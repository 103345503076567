import { MovesQualification as MVActionTypes } from '../../constants/actionTypes';

const initialState = {
  moveProfileDetails: null,
  preferredMVService: {
    lineLevelInfo: null,
  },
  isPendingMoveOrder: false,
  isBalancePastDue: false,
  isDelayedBilling: false,
  directMove: false,
  disConnectMove: false,
};

const moveReducer = (state = initialState, action) => {
  switch (action.type) {
    case MVActionTypes.PROFILE_QUALIFICATION_DETAILS:
      return { ...state, moveProfileDetails: action.value };
    case MVActionTypes.SET_PREFERRED_MV_SERVICE:
      const lineLevelInfo = { ...state.internet, lineLevelInfo: action.value};
      return { ...state, ...state,
        preferredMVService: lineLevelInfo};
    case MVActionTypes.IS_PENDING_MOVE_SERVICE:
      return { ...state, isPendingMoveOrder: action.value };
    case MVActionTypes.IS_BALANCE_DUE:
      return { ...state, isBalancePastDue: action.value };
    case MVActionTypes.IS_DELAYED_BILLING:
      return { ...state, isDelayedBilling: action.value };
    case MVActionTypes.DIRECT_MOVE:
      return { ...state, directMove: action.value };
    case MVActionTypes.DISCONNECT_MOVE:
      return { ...state, disConnectMove: action.value };
    case MVActionTypes.RESET_MV_DETAILS:
      return initialState;
    default:
      return state;
  }
};

export default moveReducer;
