export const Platforms = {
  ALL: 'ALL',
  HOME_5G: '5G',
  HOME_LTE: 'LTE',
  FIOS: 'FIOS',
};
export const MethodType = {
  GET: 'GET',
  POST: 'POST',
};
export const LQ_VIEWS = {
  HOME_HELP_VIEW: 'HOME_HELP_VIEW',
  ADDRESS_QUALIFICATION: 'ADDRESS_QUALIFICATION',
  FIVEG_HOME_QUALIFIED_VIEW: '5G_HOME_QUALIFIED_VIEW',
  RESUME_SMART_CART_VIEW: 'RESUME_SMART_CART_VIEW',
  FIOS_QUALIFIED_VIEW: 'FIOS_QUALIFIED_VIEW',
  LTE_HOME_QUALIFIED_VIEW: 'LTE_HOME_QUALIFIED_VIEW',
  UN_QUALIFIED_VIEW: 'UN_QUALIFIED_VIEW',
  BUSINESSADDRESS_VIEW: 'BUSINESSADDRESS_VIEW',
  COMMON_LQ: 'COMMON_LQ',
  ACP_QUAL: 'ACP_QUAL',
  VF_QUAL_FWA: 'VF_QUAL_FWA',
  VF_QUESTIONAIRE: 'VF_QUESTIONAIRE',
  MULTIPLE_SERVICES_QUALIFIED_MV_VIEW: 'MULTIPLE_SERVICES_QUALIFIED_MV_VIEW',
  BULK_SERVICES_QUALIFIED_VIEW: 'BULK_SERVICES_QUALIFIED_VIEW',
  LBO_VIEW: 'LBO_VIEW',
  BLANK_LOADER_VIEW: 'BLANK_LOADER_VIEW',
  FIOS_LOADING_VIEW: 'FIOS_LOADING_VIEW',
  VF_MNH_QUALIFIED_VIEW: 'VF_MNH_QUALIFIED_VIEW',
  SESSION_TIMEOUT_VIEW: 'SESSION_TIMEOUT_VIEW',
  HOME_LQ_VIEW: 'HOME_LQ_VIEW',
  RESUME_SEND_CART_VIEW: 'RESUME_SEND_CART_VIEW',
  IN_SERVICE_VIEW: 'IN_SERVICE_VIEW',
  HERE_MAP_VIEW: 'HERE_MAP_VIEW',
};

export const Pages = {
  homeHub: 'https://www.verizon.com/home/',
  ProdMyVzAccount: 'https://www.verizon.com/consumer/myverizon/router?Target=null',
  NonProdMyVzAccount: 'https://sso-np.ebiz.verizon.com/consumer/myverizon/router?Target=null',
  InHomeBuildProductReskin: '/inhome/buildproductsRskn?lq2=y',
  InHomeBuildProduct: '/inhome/buildproducts?lq2=y',
  InHomeBuildProductInServiceLBO: '/inhome/lbo?InService=N',
  InHomeBuildProductPendingLBO: '/inhome/lbo?PendingOrder=N',
  PartnerInHomeBuildProduct: '/inhome/buildproducts?lq2=y&vflow=y',
  inHomeCheckout: '/inhome/checkout?lq2=y',
  inHomeFccBroadbandLabels: '/inhome/fccbroadbandlabels',
  inHomeResumeCart: '/inhome/resumecart',
  inHomeChangeSignin: '/inhome/change/signin?flowtype=af&source=lq2',
  inHomeChangeSigninPN: '/inhome/change/signin',
  inHomeTwsDiscount: '/inhome/twsdiscount?lq2=y',
  InHomeGetMoverDetail: '/inhome/moves/getmoveorderdetail?lq2=y',
  fiveGNSAExpressCart: '/sales/home/expresscart.html?expresscart=true&resumecart=false',
  fiveGNSAExpressCartFcc: '/sales/home/expresscart.html?expresscart=true&fcc=y',
  fiveGNSAExpressCartResume: '/sales/home/expresscart.html?expresscart=true&resumecart=true',
  hoaLink: '/support/5g-home-installation-faqs/#hoa',
  privacyLink: 'https://www.verizon.com/about/privacy/',
  californiaPrivacyClick: 'https://www.verizon.com/californiaprivacy',
  termsAndConditionLink: 'https://www.verizon.com/support/other-wireless-topics/legal-notices-and-industry-info/',
  termsAndConditionLinkForSmsConsent: 'https://www.verizon.com/support/noncustomer-texts-legal/',
  fiosLearnMore: '/home/internet/fios-fastest-internet/',
  lteLearnMore: '/home/lte-home-internet/',
  ONReasonCodeUrl: '/sales/home/expresscart.html?cashonly=Y',
  ONFraudOrderUrl: '/sales/home/expresscart.html?fraudorder=Y',
  ONFraudOrderYUrl: '/sales/home/expresscart.html?fraudorder=N',
  collections_INDurl: '/sales/home/expresscart.html?collections_IND=Y',
  ONMoveDBurl: '/sales/home/expresscart.html?ONMoveDB=Y',
  ACCFailurl: '/sales/home/expresscart.html?ACCFail=Y',
  ONFailurl: '/sales/home/expresscart.html?ONFail=Y',
  ONFailMVurl: '/sales/home/expresscart.html?ONFail=Y',
  homePlansurl: '/sales/home/plans.html',
  cbandurl: '/sales/home/progressiveplan.html',
  Progressive: '/sales/home/progressiveplan.html',
  cbandMVurl: '/sales/home/movers/appointment.html',
  redirectCart: '/sales/home/cart.html',
  NotOwnerurl: '/sales/home/expresscart.html?NotOwner=Y',
  redirectAddons: '/sales/home/addons.html',
  redirectSetup: '/sales/home/setup.html',
  conflictMessage: '/inhome/connection?conflictmessage',
  redirectToHomeHub: '/home/internet/',
  redirectToFiveGHome: '/home/internet/5g/',
  redirectToLte: '/home/internet/lte/',
  redirectToFios: '/home/internet/fios-fastest-internet/',
  redirectToSetUpEquipment: 'https://www.verizon.com/support/5g-home-internet/',
  redirectPayBalance: '/digital/nsa/secure/ui/payment/paybill/',
  signIn_WirelessQa1NSAtoFios:
    'https://securesit1.verizon.com/signin?mode=i&userNameOnly=true&clientId=VZCOMBQ&goto=https://www98.verizon.com/inhome/buildproducts?lq2=y%26mnh%3Dy%26',
  signIn_WirelessQa2NSAtoFios:
    'https://securesit2.verizon.com/signin?mode=i&userNameOnly=true&clientId=VZCOMBQ&goto=https://www98.verizon.com/inhome/buildproducts?lq2=y%26mnh%3Dy%26',
  signIn_WirelessQa3NSAtoFios:
    'https://securesit3.verizon.com/signin?mode=i&userNameOnly=true&clientId=VZCOMBQ&goto=https://www98.verizon.com/inhome/buildproducts?lq2=y%26mnh%3Dy%26',
  signIn_WirelessNSAtoFios:
    'https://secure.verizon.com/signin?mode=i&userNameOnly=true&clientId=VZCOMBQ&goto=https://www.verizon.com/inhome/change/fiosmnh',
  signIn_WirelessNSAtoFiosNte1:
    'https://securesit2.verizon.com/signin?mode=i&userNameOnly=true&clientId=VZCOMBQ&goto=https://wwwnte1aws.ebiz.verizon.com/inhome/change/fiosmnh',
  signIn_WirelessNSAtoFiosStg:
    'https://securesit2.verizon.com/signin?mode=i&userNameOnly=true&clientId=VZCOMBQ&goto=https://www98.verizon.com/inhome/change/fiosmnh',
  signIn_WirelessNSAtoFiosSit:
    'https://securesit2.verizon.com/signin?mode=i&userNameOnly=true&clientId=VZCOMBQ&goto=https://wwwawssit.ebiz.verizon.com/inhome/change/fiosmnh',
  signIn_WirelessNSAtoFiosPP:
    'https://secure.verizon.com/signin?mode=i&userNameOnly=true&clientId=VZCOMBQ&goto=https://preprod-east-gz.vpc.verizon.com/inhome/change/fiosmnh',
  signIn_ACP_Secure_Qa1:
    'https://securesit1.verizon.com/signin?goto=https%3A%2F%2Fvzwqa1.verizonwireless.com%2Fhome%2Finternet%2Facp%2F%3Ftestacp%3Dy',
  signIn_VFSit5:
    'https://securesit5.verizon.com/vzauth/UI/Login?realm=vzw&goto=https%3A%2f%2fwwwnssit5.verizon.com%2fdiscounts%2Fverizon-forward%2f%3fauth=y',
  signIn_VFSit6:
    'https://securesit6.verizon.com/vzauth/UI/Login?realm=vzw&goto=https%3A%2f%2fwwwnssit6.verizon.com%2fdiscounts%2Fverizon-forward%2f%3fauth=y',
  signIn_VFEbiz5:
    'https://securesit5.verizon.com/vzauth/UI/Login?realm=vzw&goto=https%3A%2f%2fwwwnssit5.verizon.com%2fdiscounts%2Fverizon-forward%2f%3fauth=y',
  signIn_authAM98TWS:
    'https://pprd-ssoauth.verizon.com/vzauth/UI/Login?realm=vzw&service=WlnOneVerizonChain&sourceDomain=https://www98.verizon.com&remainInOverlay=N&mode=i&modeAfterSubmit=o&fromVZT=Y&userNameOnly=false&clientId=VZCOMBQ&goto=https%3a%2f%2fwww98.verizon.com%2fconsumer%2fmyverizon%2frouter%3ftarget%3dhttps%253a%252f%252fwww98.verizon.com%252finhome%252fchange%252fsignin%253fflowtype%253daf',
  signIn_WirelessQa1Nsa:
    'https://securesit1.verizon.com/signin?mode=i&userNameOnly=true&clientId=VZCOMBQ&goto=https%3A%2F%2Fvzwqa1.verizonwireless.com%2Fsales%2Fhome%2Fexpresscart.html%3Fexpresscart%3Dtrue',
  signIn_WirelessQa2Nsa:
    'https://securesit2.verizon.com/signin?mode=i&userNameOnly=true&clientId=VZCOMBQ&goto=https%3A%2F%2Fvzwqa2.verizonwireless.com%2Fsales%2Fhome%2Fexpresscart.html%3Fexpresscart%3Dtrue',
  signIn_WirelessQa3Nsa:
    'https://securesit3.verizon.com/signin?mode=i&userNameOnly=true&clientId=VZCOMBQ&goto=https%3A%2F%2Fvzwqa3.verizonwireless.com%2Fsales%2Fhome%2Fexpresscart.html%3Fexpresscart%3Dtrue',
  signIn_WirelessQa4Nsa:
    'https://securesit4.verizon.com/signin?mode=i&userNameOnly=true&clientId=VZCOMBQ&goto=https%3A%2F%2Fvzwqa4.verizonwireless.com%2Fsales%2Fhome%2Fexpresscart.html%3Fexpresscart%3Dtrue',
  signIn_WirelessQa5Nsa:
    'https://securesit5.verizon.com/signin?mode=i&userNameOnly=true&clientId=VZCOMBQ&goto=https%3A%2F%2Fvzwqa5.verizonwireless.com%2Fsales%2Fhome%2Fexpresscart.html%3Fexpresscart%3Dtrue',
  signIn_WirelessQa6Nsa:
    'https://securesit6.verizon.com/signin?mode=i&userNameOnly=true&clientId=VZCOMBQ&goto=https%3A%2F%2Fvzwqa6.verizonwireless.com%2Fsales%2Fhome%2Fexpresscart.html%3Fexpresscart%3Dtrue',
  signIn_WirelessQa7Nsa:
    'https://securesit7.verizon.com/signin?mode=i&userNameOnly=true&clientId=VZCOMBQ&goto=https%3A%2F%2Fvzwqa7.verizonwireless.com%2Fsales%2Fhome%2Fexpresscart.html%3Fexpresscart%3Dtrue',
  signIn_WirelessQa10Nsa:
    'https://securesit10.verizon.com/signin?mode=i&userNameOnly=true&clientId=VZCOMBQ&goto=https%3A%2F%2Fvzwqa10.verizonwireless.com%2Fsales%2Fhome%2Fexpresscart.html%3Fexpresscart%3Dtrue',
  signIn_WirelessQa30Nsa:
    'https://securesit30.verizon.com/signin?mode=i&userNameOnly=true&clientId=VZCOMBQ&goto=https%3A%2F%2Fvzwqa30.verizonwireless.com%2Fsales%2Fhome%2Fexpresscart.html%3Fexpresscart%3Dtrue',
  signIn_WirelessQa40Nsa:
    'https://securesit40.verizon.com/signin?mode=i&userNameOnly=true&clientId=VZCOMBQ&goto=https%3A%2F%2Fvzwqa40.verizonwireless.com%2Fsales%2Fhome%2Fexpresscart.html%3Fexpresscart%3Dtrue',
  signIn_WirelessQa50Nsa:
    'https://securesit50.verizon.com/signin?mode=i&userNameOnly=true&clientId=VZCOMBQ&goto=https%3A%2F%2Fvzwqa50.verizonwireless.com%2Fsales%2Fhome%2Fexpresscart.html%3Fexpresscart%3Dtrue',
  signIn_WirelessQa65Nsa:
    'https://securesit65.verizon.com/signin?mode=i&userNameOnly=true&clientId=VZCOMBQ&goto=https%3A%2F%2Fvzwqa65.verizonwireless.com%2Fsales%2Fhome%2Fexpresscart.html%3Fexpresscart%3Dtrue',
  signIn_WirelessNSSIT5Nsa:
    'https://securesit5.verizon.com/signin?mode=i&userNameOnly=true&clientId=VZCOMBQ&goto=https%3A%2F%2Fwwwnssit5.verizon.com%2Fsales%2Fhome%2Fexpresscart.html%3Fexpresscart%3Dtrue',
  signIn_WirelessNSSIT6Nsa:
    'https://securesit6.verizon.com/signin?mode=i&userNameOnly=true&clientId=VZCOMBQ&goto=https%3A%2F%2Fwwwnssit6.verizon.com%2Fsales%2Fhome%2Fexpresscart.html%3Fexpresscart%3Dtrue',
  signIn_WirelessNSSIT_EBIZ5Nsa:
    'https://securesit5.verizon.com/signin?mode=i&userNameOnly=true&clientId=VZCOMBQ&goto=https%3A%2F%2Fwwwnssit5.ebiz.verizon.com%2Fsales%2Fhome%2Fexpresscart.html%3Fexpresscart%3Dtrue',
  signIn_WirelessQa1NsaMV:
    'https://securesit1.verizon.com/signin?mode=i&userNameOnly=true&clientId=VZCOMBQ&goto=https%3A%2F%2Fvzwqa1.verizonwireless.com%2Fhome%2Fmoving%3Fauth=Y',
  signIn_WirelessQa2NsaMV:
    'https://securesit2.verizon.com/signin?mode=i&userNameOnly=true&clientId=VZCOMBQ&goto=https%3A%2F%2Fvzwqa2.verizonwireless.com%2Fhome%2Fmoving%3Fauth=Y',
  signIn_WirelessQa3NsaMV:
    'https://securesit3.verizon.com/signin?mode=i&userNameOnly=true&clientId=VZCOMBQ&goto=https%3A%2F%2Fvzwqa3.verizonwireless.com%2Fhome%2Fmoving%3Fauth=Y',
  signIn_WirelessNSSIT5NsaMV:
    'https://securesit5.verizon.com/signin?mode=i&userNameOnly=true&clientId=VZCOMBQ&goto=https%3A%2F%2Fwwwnssit5.verizon.com%2Fhome%2Fmoving%3Fauth=Y',
  signIn_WirelessNSSIT6NsaMV:
    'https://securesit6.verizon.com/signin?mode=i&userNameOnly=true&clientId=VZCOMBQ&goto=https%3A%2F%2Fwwwnssit6.verizon.com%2Fhome%2Fmoving%3Fauth=Y',
  signIn_WirelessNSSIT_EBIZ5NsaMV:
    'https://securesit5.verizon.com/signin?mode=i&userNameOnly=true&clientId=VZCOMBQ&goto=https%3A%2F%2Fwwwnssit5.ebiz.verizon.com%2Fhome%2Fmoving%3Fauth=Y',
  signIn_authQa1: 'https://securesit1.verizon.com/signin?mode=i&userNameOnly=true&clientId=VZCOMBQ&goto=',
  signIn_authQa2: 'https://securesit2.verizon.com/signin?mode=i&userNameOnly=true&clientId=VZCOMBQ&goto=',
  signIn_authQa3: 'https://securesit3.verizon.com/signin?mode=i&userNameOnly=true&clientId=VZCOMBQ&goto=',
};

export const ThrottleList = {};

export const Flowtype = {
  retail: 'retail-qr-code',
  storeLocater: 'store_locator',
  MOVERS: 'MOVERS',
};

export const CommonLQ = {
  shaKey: 'TWoOwIsECMenIFOunDR97WMounTAIns8anD2A3DiaMOndYbUR15dCunderHN0rtHKELop',
};

export const RouterTypes = {
  titanRouters: ['ASK-NCQ1338', 'ARC-XCI55AX', 'ASK-NCQ1338FA'],
  westWorldRouter: 'ASK-RTL108',
  geminiRouter: 'LVSKIHP',
  titan3Router: 'WNC-CR200A',
  eagleRouter: 'LV65',
};
