import { getFlowName, getDevice, populatelqStateValue, getStoreInfo, getSubCategory, getChannelName } from './vzTagUtil';
import { logMetrics } from '../services/logService';
import { Flowtype } from '../constants/common';
import { getCookie } from './lqServiceUtil';
import { getRenderSettings } from '../config';

const renderSetting = getRenderSettings();

const invokeVzTag = (APIName, dataObject) => {
  if (window.VZTAG_IS_READY) {
    window.vztag.api.dispatch(APIName, dataObject);
  } else {
    document.addEventListener(
      'vztagReady',
      (function vztagApi(VapiName, Vobj) {
        return () => {
          window.vztag.api.dispatch(VapiName, Vobj);
        };
      })(APIName, dataObject),
    );
  }
};
const invokeCoreData = (pageName, detailPageName, dataObject) => {
  window.coreData = window.coreData || [];
  window.coreData.push({
    task: 'emit',
    event: 'pageView',
    params: { selector: 'portal', name: pageName, detail: detailPageName, trackAs: 'none', data: dataObject },
  });
};

export const openViewVzTag = (detailPageName, signMHSuccess, nycList) => {
  try {
    const stateInfo = getStoreInfo();
    const deviceinfo = getDevice();
    let throttleValue = 'nsa version|vhi home|geo|mhnewsignin|suppresslqa';
    const flowName = getFlowName(stateInfo, stateInfo.fiosDetails);
    const APIName = 'openView';
    const params = new URLSearchParams(window.location.search);
    let subFlowName = '';
    let lqEvent = '';
    const lqInfo = stateInfo.addressQualification.lqResult;
    const zipCode = stateInfo.customerStreetDetails?.zip ? stateInfo.customerStreetDetails.zip : '';
    const nycCrossQual = !!(detailPageName && detailPageName.indexOf('lq result') > -1 && detailPageName.indexOf('NYC Crossqual') > -1);
    if (stateInfo.loopQualApp && stateInfo.loopQualApp.isLoggedIn) {
      throttleValue =
        (stateInfo.loopQualApp && stateInfo.loopQualApp.isBulkQual) || stateInfo.loopQualApp.Flowtype === Flowtype.storeLocater
          ? 'nsa version|vhi home|passive-lq|geo|mhnewsignin|suppresslqa'
          : 'nsa version|vhi home|bau-lq|geo|mhnewsignin|suppresslqa';
    }
    if (stateInfo.loopQualApp.isComboMFJT) {
      throttleValue += '|mhjt y';
      subFlowName = 'mhjt';
    }
    const addressEmail = stateInfo.addressQualification?.scEmail
      ? stateInfo.addressQualification.scEmail
      : stateInfo.notify.emailaddress
      ? stateInfo.notify.emailaddress
      : '';
    const locusID = stateInfo.customerStreetDetails && stateInfo.customerStreetDetails.locusID ? stateInfo.customerStreetDetails.locusID : '';
    const phoneNumber = stateInfo.addressQualification?.formattedMtn
      ? stateInfo.addressQualification.formattedMtn
      : stateInfo.notify.formattedPhonenumber
      ? stateInfo.notify.formattedPhonenumber
      : '';
    if (detailPageName && detailPageName.indexOf('lq result') > -1 && !detailPageName.includes('not')) {
      subFlowName += '|mh';
    }
    if (stateInfo.loopQualApp.isVFEmailNc) {
      subFlowName += '|vfemail';
    }
    if (signMHSuccess || (lqInfo?.fiosQualified && stateInfo.fiosDetails?.signMHSuccess)) {
      lqEvent = 'event10';
    }
    if (lqInfo?.oneVzIdCustomer) {
      lqEvent = 'event9';
    }
    let session = '';
    if (getCookie('GLOBALID')) {
      session = getCookie('GLOBALID');
    } else if (window.sessionStorage.getItem('digitalSessionId')) {
      session = window.sessionStorage.getItem('digitalSessionId');
    } else {
      session = window.sessionStorage.getItem('visitor_id');
    }
    let events = '';
    if (nycList) {
      if (stateInfo.notify.notifyFios) {
        events = ',event347 ';
      }
      if (stateInfo.notify.notifyFiosTv) {
        events += ',event348 ';
      }
      if (stateInfo.notify.notifyfiveG) {
        events += ',event349 ';
      }
      events = events.replace(',', '');
    }
    let detailList = `${detailPageName}`;
    if (nycList && detailPageName === 'lq result - noteligible' && !nycCrossQual) {
      detailList += '|NYC';
    }
    if (detailPageName.indexOf('fios eligible') > -1 && lqInfo?.fiosQualification?.inService === 'Y') {
      detailList += '|inservice';
    }
    if (detailPageName.indexOf('fios eligible') > -1 && lqInfo?.fiosQualification?.pendingOrderDisconnect === 'Y') {
      detailList += '|pending disconnect';
      subFlowName += '|pending disconnect';
    }
    if (stateInfo.loopQualApp.isLoggedInUserInfo && detailPageName.indexOf('eligible') > -1) {
      detailList += `|Wireline SignIn`;
    }
    if (detailPageName === 'Thank you for being a loyal mobile customer') {
      subFlowName += '|M+H eligible';
    }
    const subLocationId = window.sessionStorage.getItem('hashedSubLocationId') ? window.sessionStorage.getItem('hashedSubLocationId') : '';
    const hashedAddressDetail = stateInfo.customerStreetDetails?.hashedLocusId
      ? stateInfo.customerStreetDetails.hashedLocusId
      : stateInfo.customerStreetDetails?.hashedNtasAddrID
      ? stateInfo.customerStreetDetails.hashedNtasAddrID
      : subLocationId;
    subFlowName = subFlowName && subFlowName.replace('undefined', '');
    const channelId = getChannelName(stateInfo);
    const dataObject = {
      name: detailList,
      selector: 'portal',
      hashedAddress: hashedAddressDetail,
      data: {
        page: {
          flow: flowName,
          throttle: throttleValue,
          channel: 'Ordering',
          zip: zipCode,
          LQZip: zipCode,
          displayChannel: channelId,
          sourceChannel: channelId,
          channelSession: window.sessionStorage.getItem('digitalSessionId')
            ? window.sessionStorage.getItem('digitalSessionId')
            : window.sessionStorage.getItem('visit_id'),
          applicationName: deviceinfo,
          subFlow: subFlowName,
          contentFragments:
            nycList && detailPageName === 'lq result - noteligible'
              ? 'l4|p1||||||FIOS Home Internet^l5|p1||||||Fios TV^l6|p1||||||5G Home Internet'
              : nycCrossQual
              ? 'l4|p1||||||FIOS Home Internet^l5|p1||||||Fios TV'
              : '',
          subCategory: getSubCategory(renderSetting, stateInfo),
        },
        event: {
          value: nycList && (detailPageName.indexOf('enquiry') > -1 || nycCrossQual) ? events : lqEvent,
        },
        cmp: {
          all: params ? params.get('cmp') : '',
        },
        user: {
          authStatus:
            stateInfo.loopQualApp && (stateInfo.loopQualApp.isLoggedIn || stateInfo.loopQualApp.isLoggedInUserInfo) ? 'logged in' : 'anonymous',
          accountType:
            stateInfo.loopQualApp && (stateInfo.loopQualApp.isLoggedIn || stateInfo.loopQualApp.isLoggedInUserInfo)
              ? 'existing customer'
              : 'new customer',
          session,
          zip: zipCode,
          prospect: hashedAddressDetail,
        },
        target: {
          engagement: {
            intent: 'Ordering',
          },
          message: detailPageName.indexOf('enquiry') > -1 ? 'Thanks for inquiring about our services at your address.' : '',
          sandBox: getCookie('cdlThrottleList') || window.sessionStorage.getItem('LqThrottle') ? window.sessionStorage.getItem('LqThrottle') : '',
        },
        utils: {
          rtm: {
            locusId: locusID,
            prospectEmail: addressEmail,
            mtn: phoneNumber,
          },
        },
      },
    };
    invokeVzTag(APIName, dataObject);
  } catch (e) {
    console.log(`Exception in openViewVzTag${e}`);
    logMetrics('openViewVzTag', e.message, 'Error');
  }
};
export const linkVzTag = (linkName, detailPageName, nycList) => {
  try {
    const stateInfo = getStoreInfo();
    const flowName = getFlowName(stateInfo, stateInfo.fiosDetails);
    let throttleValue = 'nsa version|vhi home|geo';
    let subFlowName = '';
    if (stateInfo.loopQualApp && stateInfo.loopQualApp.isLoggedIn) {
      throttleValue =
        (stateInfo.loopQualApp && stateInfo.loopQualApp.isBulkQual) || stateInfo.loopQualApp.Flowtype === Flowtype.storeLocater
          ? 'nsa version|vhi home|passive-lq'
          : 'nsa version|vhi home|bau-lq';
    }
    if (stateInfo.loopQualApp.isComboMFJT) {
      throttleValue += '|mhjt y';
      subFlowName = 'mhjt';
    }
    if (stateInfo.loopQualApp.isVFEmailNc) {
      subFlowName += '|vfemail';
    }
    let detailList = detailPageName;
    if (nycList) {
      detailList = `${detailPageName}|NYC`;
    }
    const APIName = 'linkClick';
    const channelId = getChannelName(stateInfo);
    const dataObject = {
      name: linkName,
      detail: detailList,
      data: {
        page: {
          flow: flowName,
          channel: 'Ordering',
          throttle: throttleValue,
          subCategory: getSubCategory(renderSetting, stateInfo),
          subFlow: subFlowName,
          displayChannel: channelId,
          sourceChannel: channelId,
        },
        target: {
          engagement: {
            intent: 'Ordering',
          },
          sandBox: getCookie('cdlThrottleList') || window.sessionStorage.getItem('LqThrottle') ? window.sessionStorage.getItem('LqThrottle') : '',
        },
      },
    };
    invokeVzTag(APIName, dataObject);
  } catch (e) {
    console.log(`Exception at setting the linkVzTag${e}`);
    logMetrics('linkVzTag', e.message, 'Error');
  }
};
export const sendDataVzTagMessage = (msg, detailPageName, nycList) => {
  try {
    const stateInfo = getStoreInfo();
    let throttleValue =
      stateInfo.loopQualApp && stateInfo.loopQualApp.isBulkQual ? 'nsa version|vhi home|passive-lq|geo' : 'nsa version|vhi home|bau-lq|geo';
    let detailList = detailPageName;
    let subFlowName = '';
    if (nycList) {
      detailList = `${detailPageName}|NYC`;
    }
    if (stateInfo.loopQualApp.isComboMFJT) {
      throttleValue += '|mhjt y';
      subFlowName = 'mhjt';
    }
    if (stateInfo.loopQualApp.isVFEmailNc) {
      subFlowName += '|vfemail';
    }

    const flowName = getFlowName(stateInfo, stateInfo.fiosDetails);
    const APIName = 'sendData';
    const channelId = getChannelName(stateInfo);
    const dataObject = {
      name: '',
      detail: detailList,
      data: {
        page: {
          flow: flowName,
          channel: 'Ordering',
          throttle: throttleValue,
          subCategory: getSubCategory(renderSetting, stateInfo),
          subFlow: subFlowName,
          displayChannel: channelId,
          sourceChannel: channelId,
        },
        target: {
          message: msg,
          engagement: {
            intent: 'Ordering',
          },
          sandBox: getCookie('cdlThrottleList') || window.sessionStorage.getItem('LqThrottle') ? window.sessionStorage.getItem('LqThrottle') : '',
        },
      },
    };
    invokeVzTag(APIName, dataObject);
  } catch (e) {
    console.log(`Exception at setting the sendDataVzTagMessage${e}`);
    logMetrics('sendDataVzTagMessage', e.message, 'Error');
  }
};
export const sendDataVzTagErrorMessage = (msg, detailName) => {
  try {
    const stateInfo = getStoreInfo();
    const flowName = getFlowName(stateInfo, stateInfo.fiosDetails);
    const APIName = 'sendData';
    const channelId = getChannelName(stateInfo);
    const dataObject = {
      name: '',
      data: {
        page: {
          detail: detailName,
          channel: 'Ordering',
          flow: flowName,
          subCategory: getSubCategory(renderSetting, stateInfo),
          displayChannel: channelId,
          sourceChannel: channelId,
        },
        target: {
          engagement: {
            intent: 'Ordering',
          },
          message: msg,
          sandBox: getCookie('cdlThrottleList') || window.sessionStorage.getItem('LqThrottle') ? window.sessionStorage.getItem('LqThrottle') : '',
        },
        error: {
          code: msg,
        },
      },
    };
    invokeVzTag(APIName, dataObject);
  } catch (e) {
    console.log(`Exception at setting the sendDataVzTagErrorMessage${e}`);
    logMetrics('sendDataVzTagErrorMessage', e.message, 'Error');
  }
};
export const pageViewPageVzTag = (pageName, detailPageName = '') => {
  const stateInfo = getStoreInfo();
  const deviceinfo = getDevice();
  let throttleValue = 'nsa version|vhi home';
  let subFlowName = '';
  const flowName = getFlowName(stateInfo, stateInfo.fiosDetails);
  if (stateInfo.loopQualApp && stateInfo.loopQualApp.isLoggedIn) {
    throttleValue =
      (stateInfo.loopQualApp && stateInfo.loopQualApp.isBulkQual) || stateInfo.loopQualApp.Flowtype === Flowtype.storeLocater
        ? 'nsa version|vhi home|passive-lq'
        : 'nsa version|vhi home|bau-lq';
  }
  if (stateInfo.loopQualApp.isComboMFJT) {
    throttleValue += '|mhjt y';
    subFlowName = 'mhjt';
  }
  const addressEmail = stateInfo.addressQualification?.scEmail
    ? stateInfo.addressQualification.scEmail
    : stateInfo.notify.emailaddress
    ? stateInfo.notify.emailaddress
    : '';
  const locusID = stateInfo.customerStreetDetails && stateInfo.customerStreetDetails.locusID ? stateInfo.customerStreetDetails.locusID : '';
  const phoneNumber = stateInfo.addressQualification?.formattedMtn
    ? stateInfo.addressQualification.formattedMtn
    : stateInfo.notify.formattedPhonenumber
    ? stateInfo.notify.formattedPhonenumber
    : '';
  let params = '';
  if (window.location.search && window.location.search.indexOf('cmp') > -1) {
    params = new URLSearchParams(window.location.search);
  }
  let session = '';
  if (getCookie('GLOBALID')) {
    session = getCookie('GLOBALID');
  } else if (window.sessionStorage.getItem('digitalSessionId')) {
    session = window.sessionStorage.getItem('digitalSessionId');
  } else {
    session = window.sessionStorage.getItem('visitor_id');
  }
  if (pageName.indexOf('combo') > -1) {
    subFlowName += '|seq mh';
  }
  if (stateInfo.loopQualApp.isVFEmailNc) {
    subFlowName += '|vfemail';
  }
  subFlowName = subFlowName.replace('undefined', '');
  if (subFlowName.charAt(0) === '|') {
    subFlowName = subFlowName.substring(1);
  }
  try {
    const APIName = 'pageView';
    const dataObject = {
      name: pageName,
      data: {
        page: {
          flow: flowName,
          subFlow: subFlowName,
          throttle: throttleValue,
          channel: 'Ordering',
          displayChannel: 'VZW',
          sourceChannel: 'VZW',
          channelSession: window.sessionStorage.getItem('visit_id'),
          applicationName: deviceinfo,
          subCategory: getSubCategory(renderSetting, stateInfo),
          detail: detailPageName,
        },
        cmp: {
          all: params ? params.get('cmp') : '',
        },
        user: {
          authStatus:
            stateInfo.loopQualApp && (stateInfo.loopQualApp.isLoggedIn || stateInfo.loopQualApp.isLoggedInUserInfo) ? 'logged in' : 'anonymous',
          accountType:
            stateInfo.loopQualApp && (stateInfo.loopQualApp.isLoggedIn || stateInfo.loopQualApp.isLoggedInUserInfo)
              ? 'existing customer'
              : 'new customer',
          session,
        },
        target: {
          engagement: {
            intent: 'Ordering',
          },
          sandBox: getCookie('cdlThrottleList') || window.sessionStorage.getItem('LqThrottle') ? window.sessionStorage.getItem('LqThrottle') : '',
          message: pageName.indexOf('enquiry') > -1 ? 'Thanks for inquiring about our services at your address.' : '',
        },
        utils: {
          rtm: {
            locusId: locusID,
            prospectEmail: addressEmail,
            mtn: phoneNumber,
          },
        },
      },
    };
    if (pageName === 'pmd_landing') {
      invokeCoreData(pageName, detailPageName, dataObject.data);
    } else {
      invokeVzTag(APIName, dataObject);
    }
  } catch (e) {
    console.log(`Exception at setting the pageViewPageVzTag ${e}`);
    logMetrics('pageViewPageVzTag ', e.message, 'Error');
  }
};
export const sendDataVzTagLqa = (type) => {
  try {
    const deviceinfo = getDevice();
    const stateInfo = getStoreInfo();
    let throttleValue =
      stateInfo.loopQualApp && stateInfo.loopQualApp.isBulkQual ? 'nsa version|vhi home|passive-lq|geo' : 'nsa version|vhi home|bau-lq|geo';
    const APIName = 'sendData';
    let detailName = '';
    if (type === 'single') {
      detailName = `plq address verification single ${stateInfo.loopQualApp.sourceInfo === 'digitalCombo' ? '|seq mh' : ''}`;
    } else {
      detailName = 'plq address verification multi';
    }
    let subFlowName =
      type === 'single' ? `single line passive lq ${stateInfo.loopQualApp.sourceInfo === 'digitalCombo' ? '|seq mh' : ''}` : 'multiline passive lq';
    const zipCode = stateInfo.customerStreetDetails?.zip ? stateInfo.customerStreetDetails.zip : '';
    if (stateInfo.loopQualApp.isComboMFJT) {
      throttleValue += '|mhjt y';
      subFlowName += '|mhjt';
    }
    if (stateInfo.loopQualApp.isVFEmailNc) {
      subFlowName += '|vfemail';
    }
    let session = '';
    if (getCookie('GLOBALID')) {
      session = getCookie('GLOBALID');
    } else if (window.sessionStorage.getItem('digitalSessionId')) {
      session = window.sessionStorage.getItem('digitalSessionId');
    } else {
      session = window.sessionStorage.getItem('visitor_id');
    }
    subFlowName = subFlowName.replace('undefined', '');
    if (subFlowName.charAt(0) === '|') {
      subFlowName = subFlowName.substring(1);
    }
    const channelId = getChannelName(stateInfo);
    const flowName = getFlowName(stateInfo, stateInfo.fiosDetails);
    const dataObject = {
      name: 'passive lq',
      data: {
        page: {
          flow: flowName,
          subFlow: subFlowName,
          throttle: stateInfo.loopQualApp.sourceInfo !== 'digitalCombo' ? throttleValue : '',
          channel: 'Ordering',
          detail: detailName,
          zip: zipCode,
          LQZip: zipCode,
          channelSession: window.sessionStorage.getItem('digitalSessionId')
            ? window.sessionStorage.getItem('digitalSessionId')
            : window.sessionStorage.getItem('visit_id'),
          applicationName: deviceinfo,
          displayChannel: channelId,
          sourceChannel: channelId,
          subCategory: getSubCategory(renderSetting, stateInfo),
        },
        user: {
          authStatus:
            stateInfo.loopQualApp && (stateInfo.loopQualApp.isLoggedIn || stateInfo.loopQualApp.isLoggedInUserInfo) ? 'logged in' : 'anonymous',
          accountType:
            stateInfo.loopQualApp && (stateInfo.loopQualApp.isLoggedIn || stateInfo.loopQualApp.isLoggedInUserInfo)
              ? 'existing customer'
              : 'new customer',
          session,
          zip: zipCode,
        },
        event: {
          value: 'event333',
        },
        target: {
          engagement: {
            intent: 'Ordering',
          },
          sandBox: getCookie('cdlThrottleList') || window.sessionStorage.getItem('LqThrottle') ? window.sessionStorage.getItem('LqThrottle') : '',
        },
      },
    };
    invokeVzTag(APIName, dataObject);
  } catch (e) {
    console.log(`Exception at setting the sendDataVzTag${e}`);
    logMetrics('sendDataVzTagLqa', e.message, 'Error');
  }
};
export const sendDataVzTagLqs = (type, preferredbulkServiceDetail, comboProductsQualified) => {
  try {
    const stateInfo = getStoreInfo();
    const deviceinfo = getDevice();
    let throttleValue =
      stateInfo.loopQualApp && stateInfo.loopQualApp.isBulkQual
        ? 'nsa version|vhi home|passive-lq|geo|mhnewsignin|suppresslqa'
        : 'nsa version|vhi home|bau-lq|geo|mhnewsignin|suppresslqa';
    const zipCode = stateInfo.customerStreetDetails?.zip ? stateInfo.customerStreetDetails.zip : '';
    const notEligible = !!(stateInfo.bulkQualState?.qualifiedlist && stateInfo.bulkQualState.qualifiedlist.indexOf('not eligible') > -1);
    const APIName = 'sendData';
    let comboProducts = '';
    if (comboProductsQualified && comboProductsQualified === 'mmwave') {
      comboProducts = '5G eligible -mmWave';
    } else if (comboProductsQualified) {
      comboProducts = comboProductsQualified.qualifiedCBand
        ? '5G eligible -cband'
        : comboProductsQualified.fiveGQualified
        ? '5G eligible -mmWave'
        : comboProductsQualified.lteQualified
        ? 'lte eligible'
        : 'noteligible';
    }
    let detailName = '';
    if (stateInfo.bulkQualState) {
      if (type === 'multi') {
        detailName = `plq result -multi ${stateInfo.bulkQualState.qualifiedlist}`;
      } else {
        detailName = `plq result -single ${stateInfo.loopQualApp.sourceInfo === 'digitalCombo' ? `|seq mh` : ''} ${
          stateInfo.bulkQualState.qualifiedlist
        }`;
      }
    }
    let bulkAddressType = '';
    if (
      stateInfo.loopQualApp &&
      stateInfo.loopQualApp.isBulkQual &&
      preferredbulkServiceDetail &&
      preferredbulkServiceDetail.bulkQualAddressType !== ''
    ) {
      bulkAddressType = preferredbulkServiceDetail.bulkQualAddressType === 'BillAccount' ? 'event337' : 'event338';
    }
    let bulkProductsQualified = '';
    if (preferredbulkServiceDetail !== '') {
      bulkProductsQualified =
        stateInfo.bulkQualState && type === 'multi' ? stateInfo.bulkQualState.selectedFromList : stateInfo.bulkQualState.qualifiedlist;
    }
    let subFlowName =
      type === 'single' ? `single line passive lq ${stateInfo.loopQualApp.sourceInfo === 'digitalCombo' ? '|seq mh' : ''}` : 'multiline passive lq';
    let session = '';
    if (stateInfo.loopQualApp.isComboMFJT) {
      throttleValue += '|mhjt y';
      subFlowName += '|mhjt';
    }
    if (stateInfo.loopQualApp.isVFEmailNc) {
      subFlowName += '|vfemail';
    }
    if (getCookie('GLOBALID')) {
      session = getCookie('GLOBALID');
    } else if (window.sessionStorage.getItem('digitalSessionId')) {
      session = window.sessionStorage.getItem('digitalSessionId');
    } else {
      session = window.sessionStorage.getItem('visitor_id');
    }
    subFlowName = subFlowName.replace('undefined', '');
    if (subFlowName.charAt(0) === '|') {
      subFlowName = subFlowName.substring(1);
    }
    const channelId = getChannelName(stateInfo);
    const flowName = getFlowName(stateInfo, stateInfo.fiosDetails);
    const dataObject = {
      name: 'passive lq',
      data: {
        page: {
          flow: flowName,
          subFlow: subFlowName,
          throttle: stateInfo.loopQualApp.sourceInfo !== 'digitalCombo' ? throttleValue : '',
          channel: 'Ordering',
          zip: zipCode,
          LQZip: zipCode,
          detail: detailName,
          channelSession: window.sessionStorage.getItem('digitalSessionId')
            ? window.sessionStorage.getItem('digitalSessionId')
            : window.sessionStorage.getItem('visit_id'),
          applicationName: deviceinfo,
          displayChannel: channelId,
          sourceChannel: channelId,
          subCategory: getSubCategory(renderSetting, stateInfo),
        },
        user: {
          authStatus:
            stateInfo.loopQualApp && (stateInfo.loopQualApp.isLoggedIn || stateInfo.loopQualApp.isLoggedInUserInfo) ? 'logged in' : 'anonymous',
          accountType:
            stateInfo.loopQualApp && (stateInfo.loopQualApp.isLoggedIn || stateInfo.loopQualApp.isLoggedInUserInfo)
              ? 'existing customer'
              : 'new customer',
          session,
          productsQualified: bulkProductsQualified || comboProducts || '',
          zip: zipCode,
        },
        event: {
          value: !notEligible && bulkAddressType ? `event334, ${bulkAddressType}` : !notEligible ? 'event334' : '',
        },
        target: {
          engagement: {
            intent: 'Ordering',
          },
          sandBox: getCookie('cdlThrottleList') || window.sessionStorage.getItem('LqThrottle') ? window.sessionStorage.getItem('LqThrottle') : '',
        },
      },
    };
    invokeVzTag(APIName, dataObject);
  } catch (e) {
    console.log(`Exception at setting the sendDataVzTag${e}`);
    logMetrics('sendDataVzTagLqs', e.message, 'Error');
  }
};
export const loopQualVzTaglqa = (detailPageName) => {
  try {
    const stateInfo = getStoreInfo();
    const deviceinfo = getDevice();
    let throttleValue = 'nsa version|vhi home|geo|mhnewsignin|suppresslqa';
    const flowName = getFlowName(stateInfo, stateInfo.fiosDetails);
    if (stateInfo.loopQualApp && stateInfo.loopQualApp.isLoggedIn) {
      throttleValue =
        stateInfo.loopQualApp && stateInfo.loopQualApp.isBulkQual
          ? 'nsa version|vhi home|passive-lq|geo|mhnewsignin|suppresslqa'
          : 'nsa version|vhi home|bau-lq|geo|mhnewsignin|suppresslqa';
    }
    const zipCode = stateInfo.customerStreetDetails?.zip ? stateInfo.customerStreetDetails.zip : '';
    const addressEmail = stateInfo.addressQualification?.scEmail
      ? stateInfo.addressQualification.scEmail
      : stateInfo.notify && stateInfo.notify.emailaddress
      ? stateInfo.notify.emailaddress
      : '';
    window.sessionStorage.setItem('lqaCalled', 'Y');
    window.sessionStorage.setItem('fwa_lqaCalled', 'Y');
    let session = '';
    if (getCookie('GLOBALID')) {
      session = getCookie('GLOBALID');
    } else if (window.sessionStorage.getItem('digitalSessionId')) {
      session = window.sessionStorage.getItem('digitalSessionId');
    } else {
      session = window.sessionStorage.getItem('visit_id');
    }
    const locusID = stateInfo.customerStreetDetails?.locusID ? stateInfo.customerStreetDetails.locusID : '';
    const phoneNumber =
      stateInfo.addressQualification && stateInfo.addressQualification.formattedMtn
        ? stateInfo.addressQualification.formattedMtn
        : stateInfo.notify && stateInfo.notify.formattedPhonenumber
        ? stateInfo.notify.formattedPhonenumber
        : '';
    const subLocationId = window.sessionStorage.getItem('hashedSubLocationId') ? window.sessionStorage.getItem('hashedSubLocationId') : '';
    let subFlowName = '';
    if (stateInfo.loopQualApp.isComboMFJT) {
      throttleValue += '|mhjt y';
      subFlowName = 'mhjt';
    }
    if (stateInfo.loopQualApp.isVFEmailNc) {
      subFlowName += '|vfemail';
    }
    subFlowName = subFlowName.replace('undefined', '');
    if (subFlowName.charAt(0) === '|') {
      subFlowName = subFlowName.substring(1);
    }
    const channelId = getChannelName(stateInfo);
    const APIName = 'loopQual';
    const dataObject = {
      version: 2,
      type: 'lqa',
      zip: zipCode,
      hashedAddress: stateInfo.customerStreetDetails?.hashedLocusId
        ? stateInfo.customerStreetDetails.hashedLocusId
        : stateInfo.customerStreetDetails?.hashedNtasAddrID
        ? stateInfo.customerStreetDetails.hashedNtasAddrID
        : subLocationId,
      errorMessage: '',
      detail: detailPageName,
      data: {
        page: {
          flow: flowName,
          throttle: throttleValue,
          zip: zipCode,
          LQZip: zipCode,
          channel: 'Ordering',
          displayChannel: channelId,
          sourceChannel: channelId,
          channelSession: window.sessionStorage.getItem('visit_id'),
          applicationName: deviceinfo,
          subFlow: subFlowName,
          subCategory: getSubCategory(renderSetting, stateInfo),
        },
        user: {
          authStatus:
            stateInfo.loopQualApp && (stateInfo.loopQualApp.isLoggedIn || stateInfo.loopQualApp.isLoggedInUserInfo) ? 'logged in' : 'anonymous',
          accountType:
            stateInfo.loopQualApp && (stateInfo.loopQualApp.isLoggedIn || stateInfo.loopQualApp.isLoggedInUserInfo)
              ? 'existing customer'
              : 'new customer',
          session,
          zip: zipCode,
        },
        target: {
          engagement: {
            intent: 'Ordering',
          },
          sandBox: getCookie('cdlThrottleList') || window.sessionStorage.getItem('LqThrottle') ? window.sessionStorage.getItem('LqThrottle') : '',
        },
        utils: {
          rtm: {
            locusId: locusID,
            prospectEmail: addressEmail,
            mtn: phoneNumber,
          },
        },
      },
    };
    invokeVzTag(APIName, dataObject);
    openViewVzTag(detailPageName);
  } catch (e) {
    console.log(`Exception in loopQualVzTaglqa${e}`);
    logMetrics('loopQualVzTaglqa', e.message, 'Error');
  }
};
export const loopQualVzTaglqs = (detailPageName, typeIndicator, qualify, nycList, isThrottle) => {
  try {
    const stateInfo = getStoreInfo();
    const deviceinfo = getDevice();
    let throttleValue = 'nsa version|vhi home|geo|mhnewsignin|suppresslqa';
    let flowName = getFlowName(stateInfo, stateInfo.fiosDetails);
    let targetOffer = '';
    const event57 = !!(stateInfo.loopQualApp.isLoggedIn && stateInfo.addressQualification.lqResult.fiosMHEligible);
    const event56 = !!(!stateInfo.loopQualApp.isLoggedIn && stateInfo.addressQualification.lqResult.fiosMHEligible);
    if (stateInfo.loopQualApp && stateInfo.loopQualApp.isLoggedIn) {
      throttleValue =
        stateInfo.loopQualApp && stateInfo.loopQualApp.isBulkQual
          ? 'nsa version|vhi home|passive-lq|geo|mhnewsignin|suppresslqa'
          : 'nsa version|vhi home|bau-lq|geo|mhnewsignin|suppresslqa';
    }
    const zipCode = stateInfo.customerStreetDetails?.zip ? stateInfo.customerStreetDetails.zip : '';
    const addressEmail = stateInfo.addressQualification?.scEmail
      ? stateInfo.addressQualification.scEmail
      : stateInfo.notify?.emailaddress
      ? stateInfo.notify.emailaddress
      : '';
    const locusID = stateInfo.customerStreetDetails?.locusID ? stateInfo.customerStreetDetails.locusID : '';
    const phoneNumber = stateInfo.addressQualification?.formattedMtn
      ? stateInfo.addressQualification.formattedMtn
      : stateInfo.notify?.formattedPhonenumber
      ? stateInfo.notify.formattedPhonenumber
      : '';
    const subLocationId = window.sessionStorage.getItem('hashedSubLocationId') ? window.sessionStorage.getItem('hashedSubLocationId') : '';
    const APIName = 'loopQual';
    const lqInfo = stateInfo.addressQualification.lqResult;
    const detaileligble = lqInfo.fiosQualified
      ? 'fios eligible'
      : lqInfo.qualifiedCBand
      ? '5G eligible -cband'
      : lqInfo.fiveGQualified
      ? '5G eligible -mmWave'
      : lqInfo.lteQualified
      ? 'lte eligible'
      : 'noteligible';
    if (lqInfo && lqInfo.fiosQualified) {
      targetOffer = lqInfo.fiosQualification && lqInfo.fiosQualification.wirelessPlanType ? lqInfo.fiosQualification.wirelessPlanType : '';
    }
    const channelSession =
      qualify === 'lte' || qualify === '5g' ? window.sessionStorage.getItem('digitalSessionId') : window.sessionStorage.getItem('visit_id');
    let session = '';
    if (qualify === 'lte' || qualify === '5g') {
      if (getCookie('GLOBALID')) {
        session = getCookie('GLOBALID');
      } else if (window.sessionStorage.getItem('digitalSessionId')) {
        session = window.sessionStorage.getItem('digitalSessionId');
      } else {
        session = window.sessionStorage.getItem('visit_id');
      }
    } else {
      session = window.sessionStorage.getItem('visit_id');
    }
    let subFlowName = '';
    if (
      stateInfo.loopQualApp &&
      stateInfo.loopQualApp.flowType === 'MOVERS' &&
      window.sessionStorage.getItem('flowType') &&
      window.sessionStorage.getItem('subFlowType')
    ) {
      flowName = window.sessionStorage.getItem('flowType');
      subFlowName = `|${window.sessionStorage.getItem('subFlowType')}`;
    }
    if (lqInfo.fiosQualified) {
      if (event56 || event57) {
        subFlowName += '|m+h';
      } else {
        subFlowName += '|mh';
      }
    }
    let detailList = `${detailPageName}${detaileligble}`;
    if (nycList) detailList += '|NYC';
    if (detaileligble === 'fios eligible' && lqInfo?.fiosQualification?.inService === 'Y') {
      detailList += '|inservice';
    }
    if (detaileligble === 'fios eligible' && lqInfo?.fiosQualification?.pendingOrderDisconnect === 'Y') {
      detailList += '|pending disconnect';
      subFlowName += '|pending disconnect';
    }
    if (stateInfo.loopQualApp.isLoggedInUserInfo && detaileligble.includes('eligible')) {
      detailList += '|Wireline SignIn';
    }
    let targetMessage = stateInfo.addressQualification.lqResult.fiosMHEligible ? 'Congratulations you are qualify for the M+H discount' : '';
    if (lqInfo.qualifiedCBand || lqInfo.fiveGQualified || lqInfo.lteQualified) {
      targetMessage = 'Existing Verizon mobile customers new signin';
      if (!(stateInfo.loopQualApp.isACPFlow && stateInfo.loopQualApp.flowType === 'MOVERS')) {
        subFlowName += '|mh';
      }
    }
    if (lqInfo.fiosQualified) {
      targetMessage = 'Existing Verizon mobile customers can save on Home internet';
    }
    if (stateInfo.loopQualApp.isComboMFJT) {
      throttleValue += '|mhjt y';
      subFlowName += '|mhjt';
    }
    if (stateInfo.loopQualApp.isVFEmailNc) {
      subFlowName += '|vfemail';
    }
    subFlowName = subFlowName.replace('undefined', '');
    if (subFlowName.charAt(0) === '|') {
      subFlowName = subFlowName.substring(1);
    }
    const channelId = getChannelName(stateInfo);
    const dataObject = {
      version: 2,
      type: 'lqs',
      zip: zipCode,
      hashedAddress: stateInfo.customerStreetDetails?.hashedLocusId
        ? stateInfo.customerStreetDetails.hashedLocusId
        : stateInfo.customerStreetDetails?.hashedNtasAddrID
        ? stateInfo.customerStreetDetails.hashedNtasAddrID
        : subLocationId,
      errorMessage: '',
      detail: detailList,
      eligible: typeIndicator === 'success' ? populatelqStateValue(stateInfo.addressQualification.lqResult, qualify) : '',
      data: {
        page: {
          flow: flowName,
          throttle: throttleValue,
          zip: zipCode,
          LQZip: zipCode,
          channel: 'Ordering',
          displayChannel: channelId,
          sourceChannel: channelId,
          channelSession,
          applicationName: deviceinfo,
          subFlow: subFlowName,
          subCategory: getSubCategory(renderSetting, stateInfo),
        },
        user: {
          authStatus:
            stateInfo.loopQualApp && (stateInfo.loopQualApp.isLoggedIn || stateInfo.loopQualApp.isLoggedInUserInfo) ? 'logged in' : 'anonymous',
          accountType:
            stateInfo.loopQualApp && (stateInfo.loopQualApp.isLoggedIn || stateInfo.loopQualApp.isLoggedInUserInfo)
              ? 'existing customer'
              : 'new customer',
          session,
          zip: zipCode,
        },
        target: {
          offer: targetOffer,
          engagement: {
            intent: 'Ordering',
          },
          message: targetMessage,
          sandBox: getCookie('cdlThrottleList') || window.sessionStorage.getItem('LqThrottle') ? window.sessionStorage.getItem('LqThrottle') : '',
        },
        utils: {
          rtm: {
            locusId: locusID,
            prospectEmail: addressEmail,
            mtn: phoneNumber,
          },
        },
      },
      discount_mh_ec: event57,
      discount_mh_nc: event56,
    };
    invokeVzTag(APIName, dataObject);
    window.sessionStorage.removeItem('fwa_lqaCalled');
    if (!isThrottle && lqInfo?.fiosQualification?.pendingOrder !== 'Y') {
      openViewVzTag(detailPageName + detaileligble, '', nycList);
    }
  } catch (e) {
    console.log(`Exception in loopQualVzTaglqs${e}`);
    logMetrics('loopQualVzTaglqs', e.message, 'Error');
  }
};
export const loopQualVzTaglqError = (detailPageName, typeIndicator) => {
  try {
    const stateInfo = getStoreInfo();
    const deviceinfo = getDevice();
    let throttleValue = 'nsa version|vhi home|geo';
    const flowName = getFlowName(stateInfo, stateInfo.fiosDetails);
    if (stateInfo.loopQualApp && stateInfo.loopQualApp.isLoggedIn) {
      throttleValue =
        stateInfo.loopQualApp && stateInfo.loopQualApp.isBulkQual ? 'nsa version|vhi home|passive-lq|geo' : 'nsa version|vhi home|bau-lq|geo';
    }
    const zipCode = stateInfo.customerStreetDetails && stateInfo.customerStreetDetails.zip ? stateInfo.customerStreetDetails.zip : '';
    const subLocationId = window.sessionStorage.getItem('hashedSubLocationId') ? window.sessionStorage.getItem('hashedSubLocationId') : '';
    let subFlowName = '';
    const APIName = 'loopQual';
    let session = '';
    if (getCookie('GLOBALID')) {
      session = getCookie('GLOBALID');
    } else if (window.sessionStorage.getItem('digitalSessionId')) {
      session = window.sessionStorage.getItem('digitalSessionId');
    } else {
      session = window.sessionStorage.getItem('visitor_id');
    }
    if (stateInfo.loopQualApp.isComboMFJT) {
      throttleValue += '|mhjt y';
      subFlowName = 'mhjt';
    }
    if (stateInfo.loopQualApp.isVFEmailNc) {
      subFlowName += '|vfemail';
    }
    subFlowName = subFlowName.replace('undefined', '');
    if (subFlowName.charAt(0) === '|') {
      subFlowName = subFlowName.substring(1);
    }
    const channelId = getChannelName(stateInfo);
    const dataObject = {
      version: 2,
      type: typeIndicator,
      zip: zipCode,
      hashedAddress: stateInfo.customerStreetDetails?.hashedLocusId
        ? stateInfo.customerStreetDetails.hashedLocusId
        : stateInfo.customerStreetDetails?.hashedNtasAddrID
        ? stateInfo.customerStreetDetails.hashedNtasAddrID
        : subLocationId,
      errorMessage: detailPageName,
      detail: 'server error',
      data: {
        page: {
          flow: flowName,
          throttle: throttleValue,
          zip: zipCode,
          LQZip: zipCode,
          channel: 'Ordering',
          displayChannel: channelId,
          sourceChannel: channelId,
          channelSession: window.sessionStorage.getItem('visit_id'),
          applicationName: deviceinfo,
          subFlow: subFlowName,
          subCategory: getSubCategory(renderSetting, stateInfo),
        },
        user: {
          authStatus:
            stateInfo.loopQualApp && (stateInfo.loopQualApp.isLoggedIn || stateInfo.loopQualApp.isLoggedInUserInfo) ? 'logged in' : 'anonymous',
          accountType:
            stateInfo.loopQualApp && (stateInfo.loopQualApp.isLoggedIn || stateInfo.loopQualApp.isLoggedInUserInfo)
              ? 'existing customer'
              : 'new customer',
          session,
          zip: zipCode,
        },
        target: {
          engagement: {
            intent: 'Ordering',
          },
          sandBox: getCookie('cdlThrottleList') || window.sessionStorage.getItem('LqThrottle') ? window.sessionStorage.getItem('LqThrottle') : '',
        },
      },
    };
    invokeVzTag(APIName, dataObject);
  } catch (e) {
    console.log(`Exception in loopQualVzTaglqError${e}`);
    logMetrics('loopQualVzTaglqError', e.message, 'Error');
  }
};
export const sendDataPassiveLqa = (type, subFlowName) => {
  try {
    const deviceinfo = getDevice();
    const stateInfo = getStoreInfo();
    let throttleValue = 'nsa version|vhi home|geo';
    let subFlowValue = '';
    let channel = '';
    if (stateInfo.loopQualApp && stateInfo.loopQualApp.isFccFlow) {
      channel = 'VZW';
    } else {
      channel = 'Ordering';
    }
    if (subFlowName === 'sl') {
      subFlowValue = 'single line passive lq - store locator';
    } else if (subFlowName === 'ba') {
      subFlowValue = 'single line passive lq - billing address';
    } else if (subFlowName === 'fcc') {
      subFlowValue = 'plq';
    }
    if (stateInfo.loopQualApp.flowtype === Flowtype.storeLocater) {
      throttleValue = 'nsa version|vhi home|bau-lq|geo';
    } else {
      throttleValue = 'nsa version|vhi home|passive-lq|geo';
    }
    if (stateInfo.loopQualApp.isComboMFJT) {
      throttleValue += '|mhjt y';
      subFlowValue = 'mhjt';
    }
    const zipCode = stateInfo.customerStreetDetails?.zip ? stateInfo.customerStreetDetails.zip : '';
    let session = '';
    if (getCookie('GLOBALID')) {
      session = getCookie('GLOBALID');
    } else if (window.sessionStorage.getItem('digitalSessionId')) {
      session = window.sessionStorage.getItem('digitalSessionId');
    } else {
      session = window.sessionStorage.getItem('visitor_id');
    }
    if (subFlowName === 'locusFlow') {
      subFlowValue = 'plq';
      throttleValue = 'nsa version|vhi home|passive-lq|geo';
    }
    // eslint-disable-next-line no-param-reassign
    subFlowName = subFlowValue.replace('undefined', '');
    if (subFlowName.charAt(0) === '|') {
      // eslint-disable-next-line no-param-reassign
      subFlowName = subFlowValue.substring(1);
    }
    const flowName = getFlowName(stateInfo, stateInfo.fiosDetails);
    const APIName = 'sendData';
    const channelId = getChannelName(stateInfo);
    const dataObject = {
      name: 'passive lq',
      data: {
        page: {
          flow: flowName,
          subFlow: subFlowValue,
          zip: zipCode,
          LQZip: zipCode,
          throttle: throttleValue,
          channel,
          detail: type === 'lq-multiple-address' ? 'plq multiple address verification' : 'plq address verification',
          channelSession: window.sessionStorage.getItem('visit_id'),
          applicationName: deviceinfo,
          subCategory: getSubCategory(renderSetting, stateInfo),
          displayChannel: channelId,
          sourceChannel: channelId,
        },
        user: {
          authStatus:
            stateInfo.loopQualApp && (stateInfo.loopQualApp.isLoggedIn || stateInfo.loopQualApp.isLoggedInUserInfo) ? 'logged in' : 'anonymous',
          accountType:
            stateInfo.loopQualApp && (stateInfo.loopQualApp.isLoggedIn || stateInfo.loopQualApp.isLoggedInUserInfo)
              ? 'existing customer'
              : 'new customer',
          session,
          zip: zipCode,
        },
        event: {
          value: 'event333',
        },
        target: {
          engagement: {
            intent: 'Ordering',
          },
          sandBox: getCookie('cdlThrottleList') || window.sessionStorage.getItem('LqThrottle') ? window.sessionStorage.getItem('LqThrottle') : '',
        },
      },
    };
    invokeVzTag(APIName, dataObject);
  } catch (e) {
    console.log(`Exception in sendDataPassiveLqa${e}`);
    logMetrics('sendDataPassiveLqa', e.message, 'Error');
  }
};
export const sendDataPassiveLqs = (typeIndicator, qualify, subFlowName) => {
  try {
    const deviceinfo = getDevice();
    const stateInfo = getStoreInfo();
    let throttleValue = 'nsa version|vhi home|geo|mhnewsignin|suppresslqa';
    const event57 = !!(stateInfo.loopQualApp.isLoggedIn && stateInfo.addressQualification.lqResult.fiosMHEligible);
    const event56 = !!(!stateInfo.loopQualApp.isLoggedIn && stateInfo.addressQualification.lqResult.fiosMHEligible);
    const zipCode = stateInfo.customerStreetDetails?.zip ? stateInfo.customerStreetDetails.zip : '';
    const lqInfo = stateInfo.addressQualification.lqResult;
    let subFlowValue = '';
    if (subFlowName === 'sl') {
      subFlowValue = 'single line passive lq - store locator';
    } else if (subFlowName === 'ba') {
      subFlowValue = 'single line passive lq - billing address';
    }
    if (subFlowName === 'fcc') {
      subFlowValue = 'passive lq - fcc';
    }
    if (stateInfo.loopQualApp.flowType === Flowtype.storeLocater) {
      throttleValue = 'nsa version|vhi home|bau-lq|geo|mhnewsignin|suppresslqa';
    } else {
      throttleValue = 'nsa version|vhi home|passivelq|geo|mhnewsignin|suppresslqa';
    }
    if (lqInfo.fiosQualified && (event56 || event57)) {
      subFlowValue += '|m+h';
    } else {
      subFlowValue += '|mh';
    }
    if (stateInfo.loopQualApp.isComboMFJT) {
      throttleValue += '|mhjt y';
      subFlowValue += '|mhjt';
    }
    const detaileligble = lqInfo.fiosQualified
      ? 'fios eligible'
      : lqInfo.qualifiedCBand
      ? '5G eligible -cband'
      : lqInfo.fiveGQualified
      ? '5G eligible -mmWave'
      : lqInfo.lteQualified
      ? 'lte eligible'
      : 'noteligible';
    const prodeligble = lqInfo.fiosQualified
      ? 'fios'
      : lqInfo.qualifiedCBand
      ? '5G -cband'
      : lqInfo.fiveGQualified
      ? '5G -mmWave'
      : lqInfo.lteQualified
      ? 'lte'
      : '';
    const APIName = 'sendData';
    let session = '';
    if (getCookie('GLOBALID')) {
      session = getCookie('GLOBALID');
    } else if (window.sessionStorage.getItem('digitalSessionId')) {
      session = window.sessionStorage.getItem('digitalSessionId');
    } else {
      session = window.sessionStorage.getItem('visitor_id');
    }
    let eventValue = 'event334';
    if (subFlowName === 'locusFlow') {
      subFlowValue = 'plq';
      throttleValue = 'nsa version|vhi home|passive-lq|geo';
      eventValue = 'event334';
    } else {
      eventValue = detaileligble !== 'noteligible' ? 'event334' : '';
    }
    // eslint-disable-next-line no-param-reassign
    subFlowName = subFlowName.replace('undefined', '');
    if (subFlowName.charAt(0) === '|') {
      // eslint-disable-next-line no-param-reassign
      subFlowName = subFlowName.substring(1);
    }
    const flowName = getFlowName(stateInfo, stateInfo.fiosDetails);
    const channelId = getChannelName(stateInfo);
    const dataObject = {
      name: 'passive lq',
      data: {
        page: {
          flow: flowName,
          subFlow: subFlowValue,
          zip: zipCode,
          LQZip: zipCode,
          throttle: throttleValue,
          channel: 'Ordering',
          detail: `plq result -${detaileligble}`,
          channelSession: window.sessionStorage.getItem('digitalSessionId')
            ? window.sessionStorage.getItem('digitalSessionId')
            : window.sessionStorage.getItem('visit_id'),
          applicationName: deviceinfo,
          subCategory: getSubCategory(renderSetting, stateInfo),
          displayChannel: channelId,
          sourceChannel: channelId,
        },
        user: {
          authStatus:
            stateInfo.loopQualApp && (stateInfo.loopQualApp.isLoggedIn || stateInfo.loopQualApp.isLoggedInUserInfo) ? 'logged in' : 'anonymous',
          accountType:
            stateInfo.loopQualApp && (stateInfo.loopQualApp.isLoggedIn || stateInfo.loopQualApp.isLoggedInUserInfo)
              ? 'existing customer'
              : 'new customer',
          session,
          productsQualified: typeIndicator === 'success' ? prodeligble : '',
          zip: zipCode,
        },
        discount_mh_ec: event57,
        discount_mh_nc: event56,
        event: {
          value: eventValue,
        },
        target: {
          engagement: {
            intent: 'Ordering',
          },
          sandBox: getCookie('cdlThrottleList') || window.sessionStorage.getItem('LqThrottle') ? window.sessionStorage.getItem('LqThrottle') : '',
        },
      },
    };
    invokeVzTag(APIName, dataObject);
  } catch (e) {
    console.log(`Exception in sendDataPassiveLqs${e}`);
    logMetrics('sendDataPassiveLqs', e.message, 'Error');
  }
};

export const sendDataVzEvent = (service) => {
  try {
    const stateInfo = getStoreInfo();
    const deviceinfo = getDevice();
    let throttleValue = 'nsa version|vhi home|geo';
    const flowName = getFlowName(stateInfo, stateInfo.fiosDetails);
    if (stateInfo.loopQualApp && stateInfo.loopQualApp.isLoggedIn) {
      throttleValue =
        stateInfo.loopQualApp && stateInfo.loopQualApp.isBulkQual ? 'nsa version|vhi home|passive-lq|geo' : 'nsa version|vhi home|bau-lq|geo';
    }
    const zipCode = stateInfo.customerStreetDetails?.zip ? stateInfo.customerStreetDetails.zip : '';
    const addressEmail = stateInfo.addressQualification?.scEmail
      ? stateInfo.addressQualification.scEmail
      : stateInfo.notify && stateInfo.notify.emailaddress
      ? stateInfo.notify.emailaddress
      : '';
    let session = '';
    if (getCookie('GLOBALID')) {
      session = getCookie('GLOBALID');
    } else if (window.sessionStorage.getItem('digitalSessionId')) {
      session = window.sessionStorage.getItem('digitalSessionId');
    } else {
      session = window.sessionStorage.getItem('visit_id');
    }
    const locusID = stateInfo.customerStreetDetails?.locusID ? stateInfo.customerStreetDetails.locusID : '';
    const phoneNumber =
      stateInfo.addressQualification && stateInfo.addressQualification.formattedMtn
        ? stateInfo.addressQualification.formattedMtn
        : stateInfo.notify && stateInfo.notify.formattedPhonenumber
        ? stateInfo.notify.formattedPhonenumber
        : '';
    let subFlowName = '';
    if (stateInfo.loopQualApp.isComboMFJT) {
      throttleValue += '|mhjt y';
      subFlowName += '|mhjt';
    }
    if (stateInfo.loopQualApp.isVFEmailNc) {
      subFlowName += '|vfemail';
    }
    subFlowName = subFlowName.replace('undefined', '');
    if (subFlowName.charAt(0) === '|') {
      subFlowName = subFlowName.substring(1);
    }
    const channelId = getChannelName(stateInfo);
    const events = Array.isArray(service) ? service.join(',') : service;
    const APIName = 'sendDataVzEvent';
    const dataObject = {
      data: {
        page: {
          flow: flowName,
          throttle: throttleValue,
          zip: zipCode,
          LQZip: zipCode,
          channel: 'Ordering',
          displayChannel: channelId,
          sourceChannel: channelId,
          channelSession: window.sessionStorage.getItem('visit_id'),
          applicationName: deviceinfo,
          subFlow: subFlowName,
          subCategory: getSubCategory(renderSetting, stateInfo),
        },
        user: {
          authStatus:
            stateInfo.loopQualApp && (stateInfo.loopQualApp.isLoggedIn || stateInfo.loopQualApp.isLoggedInUserInfo) ? 'logged in' : 'anonymous',
          accountType:
            stateInfo.loopQualApp && (stateInfo.loopQualApp.isLoggedIn || stateInfo.loopQualApp.isLoggedInUserInfo)
              ? 'existing customer'
              : 'new customer',
          session,
          zip: zipCode,
        },
        event: {
          value: events,
        },
        target: {
          engagement: {
            intent: 'Ordering',
          },
          sandBox: getCookie('cdlThrottleList') || window.sessionStorage.getItem('LqThrottle') ? window.sessionStorage.getItem('LqThrottle') : '',
        },
        utils: {
          rtm: {
            locusId: locusID,
            prospectEmail: addressEmail,
            mtn: phoneNumber,
          },
        },
      },
    };
    invokeVzTag(APIName, dataObject);
  } catch (e) {
    console.log(`Exception in sendDataVzEvent${e}`);
    logMetrics('sendDataVzEvent', e.message, 'Error');
  }
};
