import React from 'react';
import { connect } from 'react-redux';
import { withCookies } from 'react-cookie';
import PropTypes from 'prop-types';
import { getCurrentBaseView } from './view-resolver';
import { Flowtype, LQ_VIEWS, Platforms } from '../../constants/common';
import * as Action from './actions';
import { validateToken, getVendorDetails, updateVendorDetails, validateVendor } from '../order-fios/actions';
import {
  genarateToken,
  checkTokenValidity,
  generateVisitorId,
  generateVisitId,
  checkLoggedIn,
  checkLoggedInStatus,
  checkLoggedInStatusUserInfo,
  redirectToPlansPage,
  getWidgetHost,
  setToStorage,
  getCookie,
  validateVendorToken,
  getInHomeTWSURL,
  isMYVZWAPP,
} from '../../utils/lqServiceUtil';
import {
  checkNetworkAvailability,
  updateHelpView,
  lqsTagTriggered,
  inVokeLqCheckNetworkAvailability,
  greyOut,
} from '../address-qualification/actions';
import Loader from '../common/loader/Loader';
import MiniLq from '../mini-lq';
import ModalOverlay from '../common/modal/modalOverlay';
import SignIn from '../common/signIn';
import { openModalWindow, closeModalWindow, existingCustomerModal } from '../common/modal/action';
import config, {
  getFlowType,
  getLQPlatform,
  getRenderSettings,
  getTwsFlow,
  getCrossSell,
  getSourceInfo,
  getFiosInquire,
  getTwsStickyLQ,
  getLoggedInTwsLQ,
} from '../../config';
import {
  getCookieVisitIdAndVisitorIdNoParam,
  getRuntimekey,
  getPdm,
  comboLqint,
  locusIDfetch,
  fetchAuthDetailsDAW,
  callThrottle,
} from '../../services/lqService';
import { stickyBannerContent } from '../../constants/lq-constants';
import { invalidAddressError, setRevisitAddress, setStreetError, setStreetGeoError, autoLocationPopulate } from '../address-lookup/actions';
import { orderNowFiveGNsa, invokeUCAbandonedCart } from '../order-fiveg-home/actions';
import { getVZDLInit } from '../../utils/vzdl';
import { sendDataVzTagLqs, openViewVzTag, pageViewPageVzTag, linkVzTag, sendDataVzTagMessage } from '../../utils/unifiedVzTag';
import DomComponent from '../common/DomComponent';
import { checkLoggedInMV } from '../move-lq/action';
import { checkLoggedInBulkQual, resetBulkDetails, checkLoggedInBulkQual_ReFetch } from '../bulk-lq/action';
import { logMetrics } from '../../services/logService';
import { showLoader } from '../common/loader/actions';
import { configLQTheme } from './themeConfig';
import MinLQSkeleton from '../skeleton-bar/MiniLQ';

class LoopQualApp extends React.PureComponent {
  constructor(props, context) {
    super(props, context);
    this.state = {
      colors: null,
    };
    this.props.dispatch(Action.updateSkeleton(true));
    const revisitAddress =
      this.props.customerStreetDetails && this.props.customerStreetDetails.disprawstr
        ? this.props.customerStreetDetails.disprawstr
        : this.props.revisitAddress;
    if (revisitAddress) {
      this.props.dispatch(setRevisitAddress(revisitAddress));
    }
    this.onCheckAvailabilityClick = this.onCheckAvailabilityClick.bind(this);
    const urlSearchParams = new URLSearchParams(window.location.search);
    this.retailQrCode = urlSearchParams.has('retail-qr-code');
    if (!this.props.loopQualApp.staticContent) {
      Action.getLoopQualAppStaticContent(this.props.dispatch);
    }
    this.isLqsCompleted = !!(
      this.props.addressQualification.lqsTagTriggered ||
      this.props.loopQualApp.currentLQView === LQ_VIEWS.FIVEG_HOME_QUALIFIED_VIEW ||
      this.props.loopQualApp.currentLQView === LQ_VIEWS.LTE_HOME_QUALIFIED_VIEW ||
      this.props.loopQualApp.currentLQView === LQ_VIEWS.FIOS_QUALIFIED_VIEW ||
      this.props.loopQualApp.currentLQView === LQ_VIEWS.FIOS_LOADING_VIEW ||
      this.props.addressQualification.lqResult.fiosExtremeHappy
    );
    this.isTwsFilterdFlow = getTwsStickyLQ() === 'Y';
    this.isResponsiveLQ = getSourceInfo() === 'ResponsiveLQ' || urlSearchParams.get('type') === 'ResponsiveLQ';
    this.isResponsiveLQEmbeded = urlSearchParams.get('type') === 'ResponsiveLQEmbeded';
    this.plansvisit = window?.vzwlqwidgetconfig?.sourceInfo === 'plansvisit';
  }

  async componentDidMount() {
    setToStorage('Fwa_MN', 'Y');
    window.sessionStorage.removeItem('clearDetailsLQ');
    const twsSrcInfo = getSourceInfo();
    // resetting the fios existing loggedin session if any
    sessionStorage.removeItem('existingFiosLoggedIn');
    const params = new URLSearchParams(window.location.search);
    let twsFlow = getTwsFlow() ? getTwsFlow() : params.get('type') ? params.get('type') : null;
    const comboflow = params.get('comboflow');
    const testcombo = params.get('testcombo');
    const hasRequiredParams = params.has('restricted') && params.has('sedOfferId') && params.has('restrictedProductType');
    if (hasRequiredParams) {
      const restricted = params.get('restricted');
      const sedOfferId = params.get('sedOfferId');
      const restrictedProductType = params.get('restrictedProductType');
      if ((restricted === 'true' || restricted === true) && restrictedProductType === 'SPO') {
        const salesLandingOffers = {
          sedOfferId,
          restrictedProductType,
          restricted,
        };
        window.sessionStorage.setItem('salesLandingOffers', JSON.stringify(salesLandingOffers));
      } else {
        window.sessionStorage.removeItem('salesLandingOffers');
      }
    }

    if (!window.localStorage.getItem('lqwidgetloadded')) {
      logMetrics('lqwidget', 'lqwidgetloadded', 'Info');
      window.localStorage.setItem('lqwidgetloadded', 'Y');
    }
    window.sessionStorage.setItem('GeoQualBlock', 'N');
    window.sessionStorage.removeItem('ResponsiveLQBulkRes');
    this.props.dispatch(setStreetError(false));
    this.props.dispatch(setStreetGeoError(false));
    this.props.dispatch(invalidAddressError(false));
    let platForm = getLQPlatform();
    window.sessionStorage.removeItem('sessionIdFromPdm');
    const renderSetting = getRenderSettings();
    if (this.isTwsFilterdFlow) {
      platForm = 'FIOS';
    }
    if (platForm) {
      this.props.dispatch(Action.updatePlatForm(platForm));
    }
    let flowType = getFlowType();
    const srcInfo = getSourceInfo();
    if (
      flowType === '' &&
      window.location.search &&
      (window.location.search.toLowerCase().indexOf('move=y') > -1 || window.location.search.toLowerCase().indexOf('type=moving') > -1)
    ) {
      flowType = Flowtype.MOVERS;
    }
    if (srcInfo && (srcInfo === 'retail-qr-code' || srcInfo === 'store_locator' || srcInfo === 'MOVERS')) {
      flowType = srcInfo;
    }
    this.props.dispatch(Action.setFlowType(flowType));
    let server_custStoreID = '';
    let server_visitorId = '';
    let server_digitalIgSession = '';
    if (!(isMYVZWAPP() && this.props.loopQualApp.sourceInfo?.toUpperCase() === 'CPC')) {
      closeModalWindow(this.props.dispatch);
    }
    const widgetHost = getWidgetHost(window.location.pathname);
    if (widgetHost) {
      this.props.dispatch(Action.updateWidgetHost(widgetHost));
    }
    // If user is logged in and role as guest, Considering isLoggedIn as false for Sequential flow (Mobility Email to FWA).
    const isLoggedIn =
      srcInfo && srcInfo === 'digitalCombo' && getCookie('role') === 'guest'
        ? false
        : checkLoggedInStatus(this.props.dispatch, this.props.loopQualApp);
    const isLoggedInUserInfo = checkLoggedInStatusUserInfo(this.props.dispatch);
    this.keydownOrMousedownCheck();
    Action.getLoopQualAppStaticContent(this.props.dispatch);
    const vtoken = params.get('vtoken');
    const autogeoqual = params.get('autogeoqual');
    const cVal = params.get('c');
    const abrVal = params.get('abr');
    const skipregQS = params.get('skipReg');
    const isACPFlow = getSourceInfo() === 'ACP' || params.get('type') === 'acp';
    const isVfEmail = params.get('CMP')?.indexOf('vfqualfwa') > -1 || params.get('CMP')?.indexOf('vfecqualfw') > -1;
    const isVfResume = params.get('resume') === 'y';
    const vfMnhFlow = params.get('vfmnh') === 'y';
    const moversPh2 = flowType === Flowtype.MOVERS && params.get('ph2') === 'y';
    const moversPh2Titan = params.get('ph2Titan') === 'y';
    const disableMoversPh2 = params.get('disableMoversPh2') === 'y';
    const doLqCall = params.get('doLqCall');
    const sourceInfo = params.get('sourceInfo') ? params.get('sourceInfo') : null;
    const crossSell = getCrossSell();
    const mhToken = params.get('vzt') ? params.get('vzt') : null;
    const clearAdd = params.get('editfromOrdering') ? params.get('editfromOrdering') : '';
    const lqResponseStorage = window.sessionStorage.getItem('LQResponse');
    const locusFetch = params.get('locusid') ? params.get('locusid') : null;
    const isFccFlow = getSourceInfo() === 'FCC' || params.get('type') === 'fcc';
    const teachersFlow = this.twsFilterFlow(twsSrcInfo, twsFlow) === 'teacher';
    const isComboMFJT = getSourceInfo() === 'digitalComboMFJT' || params.get('type') === 'digitalComboMFJT';
    const isNycPh2 = getFiosInquire() === 'Y' || params.get('type') === 'fiosInquire';
    const newConnection = params.get('PN')
      ? params.get('PN')
      : window.sessionStorage.getItem('couponToken')
      ? window.sessionStorage.getItem('couponToken')
      : '';
    const digiCombo = params.get('digitalCombo') ? params.get('digitalCombo') : '';
    const session_visitor_id = window.sessionStorage.getItem('visitor_id');
    const geo = window.sessionStorage.getItem('geologged');
    if (!lqResponseStorage && !isLoggedIn && !geo) {
      if (navigator.permissions) {
        navigator.permissions.query({ name: 'geolocation' }).then((PermissionStatus) => {
          if (PermissionStatus.state === 'granted') {
            logMetrics('geo', 'Granted already', 'Info');
            if (autogeoqual) {
              autoLocationPopulate(this.props.dispatch);
            }
          } else if (PermissionStatus.state === 'prompt') {
            logMetrics('geo', 'Ideal', 'Info');
          } else {
            logMetrics('geo', 'Blocked already', 'Info');
          }
          window.sessionStorage.setItem('geologged', 'true');
        });
      }
    }
    if (clearAdd || this.plansvisit) {
      Action.clearDetails(this.props.dispatch, this.props.cookies, this.props.fiosDetails, this.props.loopQualApp);
      linkVzTag('change address -plans');
    }
    if (isLoggedInUserInfo) {
      const session_visit_id = window.sessionStorage.getItem('visit_id');
      if (!session_visit_id) {
        generateVisitId();
      }
    }
    const throttleTriggered = !!window.sessionStorage.getItem('ThrottleTriggered');
    if (!throttleTriggered && isACPFlow) {
      callThrottle(isLoggedIn, this.props.dispatch, isACPFlow);
    }
    const responsekey = await getRuntimekey(this.props.dispatch);
    const isLoggedInTWSTK = getLoggedInTwsLQ();
    Action.setNSAFlowParams(isLoggedIn);
    this.props.dispatch(resetBulkDetails());
    if (!session_visitor_id) {
      generateVisitorId();
    }
    this.setColorTheme('Y'); // setting monarch theme
    if (responsekey && responsekey.data && responsekey.data.EnableMDU && responsekey.data.EnableMDU === 'Y') {
      this.props.dispatch(Action.updateMDU(true));
    } else {
      this.props.dispatch(Action.updateMDU(false));
    }

    if (isACPFlow) {
      this.props.dispatch(Action.updateACP(isACPFlow));
      this.props.dispatch(Action.updateLq4(false));
      this.props.dispatch(Action.updateVfEc(true));
      if (isVfEmail) window.sessionStorage.setItem('isVfEmail', 'true');
      if (params.get('newExp') === 'Y' && params.get('CMP').indexOf('vfncqualfw') > -1) {
        window.sessionStorage.setItem('isVfEmailNC', 'true');
        this.props.dispatch(Action.updateVFEmailNc(true));
      } else {
        window.sessionStorage.setItem('isVfEmailNC', 'false');
        this.props.dispatch(Action.updateVFEmailNc(false));
      }
      if (isVfResume) this.props.dispatch(Action.updateVFResumeFlow(true));
      const session_visit_id = window.sessionStorage.getItem('visit_id');
      if (!session_visit_id) {
        generateVisitId();
      }
    } else if (
      (renderSetting && renderSetting.miniLq && renderSetting.miniLq.enabled && this.props.loopQualApp.flowType !== Flowtype.MOVERS) ||
      (teachersFlow && params.get('twsStickyLq') === 'Y') ||
      this.isResponsiveLQ
    ) {
      this.props.dispatch(Action.updateLq4(true));
      setToStorage('lq4', true);
      this.props.dispatch(Action.updateACP(false));
    } else {
      this.props.dispatch(Action.updateLq4(false));
      window.sessionStorage.removeItem('lq4');
      this.props.dispatch(Action.updateACP(false));
    }
    if (
      moversPh2 ||
      (responsekey && responsekey.data && responsekey.data.EnableMoverPh2 && responsekey.data.EnableMoverPh2 === 'Y') ||
      window.sessionStorage.getItem('moversPh2')
    ) {
      this.props.dispatch(Action.updateMoversPh2(true));
      window.sessionStorage.setItem('moversPh2', true);
      const value = 'moversPh2=true;path=/';
      document.cookie = value;
      if (moversPh2Titan || responsekey.data.EnableMoverPh2Titan === 'Y' || window.sessionStorage.getItem('moversPh2Titan')) {
        this.props.dispatch(Action.updateMoversPh2Titan(true));
        window.sessionStorage.setItem('moversPh2Titan', true);
      } else {
        this.props.dispatch(Action.updateMoversPh2Titan(false));
      }
      if (
        disableMoversPh2 ||
        (responsekey.data.DisableMoversPh2 === 'Y' && params.get('disableMoversPh2') !== 'n') ||
        (window.sessionStorage.getItem('disableMoversPh2') && params.get('disableMoversPh2') !== 'n')
      ) {
        this.props.dispatch(Action.updateDisableMoversPh2(true));
        window.sessionStorage.setItem('disableMoversPh2', true);
      } else {
        this.props.dispatch(Action.updateDisableMoversPh2(false));
      }
    } else {
      this.props.dispatch(Action.updateMoversPh2(false));
      this.props.dispatch(Action.updateMoversPh2Titan(false));
    }
    if (newConnection) {
      setToStorage('couponToken', newConnection);
      this.updateCurrentView(LQ_VIEWS.COMMON_LQ);
      this.props.dispatch(Action.updateNewConnection(true));
    } else {
      this.props.dispatch(Action.updateNewConnection(false));
    }
    if (mhToken) {
      this.props.dispatch(Action.updateMhToken(mhToken));
    } else {
      this.props.dispatch(Action.updateMhToken(null));
    }
    if (crossSell) {
      this.props.dispatch(Action.updateSourceInfo(crossSell));
    } else if (sourceInfo) {
      this.props.dispatch(Action.updateSourceInfo(sourceInfo));
      openModalWindow('LQAPP', this.props.dispatch);
    } else {
      this.props.dispatch(Action.updateSourceInfo(srcInfo || null));
    }
    if (locusFetch) {
      this.props.dispatch(Action.updateSourceInfo('external'));
    }
    if (isFccFlow) {
      this.props.dispatch(Action.updateHideMiniLq(true));
      this.props.dispatch(Action.updateFccFlow(true));
    } else if (this.isTwsFilterdFlow || this.isResponsiveLQ) {
      // if (this.isTwsFilterdFlow && !this.props.lqResult.lqAddress) {
      this.props.dispatch(Action.updateHideMiniLq(true));
      // }
    } else {
      this.props.dispatch(Action.updateHideMiniLq(false));
      this.props.dispatch(Action.updateFccFlow(false));
    }
    if (!getCookie('loggedIn') && !getCookie('userinfo')) {
      setTimeout(() => {
        this.props.dispatch(Action.updateSkeleton(false));
      }, 1000);
    } else {
      this.props.dispatch(Action.updateSkeleton(false));
    }

    if (this.isResponsiveLQ) {
      this.props.dispatch(Action.updateRespLQ(true));
    } else {
      this.props.dispatch(Action.updateRespLQ(false));
    }

    if ((srcInfo && srcInfo === 'digitalCombo') || digiCombo) {
      this.props.dispatch(Action.updateSourceInfo('digitalCombo'));
      this.updateCurrentView(LQ_VIEWS.BLANK_LOADER_VIEW);
      this.props.dispatch(showLoader());
    }
    if (isComboMFJT && !this.props.loopQualApp.isLoggedIn && !this.props.loopQualApp.isLoggedInUserInfo) {
      this.props.dispatch(Action.updateComboMFJT(true));
      this.props.dispatch(Action.updateHideMiniLq(true));
      this.props.dispatch(Action.updateLq4(false));
    } else {
      this.props.dispatch(Action.updateComboMFJT(false));
    }
    if (
      (twsFlow &&
        (twsFlow === 'medical' ||
          twsFlow === 'student' ||
          twsFlow === 'medical_new' ||
          twsFlow === 'responder' ||
          twsFlow === 'teacher' ||
          twsFlow === 'milvet' ||
          twsFlow === 'nurse')) ||
      (twsSrcInfo && twsSrcInfo.indexOf('tws') > -1)
    ) {
      twsFlow = this.twsFilterFlow(twsSrcInfo, twsFlow);
      this.props.dispatch(Action.updateTWS(twsFlow));
      const session_visit_id = window.sessionStorage.getItem('visit_id');
      if (!session_visit_id) {
        generateVisitId();
      }
    } else {
      this.props.dispatch(Action.updateTWS(null));
    }
    if (this.props.loopQualApp.currentLQView === 'COMMON_LQ') {
      if (!twsFlow && !isACPFlow && !newConnection) {
        this.updateCurrentView(LQ_VIEWS.ADDRESS_QUALIFICATION);
      }
    }
    if (isNycPh2) {
      this.props.dispatch(Action.updateNycPh2(true));
    } else {
      this.props.dispatch(Action.updateNycPh2(false));
    }

    if (this.props.modalOverlay.existingCustomerModal) {
      existingCustomerModal(this.props.dispatch, false);
    }
    if (this.props.addressQualification.helpView) {
      updateHelpView(this.props.dispatch, false);
    }
    window.vzlqw = {};
    window.vzlqw.closeModal = this.closeModal;
    window.vzlqw.isvzlqwModalOpened = false;
    if (!(window.location.search && window.location.search.toLowerCase().includes('?availability'))) {
      window.vzlqw.openModal = this.onCheckAvailabilityClick;
    }
    let checkAvailBtnTrack = 'check avail - nav';
    if (this.props.loopQualApp.currentLQView === LQ_VIEWS.FIVEG_HOME_QUALIFIED_VIEW) {
      if (this.props.lqResult.qualifiedCBand) {
        checkAvailBtnTrack = 'order now - 5g eligible -cband';
      } else {
        checkAvailBtnTrack = 'order now - 5g eligible -mmWave';
      }
    } else if (this.props.loopQualApp.currentLQView === LQ_VIEWS.LTE_HOME_QUALIFIED_VIEW) {
      checkAvailBtnTrack = 'order now - lte eligible';
    } else if (
      this.props.loopQualApp.currentLQView === LQ_VIEWS.FIOS_QUALIFIED_VIEW ||
      this.props.loopQualApp.currentLQView === LQ_VIEWS.FIOS_LOADING_VIEW
    ) {
      checkAvailBtnTrack = 'order now - fios eligible';
    } else if (this.props.loopQualApp.currentLQView === LQ_VIEWS.UN_QUALIFIED_VIEW) {
      checkAvailBtnTrack = 'not eligible';
    }
    Action.setCheckAvailBtnTrack(checkAvailBtnTrack, this.props.dispatch);
    if (this.retailQrCode) {
      this.props.dispatch(Action.setFlowType('retail-qr-code'));
    }
    setToStorage('isCBandFlow', 'Y');
    setToStorage('lqWidgetLoaded', true);
    setToStorage('isWillow', true);
    setToStorage('skipReg', skipregQS === 'Y' ? 'Y' : 'N');
    setToStorage('enableFuncComp', params.get('chkout') === 'Y' ? 'Y' : 'N');
    setToStorage('enableACP', true);
    setToStorage('LqThrottle', getCookie('cdlThrottleList') ? getCookie('cdlThrottleList') : '');
    window.vzlqw.lqWidgetLoaded = true;
    if (window.location.pathname.includes('cpcCheckAvailability')) {
      const bridgeAPIMsg = {
        actionType: 'updateNavigation', // Bridge API Tittle cpcCheckAvailability changes
        navigationBar: {
          moleculeName: 'navigationBar',
          title: 'Home Internet',
          alwaysShowBackButton: true, // false if u  want to hide the back
        },
      };
      this.mvaBridgeApiCall(bridgeAPIMsg);
    } else if (
      window.location.pathname.includes('inhome/checkavailability') ||
      window.location.pathname.includes('inhome/qualification') ||
      window.location.pathname.includes('sales/home/lq') ||
      window.location.pathname.includes('inhome/moves/checkavailability')
    ) {
      const coupon = params.get('PN');
      const couponPattern = /^[0-9A-Z]{4}-[0-9A-Z]{4}-[0-9A-Z]{4}$/;
      const newCouponPattern = /^DAW01.{10,16}$/;
      const isCouponInvoked = window.sessionStorage.getItem('couponInvoked') ? window.sessionStorage.getItem('couponInvoked') : false;
      const validToken = coupon ? couponPattern.test(coupon.toUpperCase()) || newCouponPattern.test(coupon.toUpperCase()) : false;
      if (coupon && validToken && !isCouponInvoked) {
        this.props.dispatch(showLoader());
        setTimeout(() => {
          validateToken(coupon, this.props.dispatch);
        }, 1000);
        openModalWindow('LQAPP', this.props.dispatch);
        this.invokeInitTag(false, 'checkAvaillqInitCare', 'lq check avail - care', true, this.props.loopQualApp, this.props.customerStreetDetails);
      } else if (
        isCouponInvoked &&
        window.location.search &&
        window.location.search.toLowerCase().indexOf('testfwa=y') > -1 &&
        (window.location.host.includes('ebiz.verizon.com') || window.location.host.includes('98.verizon.com'))
      ) {
        window.location.href = `https://vzwqa3.verizonwireless.com/sales/home/external/checkAvailability.html?PN=${coupon}`;
      } else {
        openModalWindow('LQAPP', this.props.dispatch);
        if (vtoken || (abrVal && cVal)) {
          setTimeout(() => {
            getVendorDetails(this.props.dispatch);
          }, 1000);
        }
        if (this.props.loopQualApp.TWS || isACPFlow) {
          const currentView =
            isACPFlow &&
            this.props.loopQualApp.isLoggedIn &&
            (this.props.addressQualification?.lqResult?.existingFWA ||
              window.sessionStorage.getItem('existingFwa') === 'Y' ||
              this.props.bulkQualDetails.preferredbulkServiceDetail.existingFWA) &&
            this.props?.loopQualApp?.isVfEc &&
            window.sessionStorage.getItem('isVfExist') === 'false'
              ? window.sessionStorage.getItem('isVfEmail') === 'true'
                ? LQ_VIEWS.VF_QUAL_FWA
                : LQ_VIEWS.VF_QUESTIONAIRE
              : window.sessionStorage.getItem('isVfEmailNC') === 'true'
              ? LQ_VIEWS.ADDRESS_QUALIFICATION
              : LQ_VIEWS.COMMON_LQ;
          this.updateCurrentView(currentView);
        }
        this.invokeInitTag(
          !!lqResponseStorage,
          'checkAvaillqInitFullPage',
          'lq check avail - landing',
          true,
          this.props.loopQualApp,
          this.props.customerStreetDetails,
        );
      }
    } else if (vtoken) {
      await validateVendorToken(vtoken, this.props.dispatch);
    } else if (abrVal && cVal) {
      const queryDetails = { abrVal, c: cVal, cmpId: params.get('CMP'), locationCode: '' };
      validateVendor(queryDetails, this.props.dispatch);
    }
    if (window.location.search && window.location.search.toLowerCase().indexOf('?availability') > -1) {
      if (this.props.loopQualApp.lq4 && !this.props.addressQualification.lqsTagTriggered) {
        inVokeLqCheckNetworkAvailability(
          'stickyBar-CTA',
          this.props.customerStreetDetails,
          this.props.unitLookupDetails,
          this.props.addressQualification,
          this.props.loopQualApp,
          this.props.dispatch,
          this.props.cookies,
          '',
          this.props.fiosDetails,
          this.props.moveDetails,
          null,
          this.props.parserAttempt,
          false,
        );
        greyOut();
      } else {
        openModalWindow('LQAPP', this.props.dispatch);
        this.invokeInitTag(!!lqResponseStorage, 'checkAvaillqInit', 'lq check avail', false, this.props.loopQualApp);
      }
    }
    if (document.readyState === 'complete') {
      this.initExternalTriggers();
      this.initExternalSignInTriggers();
    } else if (window.addEventListener) {
      window.addEventListener('load', () => {
        this.initExternalTriggers();
        this.initExternalSignInTriggers();
      });
    } else if (document.addEventListener) {
      document.addEventListener('DOMContentLoaded', () => {
        this.initExternalTriggers();
        this.initExternalSignInTriggers();
      });
    }
    this.isVendorFlowParams();
    if (this.isLqsCompleted) {
      if (this.props.loopQualApp.isNSAFlow) {
        if (window.location.host.includes('qa') || window.location.host.includes('localhost')) {
          server_visitorId = window.localStorage.getItem('visitor_id');
        }
      }
      const response = await getCookieVisitIdAndVisitorIdNoParam();
      server_custStoreID = response && response.data && response.data.server_cust_Store_pid ? response.data.server_cust_Store_pid : '';
      server_visitorId = response && response.data && response.data.server_visitor_id ? response.data.server_visitor_id : '';
      server_digitalIgSession = response && response.data && response.data.server_digital_ig_session ? response.data.server_digital_ig_session : '';
      window.localStorage.setItem('visitor_id', server_visitorId);
      const globalId = getCookie('GLOBALID') ? getCookie('GLOBALID') : '';
      if (globalId && !lqResponseStorage) {
        getPdm(this.props.dispatch);
      }
    }
    if (
      isLoggedIn &&
      this.props.loopQualApp.roleType &&
      (this.props.loopQualApp.roleType === 'prepay' || this.props.loopQualApp.roleType === 'guest')
    ) {
      return true;
    }
    if (locusFetch) {
      locusIDfetch(
        locusFetch,
        this.props.dispatch,
        this.props.loopQualApp,
        this.props.fiosDetails,
        this.props.addressQualification,
        this.props.unitLookupDetails,
        this.props.moveDetails,
        this.props.cookies,
      );
    } else if (srcInfo === 'digitalCombo' || digiCombo || comboflow === 'fios' || testcombo === 'y') {
      pageViewPageVzTag('combo checkavailability');
      comboLqint(
        this.props.dispatch,
        this.props.loopQualApp,
        this.props.fiosDetails,
        this.props.addressQualification,
        this.props.unitLookupDetails,
        this.props.moveDetails,
      );
    } else if (isACPFlow && (isLoggedInUserInfo || isLoggedIn || vfMnhFlow)) {
      const bulkPlanVisited = !!window.sessionStorage.getItem('bulkPlanVisited');
      if (window.location.search && window.location.search.toLowerCase().indexOf('auth=y') > -1) {
        openModalWindow('LQAPP', this.props.dispatch);
      }
      if (vfMnhFlow) {
        this.updateCurrentView(LQ_VIEWS.VF_MNH_QUALIFIED_VIEW);
        checkLoggedInBulkQual_ReFetch(
          this.props.dispatch,
          this.props.loopQualApp,
          this.props.fiosDetails,
          this.props.addressQualification,
          this.props.customerStreetDetails,
          this.props.unitLookupDetails,
          this.props.moveDetails,
          this.props.cookies,
          false,
          this.props.bulkQualDetails,
        );
      } else if (isLoggedIn) {
        if (bulkPlanVisited) {
          checkLoggedIn(this.props.dispatch, this.props.cookies, this.props.loopQualApp, '', this.props.addressQualification, this.props.moveDetails);
        } else {
          this.props.dispatch(Action.updateIsBulkQual(true));
          checkLoggedInBulkQual(
            this.props.dispatch,
            this.props.loopQualApp,
            this.props.fiosDetails,
            this.props.addressQualification,
            this.props.customerStreetDetails,
            this.props.unitLookupDetails,
            this.props.moveDetails,
            this.props.cookies,
            false,
            this.props.bulkQualDetails,
          );
        }
      } else if (isLoggedInUserInfo) {
        Action.fetchFiosProfile(this.props.dispatch, this.props.loopQualApp);
      }
    } else if (isLoggedInTWSTK) {
      const isAuthY = window.location.search && window.location.search.toLowerCase().indexOf('auth=y') > -1;
      if (this.props.loopQualApp.isLoggedInUserInfo && isAuthY) {
        sendDataVzTagMessage('TWS FIOS LoggedIn customer', '');
        const signedURLTWS = getInHomeTWSURL(this.props.loopQualApp);
        console.log('signedURLTWS', signedURLTWS);
        window.location.href = signedURLTWS;
        return true;
      }
      if (isLoggedIn) {
        if (window.location.search && isAuthY) {
          openModalWindow('LQAPP', this.props.dispatch);
        }
        this.props.dispatch(Action.updateIsBulkQual(true));
        checkLoggedInBulkQual(
          this.props.dispatch,
          this.props.loopQualApp,
          this.props.fiosDetails,
          this.props.addressQualification,
          this.props.customerStreetDetails,
          this.props.unitLookupDetails,
          this.props.moveDetails,
          this.props.cookies,
          false,
          this.props.bulkQualDetails,
        );
      }
    } else if (newConnection) {
      fetchAuthDetailsDAW(newConnection, isLoggedInUserInfo, isLoggedIn, this.props.loopQualApp);
    } else if (this.props.loopQualApp.flowType === Flowtype.MOVERS && (isLoggedInUserInfo || isLoggedIn)) {
      if (window.location.search && window.location.search.toLowerCase().indexOf('auth=y') > -1) {
        openModalWindow('LQAPP', this.props.dispatch);
      }
      if (isLoggedIn) {
        checkLoggedInMV(this.props.dispatch);
      }
    } else if (isLoggedIn && this.props.loopQualApp.sourceInfo?.toUpperCase() !== 'CPC' && !clearAdd && !this.isResponsiveLQ) {
      const bulkPlanVisited = !!window.sessionStorage.getItem('bulkPlanVisited');
      if (this.props.loopQualApp.flowType === Flowtype.storeLocater || bulkPlanVisited) {
        checkLoggedIn(this.props.dispatch, this.props.cookies, this.props.loopQualApp, '', this.props.addressQualification, this.props.moveDetails);
      } else {
        this.props.dispatch(Action.updateIsBulkQual(true));
        setToStorage('FWALqAALThrottle', 'BULK');
        checkLoggedInBulkQual(
          this.props.dispatch,
          this.props.loopQualApp,
          this.props.fiosDetails,
          this.props.addressQualification,
          this.props.customerStreetDetails,
          this.props.unitLookupDetails,
          this.props.moveDetails,
          this.props.cookies,
          false,
          this.props.bulkQualDetails,
        );
      }
    } else if (
      isLoggedInUserInfo &&
      !isLoggedIn &&
      this.props.loopQualApp.flowType !== Flowtype.MOVERS &&
      !this.props.loopQualApp.isACPFlow &&
      !this.props.loopQualApp.TWS &&
      !lqResponseStorage
    ) {
      Action.fetchFiosProfile(this.props.dispatch, this.props.loopQualApp, true); // VZWHDE-1179
    } else if (
      (this.props.loopQualApp.currentLQView !== LQ_VIEWS.UN_QUALIFIED_VIEW &&
        this.props.loopQualApp.currentLQView !== LQ_VIEWS.BUSINESSADDRESS_VIEW) ||
      this.props.lqResult.fiosQualified ||
      this.props.lqResult.fiveGQualified ||
      this.props.lqResult.qualifiedCBand ||
      this.props.lqResult.lteQualified ||
      (this.props.loopQualApp.currentLQView === LQ_VIEWS.UN_QUALIFIED_VIEW && !lqResponseStorage)
    ) {
      if (doLqCall === 'Y') {
        const selectedAddressString = window.sessionStorage.getItem('selectedAddress');
        if (selectedAddressString) {
          const selectedAddress = JSON.parse(selectedAddressString);
          checkNetworkAvailability(
            selectedAddress,
            this.props.unitLookupDetails,
            this.props.addressQualification,
            this.props.loopQualApp,
            this.props.dispatch,
            null,
            '',
            this.props.fiosDetails,
            this.props.moveDetails,
          );
          openModalWindow('LQAPP', this.props.dispatch);
        }
      } else if (
        this.props.addressQualification.lqsTagTriggered ||
        this.props.loopQualApp.currentLQView === LQ_VIEWS.FIVEG_HOME_QUALIFIED_VIEW ||
        this.props.loopQualApp.currentLQView === LQ_VIEWS.LTE_HOME_QUALIFIED_VIEW ||
        this.props.loopQualApp.currentLQView === LQ_VIEWS.FIOS_QUALIFIED_VIEW ||
        this.props.loopQualApp.currentLQView === LQ_VIEWS.FIOS_LOADING_VIEW
      ) {
        const customerDetailPresent = !!this.props.customerStreetDetails;
        const addressInfoPresent = !!(
          this.props.lqResult &&
          this.props.lqResult.addrInfo &&
          this.props.lqResult.addrInfo.state &&
          this.props.lqResult.addrInfo.zip &&
          this.props.lqResult.addrInfo.addressLine1 &&
          this.props.lqResult.addrInfo.city
        );
        if (!customerDetailPresent && this.props.lqResult && this.props.lqResult.addrInfo) {
          logMetrics('Revisit lqsTagTriggered by addrInfo', `${JSON.stringify(this.props.lqResult.addrInfo)}`, 'Info');
        }
        if (!this.isResponsiveLQ && (this.isLqsCompleted || window.location.host.includes('qa')) && (customerDetailPresent || addressInfoPresent)) {
          setTimeout(() => {
            if (vtoken) {
              setTimeout(() => {
                checkNetworkAvailability(
                  customerDetailPresent ? this.props.customerStreetDetails : this.props.lqResult.addrInfo,
                  this.props.unitLookupDetails,
                  this.props.addressQualification,
                  this.props.loopQualApp,
                  this.props.dispatch,
                  this.props.cookies,
                  '',
                  this.props.fiosDetails,
                  this.props.moveDetails,
                  null,
                  true,
                );
              }, 750);
            } else {
              checkNetworkAvailability(
                customerDetailPresent ? this.props.customerStreetDetails : this.props.lqResult.addrInfo,
                this.props.unitLookupDetails,
                this.props.addressQualification,
                this.props.loopQualApp,
                this.props.dispatch,
                this.props.cookies,
                '',
                this.props.fiosDetails,
                this.props.moveDetails,
                null,
                true,
              );
            }
          }, 2000);
        } else {
          const checkLocalStorage =
            window.localStorage !== null &&
            window.localStorage.getItem !== null &&
            window.localStorage.getItem('visitor_id') === null &&
            window.localStorage.getItem('persist:loop-qual-app-root') !== null;
          if (checkLocalStorage && !(server_custStoreID || server_visitorId || server_digitalIgSession)) {
            Action.clearDetails(this.props.dispatch, this.props.cookies, this.props.fiosDetails, this.props.loopQualApp);
          }
        }
      } else if (
        !isLoggedIn &&
        !this.props.addressQualification.lqsTagTriggered &&
        this.props.loopQualApp.currentLQView === LQ_VIEWS.BULK_SERVICES_QUALIFIED_VIEW
      ) {
        this.updateCurrentView(LQ_VIEWS.ADDRESS_QUALIFICATION);
      }
    }
    if (responsekey && responsekey.data && responsekey.data.EnableCaptcha && responsekey.data.EnableCaptcha === 'Y') {
      Action.reloadReCaptchaDetails(this.props.dispatch);
      this.props.dispatch(Action.resetRecaptchaResponse());
    }
    // openModalWindow('LQAPP', this.props.dispatch);
  }

  componentDidUpdate() {
    const streetInputText = document.getElementById('streetInputText');
    if (streetInputText) {
      console.log(streetInputText);
      console.log(this.props.unitInputError);
      if (this.props.unitInputError) {
        streetInputText.classList.add('unitErrorStreet');
      } else {
        streetInputText.classList.remove('unitErrorStreet');
      }
    }
  }

  twsFilterFlow = (twsSrcInfo, twsText) => {
    let twsFlow = twsText;
    if (twsSrcInfo && twsSrcInfo.indexOf('tws') > -1) {
      twsFlow = twsSrcInfo.replace('tws_', '');
    }
    if (twsFlow === 'medical') {
      twsFlow = 'medical_new';
    }
    return twsFlow;
  };

  mvaBridgeApiCall = (bridgeAPIMsg) => {
    // Bridge API cpcCheckAvailability changes
    try {
      const bridgeAPIPostJson = JSON.stringify(bridgeAPIMsg);
      console.debug('bridgeapiPostJson---->', bridgeAPIPostJson);
      if (
        window.webkit &&
        window.webkit.messageHandlers &&
        window.webkit.messageHandlers.mfAppInterface &&
        window.webkit.messageHandlers.mfAppInterface.postMessage
      ) {
        window.webkit.messageHandlers.mfAppInterface.postMessage(bridgeAPIPostJson);
        console.debug('bridgeapiIOS Call Successful---->', bridgeAPIPostJson);
      }
      if (window.mfAppInterface && window.mfAppInterface.postMessage) {
        window.mfAppInterface.postMessage(bridgeAPIPostJson);
        console.debug('bridgeapiAndriod Call Successful---->', bridgeAPIPostJson);
      }
    } catch (err) {
      console.error('bridgeAPIPostMsg Error: ', err);
    }
  };

  initExternalTriggers = () => {
    console.log('initExternalTriggers invoked', this.props);
    const { externalTrigger = [] } = this.props;
    externalTrigger.forEach((elementSel) => {
      let extElement = [];
      extElement = elementSel && document.querySelectorAll(elementSel);
      if (extElement.length > 0) {
        extElement.forEach((el) => el.addEventListener('click', this.onCheckAvailabilityClick));
      } else {
        extElement = elementSel && document.getElementsByClassName(elementSel.substring(1));
        if (extElement.length > 0) {
          extElement.forEach((el) => el.addEventListener('click', this.onCheckAvailabilityClick));
        }
      }
    });
  };

  onClickSignInTrigger = () => {
    console.log('clicking button elems');
    if (this.props.loopQualApp.isLoggedInUserInfo && this.props?.loopQualApp?.TWS) {
      sendDataVzTagMessage('TWS FIOS LoggedIn customer', '');
      const signedURLTWS = getInHomeTWSURL(this.props.loopQualApp);
      console.log('signedURLTWS', signedURLTWS);
      window.location.href = signedURLTWS;
      return true;
    }
    if (this.props.loopQualApp.isLoggedIn) {
      openModalWindow('LQAPP', this.props.dispatch);
    } else {
      openModalWindow('LQAPP', this.props.dispatch);
      existingCustomerModal(this.props.dispatch, true);
    }
  };

  initExternalSignInTriggers = () => {
    console.log('initExternalsigninTriggers invoked', this.props);
    const { externalSignInTrigger = [] } = this.props;
    window.vzlqw.loadExternalSigninModal = this.onClickSignInTrigger;
    externalSignInTrigger.forEach((elementSel) => {
      let extElement = [];
      extElement = elementSel && document.querySelectorAll(elementSel);
      console.log('elems', extElement);
      if (extElement.length > 0) {
        extElement.forEach((el) => el.addEventListener('click', this.onClickSignInTrigger));
      }
    });
  };

  updateCurrentView = (targetView) => {
    // eslint-disable-next-line no-prototype-builtins
    if (targetView && LQ_VIEWS.hasOwnProperty(targetView)) {
      this.props.dispatch(Action.updateCurrentView(targetView));
    }
  };

  keydownOrMousedownCheck = () => {
    window.document.body.classList.add('hide-focus-outline'); // initially hide focus outline
    window.document.onkeydown = (e) => {
      const bodyClassList = window.document.body.classList; // enable focus outline when tabbing
      if (bodyClassList.contains('hide-focus-outline') && (e.keyCode === 9 || e.keyCode === 13)) {
        bodyClassList.remove('hide-focus-outline');
      }
    };
    window.document.onmousedown = () => {
      const bodyClassList = window.document.body.classList; // disable focus outline on mouse click
      if (!bodyClassList.contains('hide-focus-outline')) {
        bodyClassList.add('hide-focus-outline');
      }
    };
  };

  renderContent = () => {
    let content = stickyBannerContent.default;
    if (this.props.loopQualApp.flowType === Flowtype.MOVERS) {
      content = stickyBannerContent.default;
    } else if (this.props.loopQualApp.isLoggedIn && this.props.loopQualApp.currentLQView === LQ_VIEWS.BULK_SERVICES_QUALIFIED_VIEW) {
      content = stickyBannerContent.default_bulk;
    } else if (this.props.loopQualApp.widgetHost === '5g') {
      content = stickyBannerContent.default_5g;
    } else if (this.props.loopQualApp.widgetHost === 'lte') {
      content = stickyBannerContent.default_lte;
    } else if (this.props.loopQualApp.widgetHost === 'fios' || this.props.loopQualApp.lqPlatform === Platforms.FIOS) {
      content = stickyBannerContent.default_fios;
    }
    return content;
  };

  getAddress = () => {
    const { lqResult, customerStreetDetails, revisitAddress } = this.props;
    return lqResult.lqAddress && lqResult.lqAddress.length > 4
      ? lqResult.lqAddress
      : customerStreetDetails && customerStreetDetails.disprawstr
      ? customerStreetDetails.disprawstr
      : revisitAddress;
  };

  invokeInitTag = (isOrderNow, initType, detailPageName, inhomeOpenView = false, loopQualApp, customerStreetDetails) => {
    setTimeout(() => {
      let modifiedDetailPageName = detailPageName;
      if (!inhomeOpenView) {
        if (isOrderNow && loopQualApp && loopQualApp.checkAvailBtnTrack !== 'check avail - nav') {
          // eslint-disable-next-line no-param-reassign
          modifiedDetailPageName = loopQualApp.checkAvailBtnTrack;
          if (modifiedDetailPageName === 'not eligible') {
            modifiedDetailPageName = 'lq result - noteligible';
          }
        }
        if (loopQualApp && loopQualApp.currentLQView === LQ_VIEWS.COMMON_LQ) {
          modifiedDetailPageName = 'Get Verizon Home Services';
        }
        openViewVzTag(modifiedDetailPageName);
      } else {
        getVZDLInit(initType, { lqInfo: loopQualApp, addressInfo: customerStreetDetails || '' }, isOrderNow);
      }
    }, 2000);
  };

  isVendorFlowParams = () => {
    if (window.location.search) {
      const params = new URLSearchParams(window.location.search.toLowerCase());
      const vendorcVal = params.get('c');
      const vendorabrVal = params.get('abr');
      const vendorOutletId = params.get('outletid');
      const vendorLocationCode = params.get('locationcode');
      if (vendorOutletId && vendorLocationCode) {
        setToStorage('vendorOutletId', vendorOutletId);
        setToStorage('vendorLocationCode', vendorLocationCode);
      }
      if (vendorabrVal && vendorcVal) {
        setToStorage('vendorabrVal', vendorabrVal);
        setToStorage('vendorid', vendorcVal);
      }
      if ((vendorabrVal && vendorcVal) || (vendorOutletId && vendorLocationCode)) {
        const vendordetails = {
          vendorId: vendorcVal,
          locationCode: vendorLocationCode,
          outletid: vendorOutletId,
          abrval: vendorabrVal,
        };
        updateVendorDetails(vendordetails, this.props.dispatch);
      } else {
        updateVendorDetails('', this.props.dispatch);
      }
    } else {
      updateVendorDetails('', this.props.dispatch);
    }
  };

  setColorTheme = (monarchEnabled) => {
    const colors = configLQTheme(monarchEnabled);
    this.setState({ colors });
  };

  renderView = () => {
    console.log('state', this.state);
    const { mode, miniLq: miniLqConfig, loopQualApp, lqResult, multiServiceDetails, addressQualification, modalOverlay, addressLookup } = this.props;
    const { colors } = this.state;
    const displayContent = this.renderContent();
    const lqAddress = this.getAddress() || '';
    const currentBaseView = getCurrentBaseView(loopQualApp.currentLQView);
    const hideFccMiniLq = this.props && this.props.loopQualApp && this.props.loopQualApp.hideFccMiniLq;
    const params1 = new URLSearchParams(window.location.search);
    const checkBulkLoadParameter = params1.get('bulkEXPLoader') === 'Y';
    const isSkeletonView = loopQualApp?.showSkeleton;
    let modalContent = (
      <>
        {currentBaseView}
        <Loader active={this.props.loaderStatus || isSkeletonView} />
      </>
    );
    if (modalOverlay.existingCustomerModal) {
      modalContent = <SignIn loopQualApp={loopQualApp} lqResult={lqResult} couponLQ={loopQualApp.couponLQ} dispatch={this.props.dispatch} />;
    }
    if (mode === 'inline') {
      return (
        <>
          {currentBaseView}
          <Loader active={this.props.loaderStatus} />
        </>
      );
    }
    return (
      <>
        {this.props.loaderStatus && checkBulkLoadParameter && <Loader active={this.props.loaderStatus} />}
        {miniLqConfig.enabled && isSkeletonView && (
          <DomComponent>
            <MinLQSkeleton />
          </DomComponent>
        )}
        {miniLqConfig.enabled && !hideFccMiniLq && !isSkeletonView && (
          <DomComponent>
            <MiniLq
              responsiveLQEmbeded={this.isResponsiveLQEmbeded}
              onClick={this.onCheckAvailabilityClick}
              displayContent={displayContent}
              miniLqConfig={miniLqConfig}
              lqAddress={lqAddress}
              lqResult={lqResult}
              widgetHost={loopQualApp.widgetHost}
              multiServiceDetails={multiServiceDetails}
              addressQualification={addressQualification}
              loopQualApp={loopQualApp}
              modalOverlay={modalOverlay}
              dispatch={this.props.dispatch}
              moveDetails={this.props.moveDetails}
              notify={this.props.notify}
              fiosDetails={this.props.fiosDetails}
              customerStreetDetails={this.props.customerStreetDetails}
              bulkQualDetails={this.props.bulkQualDetails}
              addressLookup={addressLookup}
              unitInputError={this.props.unitInputError}
              colors={colors}
            />
          </DomComponent>
        )}
        {modalOverlay.isModalOpened && modalOverlay.modalId === 'LQAPP' && (
          <ModalOverlay
            modalBody={modalContent}
            modalOverlay={modalOverlay}
            dispatch={this.props.dispatch}
            loopQualApp={loopQualApp}
            addressQualification={addressQualification}
          />
        )}
      </>
    );
  };

  closeModal = () => {
    closeModalWindow(this.props.dispatch);
  };

  onCheckAvailabilityClick = (e) => {
    if (
      this.props.loopQualApp.isLoggedIn &&
      this.props.loopQualApp.roleType &&
      (this.props.loopQualApp.roleType === 'prepay' || this.props.loopQualApp.roleType === 'guest')
    ) {
      this.updateCurrentView(LQ_VIEWS.LBO_VIEW);
      openModalWindow('LQAPP', this.props.dispatch);
      return true;
    }

    if (this.props?.loopQualApp?.isLoggedInUserInfo && this.props?.loopQualApp?.TWS) {
      sendDataVzTagMessage('TWS FIOS LoggedIn customer', '');
      const signedURLTWS = getInHomeTWSURL(this.props.loopQualApp);
      console.log('signedURLTWS', signedURLTWS);
      window.location.href = signedURLTWS;
      return true;
    }
    const el = document.getElementById('expand-contract');
    const bulkPlanVisited = !!window.sessionStorage.getItem('bulkPlanVisited');
    if (el && el.className === 'collapsed') {
      document.documentElement.scrollTop = 0;
      const mini = document.getElementsByClassName('minilq');
      if (mini && mini[0] && mini[0].style) {
        mini[0].style.inset = '';
        mini[0].style.top = '0px';
        mini[0].style.inset = '';
      }
      el.className = 'expanded';
      const streetInput = document.querySelector('#streetaddress');
      if (this.props.loopQualApp && !this.props.loopQualApp.lq4 && streetInput) {
        streetInput.focus();
      }
      greyOut();
      return true;
    }
    const lqResponseStorage = window.sessionStorage.getItem('LQResponse');
    const params = new URLSearchParams(window.location.search);
    const { loopQualApp } = this.props;
    if (!checkTokenValidity()) {
      genarateToken();
    }
    if (this.props.addressQualification.helpView) {
      updateHelpView(this.props.dispatch, false);
    }
    if (this.props.loopQualApp.sourceInfo?.toUpperCase() === 'CPC') {
      // const isMva = isMYVZWAPP();
      this.updateCurrentView(LQ_VIEWS.BLANK_LOADER_VIEW);
      this.props.dispatch(showLoader());
      openModalWindow('LQAPP', this.props.dispatch);
      /* if (isMva) {
        pageViewPageVzTag('cpc check availability');
      } */
      if (bulkPlanVisited) {
        checkLoggedIn(this.props.dispatch, this.props.cookies, this.props.loopQualApp, '', this.props.addressQualification, this.props.moveDetails);
      } else {
        this.props.dispatch(Action.updateIsBulkQual(true));
        setToStorage('FWALqAALThrottle', 'BULK');
        checkLoggedInBulkQual(
          this.props.dispatch,
          this.props.loopQualApp,
          this.props.fiosDetails,
          this.props.addressQualification,
          this.props.customerStreetDetails,
          this.props.unitLookupDetails,
          this.props.moveDetails,
          this.props.cookies,
          false,
          this.props.bulkQualDetails,
        );
      }
    }

    if (this.isResponsiveLQ) {
      const responsiveLQBulkRes = !!window.sessionStorage.getItem('ResponsiveLQBulkRes');
      const clearDetailsLQ = window.sessionStorage.getItem('clearDetailsLQ');
      this.props.dispatch(Action.updateHideMiniLq(false));
      this.props.dispatch(Action.updateSkeleton(false));
      window.scrollTo({
        top: 0,
        left: 0,
        behavior: 'smooth',
      });
      setTimeout(() => {
        greyOut();
        const streetInput = document.querySelector('#streetaddress');
        if (streetInput) {
          streetInput.focus();
        }
      }, 500);
      if ((!this.props.loopQualApp.isLoggedIn && !lqResponseStorage) || clearDetailsLQ) {
        inVokeLqCheckNetworkAvailability(
          e,
          this.props.customerStreetDetails,
          this.props.unitLookupDetails,
          this.props.addressQualification,
          this.props.loopQualApp,
          this.props.dispatch,
          this.props.cookies,
          this.props.streetInput,
          this.props.fiosDetails,
          this.props.moveDetails,
          null,
          this.props.parserAttempt,
          true,
        );
      } else if (this.props.loopQualApp.isLoggedIn) {
        openModalWindow('LQAPP', this.props.dispatch);
        if (!responsiveLQBulkRes) {
          this.props.dispatch(Action.updateIsBulkQual(true));
          this.props.dispatch(showLoader());
          this.updateCurrentView(LQ_VIEWS.BLANK_LOADER_VIEW);
          checkLoggedInBulkQual(
            this.props.dispatch,
            this.props.loopQualApp,
            this.props.fiosDetails,
            this.props.addressQualification,
            this.props.customerStreetDetails,
            this.props.unitLookupDetails,
            this.props.moveDetails,
            this.props.cookies,
            false,
            this.props.bulkQualDetails,
          );
        }
      }
    } else if (
      (window.location.search &&
        (window.location.search.toLowerCase().indexOf('move=y') > -1 || window.location.search.toLowerCase().indexOf('type=moving') > -1)) ||
      this.props.loopQualApp.flowType === Flowtype.MOVERS
    ) {
      openModalWindow('LQAPP', this.props.dispatch);
      if (!this.props.loopQualApp.isLoggedIn && !this.props.loopQualApp.isLoggedInUserInfo) {
        existingCustomerModal(this.props.dispatch, true);
      } else if (this.props.loopQualApp.isLoggedIn && this.props.loopQualApp.currentLQView === LQ_VIEWS.MULTIPLE_SERVICES_QUALIFIED_MV_VIEW)
        this.updateCurrentView(LQ_VIEWS.MULTIPLE_SERVICES_QUALIFIED_MV_VIEW);
    } else if (
      this.props.loopQualApp.isLoggedIn &&
      this.props.loopQualApp.isBulkQual &&
      this.props.bulkQualDetails.bulkProfileDetails &&
      this.props.loopQualApp.isNSAFlow &&
      this.props.loopQualApp.currentLQView !== LQ_VIEWS.ADDRESS_QUALIFICATION && // CXTDT-526128 Empty Billing and unQualified to suppress open modal
      !this.props.addressQualification.lqsTagTriggered
    ) {
      openModalWindow('LQAPP', this.props.dispatch);
      if (this.props.loopQualApp.currentLQView === LQ_VIEWS.BULK_SERVICES_QUALIFIED_VIEW && !this.props.bulkQualDetails.singleFromBulk) {
        openViewVzTag(`plq result -multi${this.props.bulkQualDetails.qualifiedlist}`);
      } else if (this.props.loopQualApp.currentLQView === LQ_VIEWS.UN_QUALIFIED_VIEW) {
        sendDataVzTagLqs('single', this.props.bulkQualDetails.preferredbulkServiceDetail);
        if (
          this.props.bulkQualDetails.preferredbulkServiceDetail &&
          this.props.bulkQualDetails.preferredbulkServiceDetail.bulkQualAddressType &&
          this.props.bulkQualDetails.preferredbulkServiceDetail.bulkQualAddressType !== ''
        ) {
          logMetrics('bulkQualAddressType', this.props.bulkQualDetails.preferredbulkServiceDetail.bulkQualAddressType, 'Info');
        }
      } else if (
        lqResponseStorage &&
        this.props.bulkQualDetails.singleFromBulk &&
        !this.props.lqResult.uberPinEligible &&
        (this.props.lqResult.fiveGQualified || this.props.lqResult.qualifiedCBand || this.props.lqResult.lteQualified)
      ) {
        if (config.autoQualOrderingRedirect === false || this.props.loopQualApp.TWS !== null || this.props.miniLq.enabled === 'false') {
          openModalWindow('LQAPP', this.props.dispatch);
        } else {
          lqsTagTriggered(this.props.dispatch, true);
          sendDataVzTagLqs('single', this.props.bulkQualDetails.preferredbulkServiceDetail);
          if (
            this.props.bulkQualDetails.preferredbulkServiceDetail &&
            this.props.bulkQualDetails.preferredbulkServiceDetail.bulkQualAddressType &&
            this.props.bulkQualDetails.preferredbulkServiceDetail.bulkQualAddressType !== ''
          ) {
            logMetrics('bulkQualAddressType', this.props.bulkQualDetails.preferredbulkServiceDetail.bulkQualAddressType, 'Info');
          }
          orderNowFiveGNsa(
            this.props.addressQualification,
            loopQualApp,
            this.props.dispatch,
            true,
            this.props.moveDetails,
            false,
            this.props.customerStreetDetails,
            this.props.unitLookupDetails,
            this.props.fiosDetails,
            false,
            true,
            this.props.bulkQualDetails.preferredbulkServiceDetail,
          );
        }
      }
    } else if (lqResponseStorage && this.props.addressQualification.lqsTagTriggered && loopQualApp.plansPageUrl !== '') {
      if (
        this.props.lqResult &&
        ((loopQualApp.currentLQView === LQ_VIEWS.FIOS_QUALIFIED_VIEW && this.props.lqResult.fiosQualified) ||
          ((loopQualApp.currentLQView === LQ_VIEWS.FIOS_LOADING_VIEW || loopQualApp.currentLQView === LQ_VIEWS.BLANK_LOADER_VIEW) &&
            this.props.lqResult.fiosExtremeHappy) ||
          (loopQualApp.currentLQView === LQ_VIEWS.FIVEG_HOME_QUALIFIED_VIEW &&
            (this.props.lqResult.fiveGQualified || this.props.lqResult.qualifiedCBand)) ||
          (loopQualApp.currentLQView === LQ_VIEWS.LTE_HOME_QUALIFIED_VIEW && this.props.lqResult.lteQualified) ||
          (loopQualApp.currentLQView === LQ_VIEWS.RESUME_SMART_CART_VIEW &&
            (this.props.lqResult.fiveGQualified || this.props.lqResult.qualifiedCBand || this.props.lqResult.lteQualified)))
      ) {
        if (config.autoQualOrderingRedirect === false) {
          openModalWindow('LQAPP', this.props.dispatch);
        } else if (this.props.lqResult.fiveGQualified || this.props.lqResult.qualifiedCBand || this.props.lqResult.lteQualified) {
          openModalWindow('LQAPP', this.props.dispatch);
          if (this.props.loopQualApp.isACPFlow) {
            const languageType = window.location.host.indexOf('espanol') > -1 ? 'S' : 'E';
            const redirectionPage = this.props?.loopQualApp?.midnight ? 'progressiveplan' : 'plansOverview';
            const emreiosFetchBody = {
              registrationLanguage: languageType,
              businessLine: 'FWA',
              customerType: this.props?.loopQualApp?.isLoggedIn ? 'E' : 'N',
              fiosSessionId: '',
              redirectUrl: this.props?.loopQualApp?.isLoggedIn
                ? `https://${window.location.host}/sales/home/${redirectionPage}.html?ec=y&acp=y&intcmp=vf3-plansoverview-continue`
                : `https://${window.location.host}/sales/home/${redirectionPage}.html?acp=y&intcmp=vf3-plansoverview-continue`,
              nonAALFlag: false,
            };
            invokeUCAbandonedCart(
              this.props.addressQualification,
              this.props.loopQualApp,
              this.props.dispatch,
              true,
              emreiosFetchBody,
              this.props.moveDetails,
              this.props.customerStreetDetails,
              this.props.unitLookupDetails,
              this.props.fiosDetails,
              this.props.bulkQualDetails.preferredbulkServiceDetail,
            );
          } else if (!this.props.lqResult.uberPinEligible) {
            orderNowFiveGNsa(
              this.props.addressQualification,
              loopQualApp,
              this.props.dispatch,
              true,
              this.props.moveDetails,
              false,
              this.props.customerStreetDetails,
              this.props.unitLookupDetails,
              this.props.fiosDetails,
              false,
              false,
            );
          }
        } else if (loopQualApp.currentLQView === LQ_VIEWS.LBO_VIEW || (this.props.lqResult.fiosQualified && !this.props.lqResult.fiosExtremeHappy)) {
          openModalWindow('LQAPP', this.props.dispatch);
        } else {
          redirectToPlansPage(loopQualApp.plansPageUrl);
        }
      } else {
        openModalWindow('LQAPP', this.props.dispatch);
        this.invokeInitTag(false, 'checkAvaillqInit', 'lq check avail', false, this.props.loopQualApp);
        if (loopQualApp.currentLQView !== LQ_VIEWS.LBO_VIEW) {
          this.updateCurrentView(LQ_VIEWS.ADDRESS_QUALIFICATION);
        }
      }
    } else if (
      (lqResponseStorage && this.props.addressQualification.lqsTagTriggered) ||
      (this.props.lqResult &&
        ((loopQualApp.currentLQView === LQ_VIEWS.FIOS_QUALIFIED_VIEW && this.props.lqResult.fiosQualified) ||
          ((loopQualApp.currentLQView === LQ_VIEWS.FIOS_LOADING_VIEW || loopQualApp.currentLQView === LQ_VIEWS.BLANK_LOADER_VIEW) &&
            this.props.lqResult.fiosExtremeHappy) ||
          (loopQualApp.currentLQView === LQ_VIEWS.FIVEG_HOME_QUALIFIED_VIEW &&
            (this.props.lqResult.fiveGQualified || this.props.lqResult.qualifiedCBand)) ||
          (loopQualApp.currentLQView === LQ_VIEWS.LTE_HOME_QUALIFIED_VIEW && this.props.lqResult.lteQualified) ||
          (loopQualApp.currentLQView === LQ_VIEWS.RESUME_SMART_CART_VIEW &&
            (this.props.lqResult.fiveGQualified || this.props.lqResult.qualifiedCBand || this.props.lqResult.lteQualified))))
    ) {
      if (this.props.lqResult.fiosExtremeHappy) {
        redirectToPlansPage(loopQualApp.plansPageUrl);
      }
      openModalWindow('LQAPP', this.props.dispatch);
      if (
        this.props.loopQualApp.isLoggedIn &&
        this.props.loopQualApp.roleType &&
        (this.props.loopQualApp.roleType === 'prepay' || this.props.loopQualApp.roleType === 'guest')
      ) {
        this.updateCurrentView(LQ_VIEWS.LBO_VIEW); // Showing LBO for prepay guest pre loggedIn LQS customer
      }
      this.invokeInitTag(true, 'checkAvaillqInit', 'lq check avail', false, loopQualApp);
    } else if (lqResponseStorage && loopQualApp.currentLQView === LQ_VIEWS.UN_QUALIFIED_VIEW) {
      openModalWindow('LQAPP', this.props.dispatch);
      this.updateCurrentView(LQ_VIEWS.UN_QUALIFIED_VIEW);
      this.invokeInitTag(true, 'checkAvaillqInit', 'lq check avail', false, loopQualApp);
    } else if (
      loopQualApp.isLoggedIn &&
      loopQualApp.currentLQView === LQ_VIEWS.BULK_SERVICES_QUALIFIED_VIEW &&
      !this.props.bulkQualDetails.singleFromBulk
    ) {
      openModalWindow('LQAPP', this.props.dispatch);
      openViewVzTag(`plq result -multi${this.props.bulkQualDetails.qualifiedlist}`);
    } else if (
      (this.isTwsFilterdFlow && !this.props.customerStreetDetails && (this.props.loopQualApp.TWS || this.props.loopQualApp.isACPFlow)) ||
      (!this.isTwsFilterdFlow && (this.props.loopQualApp.TWS || this.props.loopQualApp.isACPFlow))
    ) {
      let subFlowType = 'lq check avail';
      if (this.isTwsFilterdFlow) {
        // openModalWindow('LQAPP', this.props.dispatch);
        this.props.dispatch(Action.updateHideMiniLq(false));
        this.props.dispatch(Action.updateSkeleton(false));
        this.props.dispatch(Action.updateCurrentView(LQ_VIEWS.ADDRESS_QUALIFICATION));
        setTimeout(() => {
          greyOut();
        }, 200);
        const streetInput = document.querySelector('#streetaddress');
        if (streetInput) {
          streetInput.focus();
        }
      } else {
        openModalWindow('LQAPP', this.props.dispatch);
        let currentView = this.props.loopQualApp.currentLQView;
        if (
          this.props.loopQualApp.isLoggedIn &&
          (this.props.addressQualification?.lqResult?.existingFWA ||
            window.sessionStorage.getItem('existingFwa') === 'Y' ||
            this.props.bulkQualDetails.preferredbulkServiceDetail.existingFWA) &&
          window.sessionStorage.getItem('isVfExist') === 'false'
        ) {
          if (window.sessionStorage.getItem('isVfEmail') === 'true') {
            currentView = LQ_VIEWS.VF_QUAL_FWA;
          } else {
            currentView = LQ_VIEWS.VF_QUESTIONAIRE;
          }
        } else if (!this.props.loopQualApp.isLoggedIn && window.sessionStorage.getItem('isVfEmailNC') === 'true') {
          currentView = LQ_VIEWS.ADDRESS_QUALIFICATION;
        } else if (!this.props.loopQualApp.isLoggedIn) {
          currentView = LQ_VIEWS.COMMON_LQ;
        }
        this.updateCurrentView(currentView);
        subFlowType =
          window.sessionStorage.getItem('isVfEmailNC') === 'true'
            ? ' lq check avail | vfemail'
            : this.props.loopQualApp.currentLQView === 'VF_QUESTIONAIRE'
            ? 'thank you for being loyal customer'
            : 'lq check avail';
      }
      this.invokeInitTag(false, 'checkAvaillqInit', subFlowType, false, this.props.loopQualApp);
    } else if (
      this.props.loopQualApp.isLoggedIn &&
      this.props.loopQualApp.roleType &&
      (this.props.loopQualApp.roleType === 'prepay' || this.props.loopQualApp.roleType === 'guest')
    ) {
      openModalWindow('LQAPP', this.props.dispatch);
      this.updateCurrentView(LQ_VIEWS.LBO_VIEW);
    } else if (this.props.loopQualApp.lq4) {
      inVokeLqCheckNetworkAvailability(
        e,
        this.props.customerStreetDetails,
        this.props.unitLookupDetails,
        this.props.addressQualification,
        this.props.loopQualApp,
        this.props.dispatch,
        this.props.cookies,
        this.props.streetInput,
        this.props.fiosDetails,
        this.props.moveDetails,
        null,
        this.props.parserAttempt,
        true,
      );
    } else if (this.props.loopQualApp.isComboMFJT && !this.props.loopQualApp.isLoggedIn && !this.props.loopQualApp.isLoggedInUserInfo) {
      openModalWindow('LQAPP', this.props.dispatch);
    } else {
      // const params = new URLSearchParams(window.location.search.toLowerCase());
      const newConnection = params.get('PN')
        ? params.get('PN')
        : window.sessionStorage.getItem('couponToken')
        ? window.sessionStorage.getItem('couponToken')
        : null;
      openModalWindow('LQAPP', this.props.dispatch);
      if (newConnection) {
        this.updateCurrentView(LQ_VIEWS.COMMON_LQ);
      } else if (this.props.loopQualApp.sourceInfo?.toUpperCase() === 'CPC') {
        this.updateCurrentView(LQ_VIEWS.BLANK_LOADER_VIEW);
        this.props.dispatch(showLoader());
      } else if (loopQualApp.currentLQView !== LQ_VIEWS.LBO_VIEW) {
        this.updateCurrentView(LQ_VIEWS.ADDRESS_QUALIFICATION);
      }
      this.invokeInitTag(false, 'checkAvaillqInit', 'lq check avail', false, this.props.loopQualApp);
    }
    const seePlanCtaLearn = document.querySelector('.w_modal.m_large.vzrf.active .modal_x');
    if (seePlanCtaLearn) {
      seePlanCtaLearn.click();
    }
    if (this.props.loopQualApp.sourceInfo === 'DigitalCrossSell' && this.props.loopQualApp.mhToken === null && window.mhToken) {
      this.props.dispatch(Action.updateMhToken(window.mhToken));
    }
    const unitErrorElem = document.getElementById('uniterror');
    if (unitErrorElem) {
      unitErrorElem.focus();
    }
  };

  render() {
    return this.renderView();
  }
}
const mapStateToProps = (state) => ({
  loopQualApp: state.loopQualApp,
  loaderStatus: state.loader.loaderStatus,
  customerStreetDetails: state.addressLookup.customerStreetDetails,
  revisitAddress: state.addressLookup.revisitAddress,
  streetInput: state.addressLookup.streetInput,
  addressLookup: state.addressLookup,
  unitInputError: state.unitLookup.unitInputError,
  unitLookupDetails: state.unitLookup,
  addressQualification: state.addressQualification,
  modalOverlay: state.modalOverlay,
  lqResult: state.addressQualification.lqResult || {},
  multiServiceDetails: state.multiServiceDetails,
  isLoggedIn: state.loopQualApp.isLoggedIn,
  fiosDetails: state.fiosDetails,
  moveDetails: state.moveDetails,
  notify: state.notify,
  bulkQualDetails: state.bulkQualDetails,
  parserAttempt: state.addressLookup.parserAttempt,
});
export default withCookies(connect(mapStateToProps)(LoopQualApp));
LoopQualApp.propTypes = {
  loopQualApp: PropTypes.string,
  streetInput: PropTypes.string,
  addressLookup: PropTypes.string,
  dispatch: PropTypes.string,
  unitLookupDetails: PropTypes.string,
  externalTrigger: PropTypes.string,
  lqResult: PropTypes.string,
  mode: PropTypes.string,
  isLoggedIn: PropTypes.string,
  miniLq: PropTypes.string,
  addressQualification: PropTypes.string,
  customerStreetDetails: PropTypes.string,
  modalOverlay: PropTypes.string,
  revisitAddress: PropTypes.string,
  cookies: PropTypes.string,
  multiServiceDetails: PropTypes.string,
  loaderStatus: PropTypes.string,
  bulkQualDetails: PropTypes.object,
  moveDetails: PropTypes.object,
  fiosDetails: PropTypes.object,
  notify: PropTypes.object,
  parserAttempt: PropTypes.object,
  unitInputError: PropTypes.bool,
  externalSignInTrigger: PropTypes.any,
};
