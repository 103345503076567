import { getWidgetHost, setToStorage, getCookie } from './lqServiceUtil';
import { getRenderSettings } from '../config';
import { store } from '../reducers/configure-store';
import { Flowtype } from '../constants/common';
import { getSubCategory, getFlowName as getUtilFlowName, getTWSName, getChannelName } from './vzTagUtil';

const widgetHost = getWidgetHost(window.location.pathname);
const renderSetting = getRenderSettings();
const vzdl = require('./vzdlData.json');

let retrySendCustomEventCount = 0;

export const getVZDLInit = (pageInfo, stateInfo, isOrderNow = false) => {
  const initData = scDataVZDLDetails[pageInfo];
  if (window.vzdl) {
    setToStorage('learnVzdlObj', JSON.stringify(window.vzdl));
  }
  populateVzdlPageValues(initData, stateInfo, isOrderNow);
  populateVzdlEventObject(initData, stateInfo, isOrderNow);
  populateVzdlUserValues(initData, stateInfo);
  getFlowName();
  setTimeout(() => {
    const newEvent = {
      error: vzdl.error,
      page: vzdl.page,
      event: vzdl.event,
      search: vzdl.search,
      target: vzdl.target,
      txn: vzdl.txn,
      rtci: vzdl.rtci,
      user: vzdl.user,
      utils: vzdl.utils,
      env: vzdl.env,
      cmp: vzdl.cmp,
    };
    window.vzdl = newEvent;
    window.vzdl = window.vzdl || {};
    sendCustomEvent('pageViewOmni');
  }, 1000);
};

export const scDataVZDLDetails = {
  checkAvaillqInit: {
    name: '',
    detail: 'lq check avail',
    contentChannel: '/vz/residential/order/prospect/lqfull',
    events: 'event117',
  },
  checkAvaillqInitCare: {
    name: 'check availability',
    detail: 'Get Verizon Home Services',
    contentChannel: '/vz/residential/order/prospect/lqfull',
    events: 'event117',
  },
  checkAvaillqInitFullPage: {
    name: 'check availability',
    detail: 'lq check avail - landing',
    contentChannel: '/vz/residential/order/prospect/lqfull',
    events: 'event117',
  },
};

const sendCustomEvent = (evt) => {
  retrySendCustomEventCount = 0;
  if (window.dtm_doplugins) {
    if (document.body.dispatchEvent) {
      const event = new Event(evt);
      document.body.dispatchEvent(event);
    }
  } else {
    setTimeout(() => {
      retrySendCustomEvent(evt);
    }, 1000);
  }
};

const retrySendCustomEvent = (evt) => {
  retrySendCustomEventCount += retrySendCustomEventCount;
  if (window.dtm_doplugins) {
    sendCustomEvent(evt);
  } else if (retrySendCustomEventCount < 5) {
    setTimeout(() => {
      retrySendCustomEvent(evt);
    }, 1000);
  }
};
const populateVzdlPageValues = (pageInfo, stateInfo, isOrderNow) => {
  let device = 'eordering';
  if (/android|webos|iphone|ipod|blackberry|iemobile|opera mini/i.test(navigator.userAgent.toLowerCase())) {
    device = 'mobile';
  } else if (/Tablet|iPad/i.test(navigator.userAgent.toLowerCase())) {
    device = 'eordering tablet';
  } else {
    device = 'eordering';
  }
  const adobesuite = document.domain.indexOf('www.verizon.com') !== -1 ? 'verizontelecomglobal' : 'verizontelecomglobaldev';
  vzdl.page.detail = pageInfo.detail;
  vzdl.page.applicationName = device;
  vzdl.page.adobeReportSuite = adobesuite;
  vzdl.page.channelSession = window.sessionStorage && window.sessionStorage.getItem('visit_id') ? window.sessionStorage.getItem('visit_id') : '';
  if (window.location.search && window.location.search.indexOf('collateral') > -1) {
    vzdl.page.fireProactiveChat = 'Y';
  }
  if (window.location.search && window.location.search.indexOf('cmp') > -1) {
    const params = new URLSearchParams(window.location.search);
    vzdl.cmp.all = params.get('cmp');
  }
  if (isOrderNow && stateInfo && stateInfo.lqInfo && stateInfo.lqInfo.checkAvailBtnTrack !== 'check avail - nav') {
    vzdl.page.detail = stateInfo.lqInfo.checkAvailBtnTrack;
  }
  if (pageInfo.name !== '') {
    vzdl.page.name = pageInfo.name;
  } else if (pageInfo.name === '') {
    vzdl.page.name = window.vzdl && window.vzdl.page ? window.vzdl.page.name : '';
  }
};
const populateVzdlUserValues = () => {
  vzdl.user.session = window.sessionStorage && window.sessionStorage.getItem('visitor_id') ? window.sessionStorage.getItem('visitor_id') : '';
  const getStoreData = store.getState();
  const { loopQualApp } = getStoreData;
  vzdl.user.accountType = 'new customer';
  if (loopQualApp && (loopQualApp.isLoggedIn || loopQualApp.isLoggedInUserInfo)) {
    vzdl.user.authStatus = 'logged in';
    vzdl.user.accountType = 'existing Customer';
  } else vzdl.user.authStatus = 'anonymous';
};
const populateVzdlEventObject = (pageInfo, stateInfo, isOrderNow) => {
  if (isOrderNow && stateInfo && stateInfo.lqInfo && stateInfo.lqInfo.checkAvailBtnTrack !== 'check avail - nav') {
    vzdl.event.value = '';
  } else {
    vzdl.event.value = pageInfo.events;
  }
};

export const getFlowName = (loopQualApp = null, addressQualificationStore = null, fiosDetailsStore = null) => {
  if (addressQualificationStore && addressQualificationStore.lqResult && addressQualificationStore.lqResult.preOrderInServiceDate) {
    vzdl.page.flow = loopQualApp && loopQualApp.isLoggedIn ? '5g_preorder_aal' : '5g_preorder_nse';
  } else if (
    loopQualApp &&
    loopQualApp.flowType &&
    loopQualApp.flowType === Flowtype.MOVERS &&
    window.sessionStorage.getItem('flowType') &&
    window.sessionStorage.getItem('subFlowType')
  ) {
    vzdl.page.flow = window.sessionStorage.getItem('flowType');
    vzdl.page.subFlow = window.sessionStorage.getItem('subFlowType');
  } else if (fiosDetailsStore && fiosDetailsStore.vendorDetails && fiosDetailsStore.vendorDetails.vendor_name) {
    const vendorFlowName = fiosDetailsStore.vendorDetails.vendor_name;
    if (vendorFlowName === 'Clearlink') {
      vzdl.page.flow = 'lq widget + cl';
    } else if (vendorFlowName === 'RedVentures') {
      vzdl.page.flow = 'lq widget + rv';
    } else if (vendorFlowName === 'Target') {
      vzdl.page.flow = 'lq widget + target';
    } else if (vendorFlowName === 'Quology') {
      vzdl.page.flow = 'lq widget + Qo';
    } else {
      vzdl.page.flow = `lq widget + ${vendorFlowName}`;
    }
  } else {
    vzdl.page.flow = 'lq widget';

    if (loopQualApp && loopQualApp.TWS) {
      vzdl.page.flow = `${vzdl.page.flow}|${getTWSName(loopQualApp.TWS)}`;
    } else if (loopQualApp && loopQualApp.isACPFlow) {
      vzdl.page.flow = `${vzdl.page.flow}|vf`;
    } else if (loopQualApp && loopQualApp.newConnection) {
      vzdl.page.flow = `${vzdl.page.flow}|daw`;
    }
  }
  if (widgetHost && widgetHost === '5g') {
    if (window.vzdl && window.vzdl.page) {
      window.vzdl.page.flow = vzdl.page.flow;
    }
  }
  return vzdl.page.flow;
};

export const dataTrack = (type, name) => {
  const getStoreData = store.getState();
  const { loopQualApp, fiosDetails } = getStoreData;
  let subFlowName = '';
  const flowName = getUtilFlowName(getStoreData, fiosDetails);
  let throttleValue = 'nsa version|vhi home|geo|mhnewsignin|suppresslqa';
  if (loopQualApp && loopQualApp.isLoggedIn) {
    throttleValue = loopQualApp.isBulkQual
      ? 'nsa version|vhi home|passive-lq|geo|mhnewsignin|suppresslqa'
      : 'nsa version|vhi home|bau-lq|geo|mhnewsignin|suppresslqa';
  }
  if (loopQualApp.isComboMFJT) {
    throttleValue += '|mhjt y';
    subFlowName = 'mhjt';
  }
  const channelId = getChannelName(getStoreData);
  const v220 = getCookie('cdlThrottleList') || window.sessionStorage.getItem('LqThrottle') ? window.sessionStorage.getItem('LqThrottle') : '';
  return JSON.stringify({
    type,
    name,
    data: {
      page: {
        flow: flowName,
        throttle: throttleValue,
        channel: 'Ordering',
        subCategory: getSubCategory(renderSetting, getStoreData),
        subFlow: subFlowName,
        displayChannel: channelId,
        sourceChannel: channelId,
      },
      target: {
        engagement: {
          intent: 'Ordering',
        },
        sandBox: v220,
      },
    },
  });
};
export const dataTrackwithDetail = (type, name, detailName) => {
  const getStoreData = store.getState();
  const { loopQualApp, fiosDetails } = getStoreData;
  const flowName = getUtilFlowName(getStoreData, fiosDetails);
  let subFlowName = '';
  let throttleValue = 'nsa version|vhi home|geo|mhnewsignin|suppresslqa';
  if (loopQualApp && loopQualApp.isLoggedIn) {
    throttleValue = loopQualApp.isBulkQual
      ? 'nsa version|vhi home|passive-lq|geo|mhnewsignin|suppresslqa'
      : 'nsa version|vhi home|bau-lq|geo|mhnewsignin|suppresslqa';
  }
  if (loopQualApp.isComboMFJT) {
    throttleValue += '|mhjt y';
    subFlowName = 'mhjt';
  }
  const channelId = getChannelName(getStoreData);

  const v220 = getCookie('cdlThrottleList') || window.sessionStorage.getItem('LqThrottle') ? window.sessionStorage.getItem('LqThrottle') : '';
  return JSON.stringify({
    type,
    name,
    data: {
      page: {
        detail: detailName,
        flow: flowName,
        throttle: throttleValue,
        channel: 'Ordering',
        subCategory: getSubCategory(renderSetting, getStoreData),
        subFlow: subFlowName,
        displayChannel: channelId,
        sourceChannel: channelId,
      },
      target: {
        engagement: {
          intent: 'Ordering',
        },
        sandBox: v220,
      },
    },
  });
};
