import { AddressLookUp as Action } from '../../constants/actionTypes';
import { getStreetAddress } from '../../services/lqService';
import { catchJSException, logMetrics } from '../../services/logService';
import { CommonLQ } from '../../constants/common';
import { apiUrl } from '../../constants/api-urls';
import callingApi from '../../services/apiService';
import { checkNetworkAvailability } from '../address-qualification/actions';
import { setAddressDetailForGeo } from '../bulk-lq/action';
import { updateAutoGeo } from '../loop-qual-app/actions';
import { sendDataPassiveLqa } from '../../utils/unifiedVzTag';

export const resetStreetSelection = () => ({ type: Action.RESET_STREET_SELECTION });

export const onStreetSearch = (userAddressInput, dispatch, autogeo) => {
  try {
    return new Promise((resolve, reject) => {
      let showLQBtn = !!(userAddressInput.length > 7);
      if (showLQBtn && userAddressInput.charAt(0) !== ' ' && userAddressInput.charAt(0) !== ',') {
        showLQBtn = true;
      } else {
        showLQBtn = false;
      }
      showLQCta(dispatch, showLQBtn);
      getStreetAddress(userAddressInput, dispatch)
        .then((response) => {
          logMetrics('onStreetSearch', 'ONSTREETSEARCHSUCCESS', 'Info');
          let formattedResponse = response.slice(0, 10);
          const fullResponse = response;
          if (response && response.length) {
            formattedResponse = formattedResponse.map((a) => {
              if (a && a.disprawstr) {
                a.disprawstr = a.disprawstr
                  .toLowerCase()
                  .replace(/\b\w/g, (l) => l.toUpperCase())
                  .replace(
                    /\s\b(([Aa][EeLlKkSsZzRr])|([Cc][AaOoTt])|([Dd][EeCc])|([Ff][MmLl])|([Gg][AaUu])|([Hh][Ii])|([Ii][DdLlNnAa])|([Kk][SsYy])|([Ll][Aa])|([Mm][EeHhDdAaIiNnSsOoTt])|([Nn][EeVvHhJjMmYyCcDd])|([Mm][Pp])|([Oo][HhKkRr])|([Pp][WwAaRr])|([Rr][Ii])|([Ss][CcDd])|([Tt][NnXx])|([Uu][Tt])|([Vv][TtIiAa])|([Ww][AaVvIiYy]))\,/,
                    (l) => l.toUpperCase(),
                  )
                  .replace(/\sUsa/, (l) => l.toUpperCase());
              }
              return a;
            });
          }
          resolve(formattedResponse);
          const showMore = {
            disprawstr: 'Show more results',
            dispstr: 'Show more results',
          };
          const showLess = {
            disprawstr: 'Show less results',
            dispstr: 'Show less results',
          };
          if (response && response.length > 10) {
            fullResponse.push(showLess);
            formattedResponse.push(showMore);
          }
          fullSuggestion(dispatch, fullResponse);
          halfSuggestion(dispatch, formattedResponse);

          addressCollectionUpdate(dispatch, formattedResponse);
          setTimeout(() => {
            if (autogeo) {
              const streetInput = document.querySelector('#streetaddress');
              if (streetInput) {
                streetInput.focus();
              }
              document.querySelector('a.item-highlighted').click();
            }
          }, 200);
        })
        .catch((error) => {
          reject(error);
          if (!(error.message === 'old Request Cancelled')) {
            logMetrics('onStreetSearch', error.message, 'Error');
          }
          dispatch(resetAddressCollection());
        });
    });
  } catch (error) {
    catchJSException('LOOPQUAL2.0', 'onStreetSearch', error);
    logMetrics('onStreetSearch', error.message, 'Error');
  }
};
export const showFullResponse = (dispatch, response) => {
  addressCollectionUpdate(dispatch, response);
};

export const onStreetSelection = (streetDetails) => ({ type: Action.UPDATE_STREET_DETAILS, value: streetDetails });

export const setRevisitAddress = (address) => ({ type: Action.REVISIT_ADDRESS, value: address });
export const setRevisitScenario = (revisit) => ({ type: Action.REVISIT_SCENARIO, value: revisit });

export const updateStreetInput = (input) => ({ type: Action.UPDATE_STREET_INPUT, value: input });
export const streetEmptyError = (input) => ({ type: Action.STREET_EMPTY_ERROR, value: input });
export const setStreetError = (input) => ({ type: Action.SET_STREET_ERROR, value: input });
export const setStreetGeoError = (input) => ({ type: Action.SET_STREET_GEO_ERROR, value: input });
export const sameAddressError = (input) => ({ type: Action.SAME_ADDRESS_ERROR, value: input });
export const invalidAddressError = (input) => ({ type: Action.INVALID_ADDRESS_ERROR, value: input });
export const resetAddressCollection = () => ({ type: Action.UPDATE_ADDRESS_COLLECTION, value: [] });

export const restoreDefaults = () => ({ type: Action.RESTORE_DEFAULTS });
export const autoLocationPopulate = (unitLookupDetails, addressQualification, loopQualApp, dispatch, cookies, fiosDetails, moveDetails) => {
  if (navigator.geolocation) {
    navigator.geolocation.getCurrentPosition(success);
  }
  function success(position) {
    // speechonChange(`  `);
    dispatch({ type: 'UPDATE_ADDRESS_COLLECTION_RESET', value: [] });
    const { latitude } = position.coords;
    const { longitude } = position.coords;

    console.log(`Latitude: ${latitude}, Longitude: ${longitude}`);
    invokeWirelessLocusApi(latitude, longitude);
    // callHereApi(latitude, longitude);
  }
  function invokeWirelessLocusApi(latitude, longitude) {
    const url = apiUrl().getStreetWithGeo;
    const data = {
      longitude,
      latitude,
      includeXrefs: true,
    };
    const location =
      window.location.search &&
      (window.location.search.indexOf('testQualification') > -1 ||
        window.location.search.indexOf('nsa=y') > -1 ||
        window.location.search.indexOf('NSA=Y') > -1)
        ? 'Y'
        : null;
    let flowName;
    if (location && location === 'Y') {
      flowName = 'test|';
    }
    const requiredflowType = { flowType: flowName };
    return new Promise((resolve) => {
      callingApi(url, 'POST', data, 'application/json', requiredflowType)
        .then((response) => {
          const formattedAddress = response?.data?.addressInfo?.[0]?.standardizedAddress;
          if (
            formattedAddress &&
            formattedAddress.addressLine1 &&
            formattedAddress.city &&
            formattedAddress.stateProvince &&
            formattedAddress.postalCodeBase &&
            formattedAddress.iso3CountryCode &&
            formattedAddress.iso3CountryCode === 'USA'
          ) {
            dispatch(updateAutoGeo(true));
            const addressClassification = response?.data?.addressInfo?.[0]?.addressClassification;
            dispatch(updateStreetInput(formattedAddress.formattedAddress));
            const addrInfo = setAddressDetailForGeo(dispatch, formattedAddress);
            if (addressClassification === 'SFU' || addressClassification === 'SBU') {
              sendDataPassiveLqa('single', 'lqgeo');
              logMetrics('autogeoqual', 'Calling checkNetworkAvailability for SFU SBU', 'Info');
              checkNetworkAvailability(
                addrInfo,
                unitLookupDetails,
                addressQualification,
                loopQualApp,
                dispatch,
                cookies,
                '',
                fiosDetails,
                moveDetails,
                null,
                true,
                false,
                true,
              );
            }
            logMetrics('GeoLocator', 'called from locus', 'Info');
          }
          resolve(response);
        })
        .catch((error1) => {
          console.log(error1);
          resolve(error1);
        });
    });
  }
};

export const showLQCta = (dispatch, input) => {
  dispatch({ type: Action.SHOW_LQCTA, value: input });
};
export const updateSuggestedAddr = (input, dispatch) => {
  dispatch({ type: Action.SET_PARSED_ADDRESS, value: input });
};
const addressCollectionUpdate = (dispatch, response) => {
  resetAddressCollectionUpdate(dispatch).then(() => {
    dispatch({ type: Action.UPDATE_ADDRESS_COLLECTION, value: response });
  });
};
const resetAddressCollectionUpdate = (dispatch) =>
  new Promise((resolve) => {
    dispatch({ type: Action.UPDATE_ADDRESS_COLLECTION_RESET, value: [] });
    resolve();
  });

export const fullSuggestion = (dispatch, response) => {
  resetAddressCollectionUpdate(dispatch).then(() => {
    dispatch({ type: Action.FULL_SUGGESTION, value: response });
  });
};
export const halfSuggestion = (dispatch, response) => {
  resetAddressCollectionUpdate(dispatch).then(() => {
    dispatch({ type: Action.HALF_SUGGESTION, value: response });
  });
};
export const getSHA256Val = async (dispatch, item, source) => {
  let hashHex = '';
  const locusID = source === 'street' ? item.locusID + CommonLQ.shaKey : item.ntasAddrID + CommonLQ.shaKey;
  try {
    const msgUint8 = new TextEncoder().encode(locusID);
    const hashBuffer = await crypto.subtle.digest('SHA-256', msgUint8);
    const hashArray = Array.from(new Uint8Array(hashBuffer));
    hashHex = hashArray.map((b) => b.toString(16).padStart(2, '0')).join('');
    if (source === 'street') {
      item.hashedLocusId = hashHex;
      dispatch(onStreetSelection(item));
    } else if (source === 'ntas') {
      item.hashedNtasAddrID = hashHex;
      dispatch(onStreetSelection(item));
    }
  } catch (error) {
    console.error('getSHA-256 failed', error);
  }
};
