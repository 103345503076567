/* eslint-disable react/prefer-stateless-function */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react';
import PropTypes from 'prop-types';

import Link from './Link';
import { dataTrack } from '../../utils/vzdl';

class AddressEditable extends React.Component {
  render() {
    const { streetAddress, addressUnit, notify, stickyLq } = this.props;
    console.log('notify, stickyLq', notify, stickyLq, streetAddress);
    const mhClass = this.props.loopQualApp?.sourceInfo === 'ResponsiveLQ' ? 'mhmobile' : '';
    return (
      <div id="address-edit-wrapper" className={mhClass}>
        <div id="ae-street-wrapper" data-cs-mask>
          {streetAddress}
          {addressUnit}
        </div>
        <div id="ae-edit-wrapper">
          {this.props.loopQualApp.sourceInfo !== 'digitalCombo' && (
            <Link
              id={this.props.intputId}
              ariaLabel={`${streetAddress?.props?.children} Change`}
              onClick={(e) => this.props.onEditClick(e)}
              onKeyPress={(e) => this.props.onEditKeyDown(e)}
              linkText="Change"
              style={{ marginBottom: '6px', color: 'white ! important' }}
              data-track={dataTrack('link', 'edit address - lq widget')}
            />
          )}
        </div>
      </div>
    );
  }
}

export default AddressEditable;
AddressEditable.propTypes = {
  intputId: PropTypes.string,
  streetAddress: PropTypes.string,
  addressUnit: PropTypes.string,
  onEditClick: PropTypes.string,
  onEditKeyDown: PropTypes.string,
  notify: PropTypes.object,
  loopQualApp: PropTypes.string,
  stickyLq: PropTypes.bool,
};
