/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable jsx-a11y/label-has-for */
import React from 'react';
import styled from 'styled-components';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import * as unitLookupActions from './actions.js';
import AutoComplete from '../common/AutoComplete';
import { dataTrack, dataTrackwithDetail } from '../../utils/vzdl';
import { showLoader, hideLoader } from '../common/loader/actions';
import { sendDataVzTagMessage } from '../../utils/unifiedVzTag';

const InputSection = styled.div`
  input {
    font-weight: normal;
  }
`;
const Margin = styled.div`
  margin-top: ${(props) => (props.mvInvAdr ? '15px' : '0')};
`;
let shiftTab = false;

class UnitLookup extends React.PureComponent {
  constructor(props, context) {
    super(props, context);
    this.state = {
      isLoader: false,
    };
  }

  componentDidMount() {
    document.addEventListener('keydown', this.onBlurKeyDown);
    const { customerStreetDetails } = this.props;
    const streetId = customerStreetDetails && (customerStreetDetails.ntasAddrID || customerStreetDetails.locusID);
    if (customerStreetDetails && streetId !== this.props.unitLookup.fetchedUnitsStreetId) {
      this.fetchUnits();
    }
  }

  componentDidUpdate(prevProps) {
    if (this.props.customerStreetDetails) {
      if (!prevProps.customerStreetDetails || prevProps.customerStreetDetails.locusID !== this.props.customerStreetDetails.locusID) {
        this.fetchUnits();
      }
    }
  }

  componentWillUnmount() {
    document.removeEventListener('keydown', this.onBlurKeyDown);
  }

  onBlurKeyDown = (e) => {
    const body = document.querySelector('body');
    if (!body.classList.contains('hide-focus-outline')) {
      if (e.shiftKey && e.keyCode === 9) {
        shiftTab = true;
      } else if (e.keyCode === 9) {
        shiftTab = false;
      } else {
        console.log(e.keyCode, e);
      }
    }
  };

  onUnitBlur = async () => {
    if ((this.props.unitLookup.unitInput === '' || !this.props.unitLookup.unitSelected) && this.props.unitLookup.isUnitRequired) {
      this.props.dispatch(unitLookupActions.setUnitInputError(true));
      sendDataVzTagMessage('Please select an unit');
      this.props.dispatch(unitLookupActions.setUnitInputSuccess(false));
    }
    this.accessbilityControl();
  };

  onUnitFocus = async () => {
    this.props.dispatch(unitLookupActions.setUnitInputError(false));
    this.accessbilityControl();
  };

  onUnitSelect = async (value, item) => {
    this.props.dispatch(unitLookupActions.updateUserInput(value));
    this.props.dispatch(unitLookupActions.unitSelected(true));
    this.props.dispatch(unitLookupActions.setUnitInputError(false));
    if (item && item.location_id) {
      unitLookupActions.getSHA256Val(this.props.dispatch, item);
    } else {
      this.props.dispatch(unitLookupActions.onUnitSelection(item));
    }
    const mtnMobile = document.querySelector('#Mobile-phone-number');
    if (this.props.loopQualApp && !this.props.loopQualApp.lq4 && mtnMobile) {
      mtnMobile.focus();
    }
  };

  onUnitChange = (event) => {
    if (this.props.unitLookup.unitInput.length > 1 && this.props.unitLookup.unitInput.length <= 3) {
      console.log('suppressing 15Gifts bubble - unitInput');
      if (window.__15gifts && window.__15gifts.leap) {
        window.__15gifts.leap.suppressEngagementBubble();
        console.log('15Gifts suppressEngagementBubble');
      }
    }
    this.props.dispatch(unitLookupActions.updateUserInput(event.target.value));
    this.props.dispatch(unitLookupActions.unitSelected(false));
    this.props.dispatch(unitLookupActions.restUnitDetails());
    this.setState({ isLoader: true });
    unitLookupActions
      .loadUnits(this.props.customerStreetDetails, this.props.dispatch, this.props.loopQualApp, event.target.value)
      .then((unitList) => {
        const unitValue = event.target.value?.toLowerCase();
        if (unitList?.length === 1 && this.props.loopQualApp.isMdu) {
          const singleUnit = unitList[0];
          if (singleUnit?.ntasSublocation?.toLowerCase() === unitValue) {
            this.onUnitSelect(singleUnit?.ntasSublocation, singleUnit);
          } else if (singleUnit?.sublocation?.toLowerCase() === unitValue) {
            this.onUnitSelect(singleUnit?.sublocation, singleUnit);
          }
        }
        this.setState({ isLoader: false });
      })
      .catch(() => {
        this.setState({ isLoader: false });
      });
  };

  accessbilityControl = () => {
    const body = document.querySelector('body');
    if (!body.classList.contains('hide-focus-outline')) {
      const mobileDivWrapper = document.querySelector('#Mobile-phone-number');
      const floorInput = document.querySelector('#selectFloor');
      const mobileInput = mobileDivWrapper && mobileDivWrapper.querySelector('input') ? mobileDivWrapper.querySelector('input') : mobileDivWrapper;
      const streetInput = document.querySelector('#streetaddress');
      const editAddress = document.getElementById('editaddress');
      const unitError = document.getElementById('uniterror');
      const unitField = document.getElementById('apartmentNumber');
      if (unitError && unitField && shiftTab) {
        unitField.focus();
      } else if (streetInput && shiftTab) {
        streetInput.focus();
      }
      if (editAddress && shiftTab) {
        editAddress.focus();
      }
      if (this.props.loopQualApp && !this.props.loopQualApp.lq4 && mobileInput && !shiftTab) {
        mobileInput.focus();
      }
      if (floorInput && !shiftTab) {
        floorInput.focus();
      }
    }
  };

  fetchUnits() {
    unitLookupActions.restoreUnitDefaultsStore(this.props.dispatch);
    // this.props.dispatch(unitLookupActions.updateUnitAutocompleteList([]));
    // this.props.dispatch(unitLookupActions.restUnitDetails());
    if (this.props?.customerStreetDetails?.addressClassification !== 'SFU' && this.props?.customerStreetDetails?.addressClassification !== 'SBU') {
      this.props.dispatch(showLoader());
      unitLookupActions
        .loadUnits(this.props.customerStreetDetails, this.props.dispatch, this.props.loopQualApp)
        .then(() => {
          this.props.dispatch(hideLoader());
        })
        .catch(() => {
          this.props.dispatch(hideLoader());
        });
    }
  }

  render() {
    let { unitsAutocompleteList } = this.props;
    const unitdataTracker = 'apt selected';
    const id = 'apartmentNumber';
    if (!this.props.unitLookup.units || !this.props.unitLookup.units.length) {
      return null;
    }
    let enableDropDown = true;
    const unitInputProps = {
      className: `${
        this.props.loopQualApp && this.props.loopQualApp.lq4 && this.props.unitLookup.unitInputError
          ? 'autocompleteInput apartmentNumber autocompleteInputError'
          : 'autocompleteInput apartmentNumber'
      } ${this.props.colors?.surface || ''}`,
      name: 'lqw-unit-num',
      placeholder: this.props.loopQualApp && this.props.loopQualApp.lq4 ? '' : 'Select Unit #',
    };
    if (!this.props.unitLookup.units || !this.props.unitLookup.units.length) {
      enableDropDown = false;
    }
    if (enableDropDown) {
      unitInputProps.className += ' input-as-text';
    }
    const itemval = 'ntasSublocation';
    const itemkey = ['location_id'];
    if (
      unitsAutocompleteList &&
      (unitsAutocompleteList.length || (this.props.unitLookup.unitInput && unitsAutocompleteList && unitsAutocompleteList.length === 0))
    ) {
      unitsAutocompleteList = unitsAutocompleteList.concat(unitLookupActions.getUnitsDropdownDefaults(itemval));
    }
    return (
      <InputSection className="loop-qualification address-input-height">
        <Margin mvInvAdr={this.props.mvInvAdr} />
        <div className="restricted-width-reskin" data-cs-mask>
          <>
            {this.props.fromSticky ? (
              <label aria-label="appartment-unit" htmlFor="apartmentNumber" color={this.props?.colors?.color || 'white'} id="appartment-unit">
                Enter Apartment/Unit
              </label>
            ) : (
              <label
                aria-label="appartment-unit"
                htmlFor="apartmentNumber"
                style={{
                  color:
                    this.props.loopQualApp.isVFEmailNc || this.props.loopQualApp.isVfEc || this.props.loopQualApp.TWS
                      ? 'black'
                      : this.props?.colors?.color || 'white',
                }}
              >
                Apartment/Unit number
              </label>
            )}
            <AutoComplete
              value={this.props.unitLookup.unitInput}
              items={unitsAutocompleteList}
              onSelect={this.onUnitSelect}
              onChange={this.onUnitChange}
              inputProps={{
                itemval,
                itemkey,
                id,
                ...unitInputProps,
                'aria-labelledby': 'selectedOption',
                'aria-controls': 'Unitoptions',
                'aria-label': 'Apartment/Unit Number',
                onBlur: this.onUnitBlur,
                onFocus: this.onUnitFocus,
                datatracker:
                  this.props.loopQualApp && this.props.loopQualApp.lq4
                    ? dataTrackwithDetail('link', unitdataTracker, 'lq sticky bar')
                    : dataTrack('link', unitdataTracker),
              }}
              showLoader={this.state.isLoader}
              success={!!(this.props.unitLookup.customerUnitDetails && this.props.unitLookup.customerUnitDetails.ntasSublocation)}
              unitLookupError={this.props.unitLookup.unitInputError}
              loopQualApp={this.props.loopQualApp}
            />
            {this.props.unitLookup.unitInputError && this.props.unitLookup.unitInput === '' && (
              <div style={{ marginTop: '0px' }} tabIndex="0" aria-label=" This is a mandatory field." id="uniterror">
                <label color={this.props?.colors?.color || '#FFFFFF'} id="appartment-unit-errorText">
                  This is a mandatory field.
                </label>
              </div>
            )}
            {this.props.unitLookup.unitInputError && this.props.unitLookup.unitInput && !this.props.unitLookup.unitSelected && (
              <div style={{ marginTop: '0px' }} tabIndex="0" aria-label="This is a mandatory field." id="uniterror">
                <label color={this.props?.colors?.color || '#FFFFFF'} id="appartment-unit-errorText">
                  This is a mandatory field.
                </label>
              </div>
            )}
          </>
        </div>
      </InputSection>
    );
  }
}
const mapStateToProps = (state) => ({
  customerStreetDetails: state.addressLookup.customerStreetDetails,
  unitLookup: state.unitLookup,
  unitsAutocompleteList: state.unitLookup.unitsAutocompleteList,
  loopQualApp: state.loopQualApp,
  notify: state.notify,
});

export default connect(mapStateToProps)(UnitLookup);

UnitLookup.propTypes = {
  dispatch: PropTypes.string,
  unitLookup: PropTypes.string,
  loopQualApp: PropTypes.string,
  unitsAutocompleteList: PropTypes.string,
  customerStreetDetails: PropTypes.object,
  fromSticky: PropTypes.bool,
  colors: PropTypes.any,
  mvInvAdr: PropTypes.bool,
};
