import stringSimilarity from 'string-similarity';
import { AddressQualification as ActionTypes, LoopQualification as LQActionTypes } from '../../constants/actionTypes';
import { callingLQService, fetchAuthDetails, updatePdm } from '../../services/lqService';
import {
  updateCurrentView,
  updateParserAttempt,
  clearDetails,
  setCheckAvailBtnTrack,
  updatePlansPage,
  updateIsBulkQual,
  updateSkeleton,
} from '../loop-qual-app/actions';
import { showLoader, hideLoader } from '../common/loader/actions';
import { logMetrics } from '../../services/logService';
import { setToStorage, invokeHereMapScript, redirectToPlansPage, redirectToFiosPlansPage, redirectToMlp } from '../../utils/lqServiceUtil';
import { orderNowFiveGNsa, invokeUCAbandonedCart, fetchEmeriosData, invokeUpdateCartNoRedirect } from '../order-fiveg-home/actions';
import { updateUserInput, setUnitInputError } from '../units-lookup/actions';
import {
  setStreetError,
  sameAddressError,
  invalidAddressError,
  setStreetGeoError,
  onStreetSearch,
  updateStreetInput,
} from '../address-lookup/actions';
import { Flowtype, LQ_VIEWS, Pages } from '../../constants/common';
import { setDirectMove, setdisConnectMove } from '../move-lq/action';
import { transform, FIVG_LQ_RESPONSE } from '../../utils/lq-response-tranformer';
import {
  sendDataVzTagLqs,
  sendDataVzTagErrorMessage,
  openViewVzTag,
  loopQualVzTaglqa,
  sendDataPassiveLqa,
  sendDataPassiveLqs,
  loopQualVzTaglqs,
} from '../../utils/unifiedVzTag';
import { openModalWindow, closeModalWindow } from '../common/modal/action';
import { getRenderSettings } from '../../config';

const plansvisit = window?.vzwlqwidgetconfig?.sourceInfo === 'plansvisit';

export const checkNetworkAvailability = (
  addressInfo,
  unitInfo,
  addressQualification,
  loopQualApp,
  dispatch,
  cookies,
  streetInput,
  fiosDetails,
  moveDetails,
  bulkQualDetails = null,
  suppressOnPageLoadTag = false,
  fromOnClick = false,
  autoGeoQual = false,
  notQualifiedFromPlansVisit,
) => {
  try {
    dispatch(showLoader());
    if (fromOnClick && loopQualApp?.isLoggedIn) {
      dispatch(updateIsBulkQual(false));
    }
    if (loopQualApp.lq4 && !suppressOnPageLoadTag) {
      openViewVzTag('lq loader');
    }
    if (window.vzlqw) {
      window.vzlqw.fromOnClick = fromOnClick;
    }
    callingLQService(addressInfo, unitInfo, addressQualification, loopQualApp, cookies, streetInput, fiosDetails, moveDetails, bulkQualDetails)
      .then((response) => {
        if (notQualifiedFromPlansVisit && response) {
          invokeUpdateCartNoRedirect(response, loopQualApp, dispatch);
          return true;
        }
        if (autoGeoQual && response && (response.targetView === LQ_VIEWS.UN_QUALIFIED_VIEW || response.targetView === LQ_VIEWS.LBO_VIEW)) {
          clearDetails(dispatch, '', fiosDetails, loopQualApp, autoGeoQual);
          sendDataPassiveLqs('notEligible', 'lqgeo');
          setTimeout(() => {
            dispatch(updateStreetInput(response.lqAddress ? response.lqAddress : ''));
            onStreetSearch(response.lqAddress ? response.lqAddress : '', dispatch);
            const streetInputElm = document.querySelector('#streetaddress');
            if (streetInputElm) {
              streetInputElm.focus();
            }
          });
          logMetrics('autogeoqual', 'Cleared all Redux due to LBO or UnQualifed Scenario', 'Info');
          return true;
        }
        const isTreatThrottle =
          fromOnClick &&
          !loopQualApp.isLoggedIn &&
          !loopQualApp.TWS &&
          !loopQualApp.isFccFlow &&
          !loopQualApp.isACPFlow &&
          !response.uberPinEligible &&
          loopQualApp.flowType !== Flowtype.storeLocater &&
          loopQualApp.flowType !== Flowtype.MOVERS &&
          loopQualApp.flowType !== Flowtype.retail &&
          !loopQualApp.isComboMFJT &&
          !loopQualApp.isECComboMFJT &&
          !loopQualApp.newConnection &&
          (response.targetView === LQ_VIEWS.FIVEG_HOME_QUALIFIED_VIEW || response.targetView === LQ_VIEWS.LTE_HOME_QUALIFIED_VIEW);
        if (!isTreatThrottle) {
          dispatch(hideLoader());
        }
        if (
          plansvisit &&
          (response.targetView === LQ_VIEWS.UN_QUALIFIED_VIEW ||
            response.targetView === LQ_VIEWS.LBO_VIEW ||
            (response.fiosQualified &&
              response.fiosQualification &&
              (response.fiosQualification?.inService === 'Y' ||
                response.fiosQualification?.pendingOrder === 'Y' ||
                response?.fiosQualification?.smartCartDetails?.smartCartAvailable)))
        ) {
          dispatch(showLoader());
          setTimeout(() => {
            dispatch(hideLoader());
            redirectToMlp(response?.fiveGQualified, response?.qualifiedCBand, response?.lteQualified, response?.fiosQualified);
          }, 5000);
        }
        if (loopQualApp.runTimeKey && loopQualApp.runTimeKey.EnableCaptcha && loopQualApp.runTimeKey.EnableCaptcha === 'N') {
          if (response) {
            dispatch({ type: ActionTypes.QUALIFICATION_DETAILS, value: response });
          } else {
            dispatch({ type: ActionTypes.RESET_QUALIFICATION_DETAILS_LQRESULT });
          }
        } else if (response === 'CAPTCHA_FAILED') {
          if (loopQualApp.captchaDetails && loopQualApp.captchaDetails.reCaptchaEnabled === 'N') {
            const recaptchaDetails = {};
            recaptchaDetails.reCaptchaEnabled = 'Y';
            recaptchaDetails.reCaptchaVerified = loopQualApp.captchaDetails.reCaptchaVerified;
            recaptchaDetails.reCaptchaJS = loopQualApp.captchaDetails.reCaptchaJS;
            recaptchaDetails.reCaptchaSiteKey = loopQualApp.captchaDetails.reCaptchaSiteKey;
            dispatch({ type: LQActionTypes.UPDATE_RECAPTCHA_DETAILS, value: recaptchaDetails });
          }
        } else if (response) {
          dispatch({ type: ActionTypes.QUALIFICATION_DETAILS, value: response });
        } else {
          dispatch({ type: ActionTypes.QUALIFICATION_DETAILS, value: response.output });
        }
        if (response) {
          if (isTreatThrottle) {
            updatePlanpageViaThrottle(response, dispatch, loopQualApp);
          } else {
            dispatch(updateCurrentView(response.targetView));
          }
          const params = new URLSearchParams(window.location.search);
          const locusFetch = params.get('locusid') ? params.get('locusid') : null;
          if (locusFetch) {
            if (response.targetView === LQ_VIEWS.UN_QUALIFIED_VIEW) {
              openModalWindow('LQAPP', dispatch);
              sendDataPassiveLqa('single', 'locusFlow');
            } else if (response?.fiosQualified || response?.fiveGQualified || response?.qualifiedCBand || response?.lteQualified) {
              sendDataPassiveLqa('single', 'locusFlow');
              sendDataPassiveLqs('success', 'locusFlow');
            }
          } else if (autoGeoQual) {
            sendDataPassiveLqs('success', 'lqgeo');
          }
          if (!loopQualApp.isFccFlow) {
            dispatchLearnCta(response, dispatch);
          }
          postQualificationUpdate(response, loopQualApp, addressQualification, moveDetails, dispatch, cookies, fiosDetails, fromOnClick);
        }
        if (response && loopQualApp && loopQualApp.isFccFlow && !suppressOnPageLoadTag) {
          greyRemove();
          if (response.targetView === 'UN_QUALIFIED_VIEW' || response.uberPinEligible) {
            openModalWindow('LQAPP', dispatch);
          } else if (response.fiosQualified) {
            // sendDataPassiveLqs('success', 'fcc');
            let redirectUrl = '';
            if (window.location.host.includes('.verizonwireless.com')) {
              redirectUrl = `https://www98.verizon.com${Pages.inHomeFccBroadbandLabels}?visitor_id=${addressQualification.lqResult.visitor_id}&visit_id=${addressQualification.lqResult.visit_id}`;
            } else {
              redirectUrl = Pages.inHomeFccBroadbandLabels;
            }
            redirectToPlansPage(redirectUrl);
          } else if (!response.uberPinEligible && (response.fiveGQualified || response.qualifiedCBand || response.lteQualified)) {
            openModalWindow('LQAPP', dispatch);
            // sendDataPassiveLqs('success', 'fcc');
            dispatch(updateCurrentView(LQ_VIEWS.BLANK_LOADER_VIEW));
            dispatch(showLoader());
            return new Promise((resolve) => {
              invokeUpdateCartNoRedirect(response, loopQualApp, dispatch)
                .then((fccresponse) => {
                  if (fccresponse && fccresponse.data && fccresponse.data.serviceBody && fccresponse.data.serviceBody.serviceResponse) {
                    logMetrics('invokeUpdateCartNoRedirect', JSON.stringify(response), 'Info');
                    dispatch(hideLoader());
                    redirectToPlansPage(Pages.fiveGNSAExpressCartFcc);
                    resolve(fccresponse);
                  } else {
                    dispatch(updateCurrentView(LQ_VIEWS.LBO_VIEW));
                    logMetrics('invokeUpdateCartNoRedirectError', JSON.stringify(response), 'Error');
                    dispatch(hideLoader());
                  }
                })
                .catch((error) => {
                  logMetrics('invokeUpdateCartNoRedirect', error.message, 'Error');
                  dispatch(hideLoader());
                });
            });
          }
        }
        dispatch(updateSkeleton(false));
      })
      .catch((error) => {
        dispatch(hideLoader());
        if (fromOnClick) {
          logMetrics('lbo-qualificationerror', error.message, 'Error');
          openViewVzTag('lbo-qualificationerror');
          dispatch(updateCurrentView(LQ_VIEWS.LBO_VIEW));
        }
        logMetrics('checkNetworkAvailability', error.message, 'Error');
        dispatch(updateSkeleton(false));
      });
  } catch (error) {
    dispatch(hideLoader());
    dispatch(updateSkeleton(false));
    if (fromOnClick) {
      logMetrics('lbo-qualificationerror', error.message, 'Error');
      openViewVzTag('lbo-qualificationerror');
      dispatch(updateCurrentView(LQ_VIEWS.LBO_VIEW));
    }
    logMetrics('checkNetworkAvailability', error.message, 'Error');
  }
};

export const checkNetworkAvailabilityReTrigger = (
  addressInfo,
  unitInfo,
  addressQualification,
  loopQualApp,
  dispatch,
  cookies,
  streetInput,
  fiosDetails,
  moveDetails,
  moversReTrigger = false,
) => {
  try {
    logMetrics('UpdartCartError', 'RetriggeringQualification', 'Info');
    dispatch(showLoader());
    callingLQService(addressInfo, unitInfo, addressQualification, loopQualApp, cookies, streetInput, fiosDetails, moveDetails)
      .then((response) => {
        dispatch(hideLoader());
        if (response) {
          dispatch({ type: ActionTypes.QUALIFICATION_DETAILS, value: response });
          dispatch(updateCurrentView(response.targetView));
          dispatchLearnCta(response, dispatch);
          if (response.fiveGQualified || response.lteQualified || response.qualifiedCBand) {
            dispatch(showLoader());
            setTimeout(() => {
              dispatch(hideLoader());
              logMetrics('UpdartCartError', 'RetriggeringUpdateCart', 'Info');
              orderNowFiveGNsa(
                addressQualification,
                loopQualApp,
                dispatch,
                false,
                moveDetails,
                false,
                addressInfo,
                unitInfo,
                fiosDetails,
                true,
                false,
                '',
                moversReTrigger,
              );
            }, 1000);
          }
          postQualificationUpdate(response, loopQualApp, addressQualification, moveDetails, dispatch);
        } else {
          dispatch({ type: ActionTypes.QUALIFICATION_DETAILS, value: response.output });
        }
      })
      .catch((error) => {
        dispatch(hideLoader());
        logMetrics('checkNetworkAvailabilityReTrigger', error.message, 'Error');
      });
  } catch (error) {
    dispatch(hideLoader());
    logMetrics('checkNetworkAvailabilityReTrigger', error.message, 'Error');
  }
};

export const checkNetworkAvailabilityForExternal = (
  addressInfo,
  unitInfo,
  addressQualification,
  loopQualApp,
  dispatch,
  cookies,
  streetInput,
  fiosDetails,
  moveDetails,
) => {
  try {
    logMetrics('checkNetworkAvailabilityForExternal', 'checkNetworkAvailabilityForExternal', 'Info');
    dispatch(showLoader());
    callingLQService(addressInfo, unitInfo, addressQualification, loopQualApp, cookies, streetInput, fiosDetails, moveDetails)
      .then((response) => {
        dispatch(hideLoader());
        if (response) {
          sendDataVzTagLqs('single', '', response);
          dispatch({ type: ActionTypes.QUALIFICATION_DETAILS, value: response });
          dispatch(updateCurrentView(response.targetView));
          dispatchLearnCta(response, dispatch);
          if (response.fiveGQualified && response.uberPinEligible) {
            openModalWindow('LQAPP', dispatch);
            dispatch(updateCurrentView(LQ_VIEWS.FIVEG_HOME_QUALIFIED_VIEW));
          } else if (response.fiveGQualified || response.lteQualified || response.qualifiedCBand) {
            openModalWindow('LQAPP', dispatch);
            dispatch(showLoader());
            setTimeout(() => {
              dispatch(hideLoader());
              logMetrics('UpdateCartExternal', 'UpdateCartExternal', 'Info');
              orderNowFiveGNsa(
                addressQualification,
                loopQualApp,
                dispatch,
                false,
                moveDetails,
                false,
                addressInfo,
                unitInfo,
                fiosDetails,
                true,
                false,
              );
            }, 1000);
          } else if (response.fiosQualified) {
            openModalWindow('LQAPP', dispatch);
            dispatch(updateCurrentView(LQ_VIEWS.FIOS_QUALIFIED_VIEW));
          } else {
            openModalWindow('LQAPP', dispatch);
            dispatch(updateCurrentView(LQ_VIEWS.UN_QUALIFIED_VIEW));
          }
          postQualificationUpdate(response, loopQualApp, addressQualification, moveDetails, dispatch);
        } else {
          dispatch({ type: ActionTypes.QUALIFICATION_DETAILS, value: response.output });
        }
      })
      .catch((error) => {
        dispatch(hideLoader());
        logMetrics('checkNetworkAvailabilityForExternal', error.message, 'Error');
      });
  } catch (error) {
    dispatch(hideLoader());
    logMetrics('checkNetworkAvailabilityForExternal', error.message, 'Error');
  }
};
export const checkNetworkAvailabilityForCombo = (
  addressInfo,
  unitInfo,
  addressQualification,
  loopQualApp,
  dispatch,
  cookies,
  streetInput,
  fiosDetails,
  moveDetails,
  sequentialFiosComboOrder = null,
  orderNumber = null,
  comboLocationCode = null,
  shellAcctNoForFios = null,
  emailId = null,
  mtn = null,
) => {
  try {
    logMetrics('UpdartCartError', 'RetriggeringQualification', 'Info');
    dispatch(showLoader());
    callingLQService(
      addressInfo,
      unitInfo,
      addressQualification,
      loopQualApp,
      cookies,
      streetInput,
      fiosDetails,
      moveDetails,
      null,
      sequentialFiosComboOrder,
      orderNumber,
      comboLocationCode,
      shellAcctNoForFios,
      emailId,
      mtn,
    )
      .then((response) => {
        openModalWindow('LQAPP', dispatch);
        dispatch(hideLoader());
        if (response) {
          dispatch({ type: ActionTypes.QUALIFICATION_DETAILS, value: response });
          dispatch(updateCurrentView(response.targetView));
          dispatchLearnCta(response, dispatch);
          if (response.fiveGQualified && response.uberPinEligible) {
            dispatch(updateCurrentView(LQ_VIEWS.FIVEG_HOME_QUALIFIED_VIEW));
          } else if (response.fiveGQualified || response.lteQualified || response.qualifiedCBand) {
            dispatch(showLoader());
            // sendDataVzTagLqs('single', '', response);
            setTimeout(() => {
              dispatch(hideLoader());
              logMetrics('UpdartCartError', 'RetriggeringUpdateCart', 'Info');
              // eslint-disable-next-line no-param-reassign
              addressQualification.lqResult = response;
              orderNowFiveGNsa(
                addressQualification,
                loopQualApp,
                dispatch,
                false,
                moveDetails,
                false,
                addressInfo,
                unitInfo,
                fiosDetails,
                true,
                false,
              );
            }, 1000);
          } else if (response?.fiosQualified && response?.fiosQualification?.pendingOrder === 'Y') {
            dispatch(updateCurrentView(LQ_VIEWS.LBO_VIEW));
          }
          postQualificationUpdate(response, loopQualApp, addressQualification, moveDetails, dispatch);
        } else {
          lqsTagTriggered(this.props.dispatch, false);
          dispatch({ type: ActionTypes.QUALIFICATION_DETAILS, value: response.output });
        }
      })
      .catch((error) => {
        dispatch(hideLoader());
        logMetrics('checkNetworkAvailabilityReTrigger', error.message, 'Error');
      });
  } catch (error) {
    dispatch(hideLoader());
    logMetrics('checkNetworkAvailabilityReTrigger', error.message, 'Error');
  }
};
export const checkNetworkAvailabilityForACP = (
  addressInfo,
  unitInfo,
  addressQualification,
  loopQualApp,
  dispatch,
  cookies,
  streetInput,
  fiosDetails,
  moveDetails,
  isFios,
  isFwa,
  customerTypeCaptured,
  languageType,
) => {
  try {
    const emreiosFetchBody = {
      registrationLanguage: languageType,
      businessLine: '',
      customerType: customerTypeCaptured,
      fiosSessionId: '',
    };
    let qualifiedService = '';
    let fiosVisitId = '';
    logMetrics('UpdartCartError', 'RetriggeringQualification', 'Info');
    dispatch(showLoader());
    callingLQService(addressInfo, unitInfo, addressQualification, loopQualApp, cookies, streetInput, fiosDetails, moveDetails)
      .then((response) => {
        dispatch(hideLoader());
        if (response) {
          if (response.fiveGQualified || response.lteQualified || response.qualifiedCBand) {
            qualifiedService = 'FWA';
          } else if (response.fiosQualified) {
            qualifiedService = 'FIOS';
            fiosVisitId = response.visit_id;
          }
          sendDataVzTagLqs('single', '', response);
          dispatch({ type: ActionTypes.QUALIFICATION_DETAILS, value: response });
          dispatch(updateCurrentView(response.targetView));
          dispatchLearnCta(response, dispatch);
          if (response.fiveGQualified && response.uberPinEligible) {
            openModalWindow('LQAPP', dispatch);
            dispatch(updateCurrentView(LQ_VIEWS.FIVEG_HOME_QUALIFIED_VIEW));
          } else if (response.fiveGQualified || response.lteQualified || response.qualifiedCBand) {
            dispatch(showLoader());
            setTimeout(() => {
              dispatch(hideLoader());
              logMetrics('UpdartCartError', 'RetriggeringUpdateCart', 'Info');
              orderNowFiveGNsa(
                addressQualification,
                loopQualApp,
                dispatch,
                false,
                moveDetails,
                false,
                addressInfo,
                unitInfo,
                fiosDetails,
                true,
                false,
              ).then(fetchEmeriosData(emreiosFetchBody, null, null, qualifiedService, fiosVisitId));
            }, 1000);
          } else {
            setTimeout(() => {
              fetchEmeriosData(emreiosFetchBody, null, null, qualifiedService, fiosVisitId);
            }, 1000);
          }
          postQualificationUpdate(response, loopQualApp, addressQualification, moveDetails, dispatch);
        } else {
          dispatch({ type: ActionTypes.QUALIFICATION_DETAILS, value: response.output });
        }
      })
      .catch((error) => {
        dispatch(hideLoader());
        logMetrics('checkNetworkAvailabilityReTrigger', error.message, 'Error');
      });
  } catch (error) {
    dispatch(hideLoader());
    logMetrics('checkNetworkAvailabilityReTrigger', error.message, 'Error');
  }
};
export const checkNetworkAvailabilityReInvoke = (
  addressInfo,
  unitInfo,
  addressQualification,
  loopQualApp,
  dispatch,
  cookies,
  streetInput,
  fiosDetails,
  moveDetails,
) => {
  try {
    logMetrics('QualReinvoke', 'ReinvokingQualification', 'Info');
    setToStorage('isReinvoke', 'Y');
    dispatch(showLoader());
    callingLQService(addressInfo, unitInfo, addressQualification, loopQualApp, cookies, streetInput, fiosDetails, moveDetails)
      .then((response) => {
        dispatch(hideLoader());
        if (response) {
          dispatch({ type: ActionTypes.QUALIFICATION_DETAILS, value: response });
          dispatch(updateCurrentView(response.targetView));
          dispatchLearnCta(response, dispatch);
          postQualificationUpdate(response, loopQualApp, addressQualification, moveDetails, dispatch);
        } else {
          dispatch({ type: ActionTypes.QUALIFICATION_DETAILS, value: response.output });
        }
      })
      .catch((error) => {
        dispatch(hideLoader());
        logMetrics('checkNetworkAvailabilityReInvoke', error.message, 'Error');
      });
  } catch (error) {
    dispatch(hideLoader());
    logMetrics('checkNetworkAvailabilityReInvoke', error.message, 'Error');
  }
};
export function greyOut() {
  try {
    const path = window.location.pathname;
    if (path.includes('/sales/home/progressiveplan') || path.includes('/sales/home/popularplans')) {
      if (plansvisit) {
        const close = document.getElementById('prompt_close_button');
        if (close) {
          close.click();
        }
      }
    }
    const main = document.getElementsByClassName('sticky')[0];
    if (main && main.classList) main.classList.add('stickyfocus');
  } catch (ex) {
    console.log(`greyOut${ex}`);
  }
}
export function greyRemove(fromScrollOrOutsideClk) {
  try {
    if (fromScrollOrOutsideClk && plansvisit) {
      const closeButton = window.document.getElementById('closeWidget');
      if (closeButton) {
        closeButton.click();
      }
    }
    const main = document.getElementsByClassName('sticky')[0];
    if (main && main.classList) main.classList.remove('stickyfocus');
  } catch (ex) {
    console.log(`greyRemove${ex}`);
  }
}
export const inVokeLqCheckNetworkAvailability = (
  e,
  customerStreetDetails,
  unitLookupDetails,
  addressQualification,
  loopQualApp,
  dispatch,
  cookies,
  streetInput,
  fiosDetails,
  moveDetails,
  bulkQualDetails,
  parserAttempt,
  fromOnClick,
) => {
  const renderSetting = getRenderSettings();
  let unitRequired = false;
  let streetError = false;
  let fromStickyBar = false;
  let externalTriggerNonSticky = false;
  if (
    loopQualApp.isLoggedInUserInfo &&
    customerStreetDetails &&
    customerStreetDetails.disprawstr &&
    addressQualification.lqResult &&
    addressQualification.lqResult.lqAddress
  ) {
    const similarity = stringSimilarity.compareTwoStrings(
      addressQualification.lqResult.lqAddress.toUpperCase(),
      customerStreetDetails.disprawstr.replace(', USA', '').toUpperCase(),
    );
    console.log(similarity);
    if (similarity > 0.75) {
      streetError = true;
      dispatch(sameAddressError(true));
      return true;
    }
  }
  if (loopQualApp.homeLQ || (e && e.target && e.target.id && e.target.id === 'stickyBar-CTA') || e === 'stickyBar-CTA') {
    fromStickyBar = true;
  }
  if (!fromStickyBar) {
    setTimeout(() => {
      greyOut();
    }, 500); // CXTDT-479152
  }
  if (loopQualApp && loopQualApp.hideFccMiniLq && !fromStickyBar) {
    dispatch({ type: LQActionTypes.HIDE_FCC_MINILQ, value: false });
    greyOut();
  }
  const streetaddress = document.querySelector('#streetaddress');
  const fromAvailabilityQs = !!(
    window.location.search &&
    window.location.search.toLowerCase().indexOf('?availability') > -1 &&
    !fromOnClick &&
    streetInput.length === 0
  );
  if (fromStickyBar && !fromAvailabilityQs && (streetInput.length === 0 || streetInput.length < 10)) {
    streetError = true;
  } else if (loopQualApp.isFccFlow && fromStickyBar && !fromAvailabilityQs && !customerStreetDetails) {
    streetError = true;
  } else {
    streetError = false;
  }
  try {
    if (
      !fromStickyBar &&
      renderSetting &&
      renderSetting.miniLq &&
      renderSetting.miniLq.enabled &&
      renderSetting.miniLq.options &&
      renderSetting.miniLq.options.sticky === ''
    ) {
      externalTriggerNonSticky = true;
    }
  } catch (ex) {
    console.log(`externalTriggerNonSticky${ex}`);
  }
  if (streetError) {
    dispatch(setStreetError(true));
    dispatch(setStreetGeoError(false));
    sendDataVzTagErrorMessage('Please select an address from the dropdown', 'lq sticky bar');
    if (streetaddress) {
      streetaddress.focus();
      if (externalTriggerNonSticky) {
        document.documentElement.scrollTop = 0;
      }
    }
    return true;
  }
  if ((unitLookupDetails && unitLookupDetails.isUnitRequired) || (customerStreetDetails && customerStreetDetails.addressClassification === 'MDU')) {
    if (unitLookupDetails && unitLookupDetails.unitSelected) {
      unitRequired = true;
      dispatch(setUnitInputError(false));
    } else {
      unitRequired = false;
      dispatch(setUnitInputError(true));
      if (externalTriggerNonSticky) {
        document.documentElement.scrollTop = 0;
      }
      sendDataVzTagErrorMessage('This is a mandatory field', 'lq sticky bar');
      const unitErrorElem = document.getElementById('uniterror');
      if (unitErrorElem) {
        unitErrorElem.focus();
      }
      return true;
    }
  } else {
    unitRequired = true;
    dispatch(setUnitInputError(false));
  }
  if (fromOnClick && loopQualApp && loopQualApp.isFccFlow && customerStreetDetails) {
    openModalWindow('LQAPP', dispatch);
    sendDataPassiveLqa('lq-address', 'fcc');
    dispatch(updateCurrentView(LQ_VIEWS.BLANK_LOADER_VIEW));
    checkNetworkAvailability(
      customerStreetDetails,
      unitLookupDetails,
      addressQualification,
      loopQualApp,
      dispatch,
      cookies,
      streetInput,
      fiosDetails,
      moveDetails,
      null,
      false,
      fromOnClick,
    );
  }
  if (unitRequired && !streetError && fromStickyBar) {
    openViewVzTag('lq check avail');
    let bussinessView = false;
    if (unitLookupDetails?.customerUnitDetails?.propertyType === 'B') {
      bussinessView = true;
    } else if (customerStreetDetails?.propertyType === 'B') {
      bussinessView = !unitLookupDetails.customerUnitDetails;
    }
    if (
      ((loopQualApp && loopQualApp.lq4) || loopQualApp.homeLQ) &&
      customerStreetDetails &&
      streetInput !== '' &&
      parserAttempt < 7 &&
      !(loopQualApp && loopQualApp.isFccFlow)
    ) {
      openModalWindow('LQAPP', dispatch);
      loopQualVzTaglqa('lq address verification |lqa sticky bar');
      if (fromOnClick) {
        openViewVzTag('lq loader');
      }
      if (bussinessView) {
        dispatch(updateCurrentView(LQ_VIEWS.BUSINESSADDRESS_VIEW));
      } else {
        dispatch(updateCurrentView(LQ_VIEWS.BLANK_LOADER_VIEW));
        checkNetworkAvailability(
          customerStreetDetails,
          unitLookupDetails,
          addressQualification,
          loopQualApp,
          dispatch,
          cookies,
          streetInput,
          fiosDetails,
          moveDetails,
          null,
          true,
          fromOnClick,
        );
      }
    } else if (!customerStreetDetails && streetInput !== '' && parserAttempt < 7 && !(loopQualApp && loopQualApp.isFccFlow)) {
      dispatch(updateParserAttempt(parserAttempt + 1));
      openModalWindow('LQAPP', dispatch);
      checkNetworkAvailability(
        customerStreetDetails,
        unitLookupDetails,
        addressQualification,
        loopQualApp,
        dispatch,
        cookies,
        streetInput,
        fiosDetails,
        moveDetails,
        null,
        true,
        fromOnClick,
      );
    } else if (fromAvailabilityQs && streetaddress) {
      streetaddress.focus();
    } else if (!loopQualApp.isFccFlow) {
      openModalWindow('LQAPP', dispatch);
    }
    return true;
  }
  if (
    unitRequired &&
    customerStreetDetails &&
    !fromStickyBar &&
    ((loopQualApp && loopQualApp.lq4) || loopQualApp.homeLQ) &&
    customerStreetDetails &&
    streetInput !== '' &&
    parserAttempt < 7 &&
    !(loopQualApp && loopQualApp.isFccFlow)
  ) {
    openModalWindow('LQAPP', dispatch);
    loopQualVzTaglqa('lq address verification |lqa sticky bar');
    dispatch(updateCurrentView(LQ_VIEWS.BLANK_LOADER_VIEW));
    checkNetworkAvailability(
      customerStreetDetails,
      unitLookupDetails,
      addressQualification,
      loopQualApp,
      dispatch,
      cookies,
      streetInput,
      fiosDetails,
      moveDetails,
      bulkQualDetails,
      true,
      fromOnClick,
    );
  } else if (unitRequired && customerStreetDetails && !fromStickyBar) {
    openModalWindow('LQAPP', dispatch);
  } else if (streetInput.length >= 8 && !fromStickyBar && streetInput !== '' && !customerStreetDetails && !(loopQualApp && loopQualApp.isFccFlow)) {
    dispatch(updateParserAttempt(parserAttempt + 1));
    openModalWindow('LQAPP', dispatch);
    checkNetworkAvailability(
      customerStreetDetails,
      unitLookupDetails,
      addressQualification,
      loopQualApp,
      dispatch,
      cookies,
      streetInput,
      fiosDetails,
      moveDetails,
      null,
      true,
      fromOnClick,
    );
  }
  if (streetaddress) {
    streetaddress.focus();
  }
};
export const postQualificationUpdate = (response, loopQualApp, addressQualification, moveDetails, dispatch, cookies, fiosDetails, fromOnClick) => {
  if (response.fiveGQualified || response.lteQualified || response.qualifiedCBand || response.tgwQualified) {
    if (response.addrInfo && !loopQualApp.isLoggedIn) {
      updatePdm(response);
    }
    const digitalSessionIdFromQual = window.sessionStorage.getItem('digitalSessionId') ? window.sessionStorage.getItem('digitalSessionId') : '';
    const sessionIdFromPdm = window.sessionStorage.getItem('sessionIdFromPdm') ? window.sessionStorage.getItem('sessionIdFromPdm') : '';
    if (addressQualification.smartCartPdm === 'Y' && digitalSessionIdFromQual && sessionIdFromPdm && digitalSessionIdFromQual !== sessionIdFromPdm) {
      try {
        if (window.vzAbandonedCartModal) {
          invokeUCAbandonedCart(addressQualification, loopQualApp, dispatch);
        } else {
          logMetrics('smartCartPdm present', 'vzAbandonedCartModal not present', 'Info');
        }
      } catch (ex) {
        console.error(ex);
      }
    }
    setSessionOrderingIntent(response, loopQualApp);
  }
  if (response.uberPinEligible) {
    invokeHereMapScript();
    if (loopQualApp.isLoggedIn && addressQualification.lqResult.addrInfo && addressQualification.lqResult.addrInfo.addressLine2) {
      const unit = addressQualification.lqResult.addrInfo.addressLine2;
      if (/^[0-9]/.test(unit)) {
        dispatch(updateUserInput(`UNIT ${unit}`));
      } else {
        dispatch(updateUserInput(unit));
      }
    }
  }
  if (
    loopQualApp.flowType === Flowtype.MOVERS &&
    moveDetails &&
    moveDetails.preferredMVService &&
    moveDetails.preferredMVService.lineLevelInfo &&
    moveDetails.preferredMVService.lineLevelInfo > 1
  ) {
    const preferredMVService = moveDetails.preferredMVService.lineLevelInfo.networkBandwidthType
      ? moveDetails.preferredMVService.lineLevelInfo.networkBandwidthType.toLowerCase()
      : preferredMVService.lineLevelInfo.toLowerCase();
    if (response.moveQualified && response.networkBandwidthType && preferredMVService === response.networkBandwidthType.toLowerCase()) {
      if (preferredMVService.includes('mmw')) {
        dispatch(setdisConnectMove(true));
      } else {
        dispatch(setDirectMove(true));
      }
    } else if (
      preferredMVService.includes('mmw') &&
      response.networkBandwidthType &&
      preferredMVService === response.networkBandwidthType.toLowerCase()
    ) {
      dispatch(setdisConnectMove(true));
    }
  } else {
    if (loopQualApp.lq4 && (response.skipMultipleAddress || (fromOnClick && response.inValidAddress))) {
      if (cookies && fiosDetails) {
        clearDetails(dispatch, cookies, fiosDetails, loopQualApp);
      }
      closeModalWindow(dispatch);
      if (fromOnClick && response.inValidAddress) {
        dispatch(invalidAddressError(true));
        sendDataVzTagErrorMessage('Please re-enter a valid address and select from the dropdown.', 'lq sticky bar');
      } else {
        dispatch(setStreetError(true));
        dispatch(setStreetGeoError(false));
      }
      greyOut();
    } else if (response.fiosExtremeHappy) {
      if (fromOnClick || window.location.pathname.includes('inhome/checkavailability') || window.location.pathname.includes('inhome/qualification')) {
        setToStorage('isLQHappyPath', 'Y');
        dispatch(showLoader());
        lqsTagTriggered(dispatch, true);
        const redirectUrl = window.location.host.includes('.verizonwireless.com')
          ? `https://www98.verizon.com${Pages.InHomeBuildProduct}&visitor_id=${response.visitor_id}&visit_id=${response.visit_id}`
          : Pages.InHomeBuildProduct;
        setTimeout(() => {
          dispatch(hideLoader());
          redirectToFiosPlansPage(redirectUrl, dispatch);
        }, 200);
      } else if (response && response.fiosQualified) {
        dispatch(updateCurrentView(LQ_VIEWS.FIOS_QUALIFIED_VIEW));
      }
    }
    dispatch(setDirectMove(false));
    dispatch(setdisConnectMove(false));
  }
};
const dispatchLearnCta = (response, dispatch) => {
  const qualifiedServices = [];
  if (response.qualified === false && response.targetView === 'UN_QUALIFIED_VIEW') {
    qualifiedServices.push('NOT_QUALIFIED');
    setToStorage('LqQualified', 'NOT_QUALIFIED');
    setCheckAvailBtnTrack('not eligible', dispatch);
  } else {
    const { fiveGQualified, lteQualified, fiosQualified, qualifiedCBand } = response;
    if (fiosQualified) {
      qualifiedServices.push('FIOS_QUALIFIED');
      setToStorage('LqQualified', 'FIOS_QUALIFIED');
      setCheckAvailBtnTrack('order now - fios eligible', dispatch);
    } else if (fiveGQualified || qualifiedCBand) {
      qualifiedServices.push('5G_HOME_QUALIFIED');
      setToStorage('LqQualified', qualifiedCBand ? '5G_CBAND_HOME_QUALIFIED' : '5G_HOME_QUALIFIED');
      setCheckAvailBtnTrack(qualifiedCBand ? 'order now - 5g eligible -cband' : 'order now - 5g eligible -mmWave', dispatch);
    } else if (lteQualified) {
      qualifiedServices.push('LTE_HOME_QUALIFIED');
      setToStorage('LqQualified', 'LTE_HOME_QUALIFIED');
      setCheckAvailBtnTrack('order now - lte eligible', dispatch);
    }
  }
  console.log('====DISPATCHING QUAL SUCCESS EVENT====');
  document.dispatchEvent(new CustomEvent('lqw-qualification-success', { detail: qualifiedServices, bubbles: true }));
};
export const setSessionOrderingIntent = (response) => {
  const location =
    window.location.search &&
    (window.location.search.indexOf('testQualification') > -1 ||
      window.location.search.indexOf('nsa=y') > -1 ||
      window.location.search.indexOf('NSA=Y') > -1)
      ? 'Y'
      : null;

  setToStorage('isCBandQual', '');
  if (response.preOrder5GFlow) {
    setToStorage('isPreorder', true);
  }
  if (location && location === 'Y') {
    setToStorage('isTestFlow', 'Y');
  }
  if (response.qualifiedCBand) {
    setToStorage('isCBandQual', 'Y');
    setToStorage('FlowType', '5G');
    if (response.bundleDetails && response.bundleDetails.length > 1) {
      setToStorage('cbandPro', 'Y');
    } else {
      setToStorage('cbandPro', 'N');
    }
  } else if (response.fiveGQualified && response.launchType === 'FCL') {
    setToStorage('FlowType', '5G');
  } else if (response.lteQualified && response.launchType === '4GH') {
    setToStorage('FlowType', 'LTE');
  } else if (response.tgwQualified) {
    setToStorage('isTGW', true);
    setToStorage('FlowType', '5G');
  } else {
    setToStorage('FlowType', '');
  }
};
export const singlefromBulkQualUpdate = (dispatch, bkResponse, loopQualApp) => {
  try {
    const response = transform(bkResponse, FIVG_LQ_RESPONSE, '', loopQualApp, '', '', false, true);
    dispatch({ type: ActionTypes.QUALIFICATION_DETAILS, value: response });
    setSessionOrderingIntent(response, loopQualApp);
    if (response) {
      dispatch(updateCurrentView(response.targetView));
    }
    dispatchLearnCta(response, dispatch);
    return response;
  } catch (error) {
    dispatch(hideLoader());
    logMetrics('singlefromBulkQualUpdate', error.message, 'Error');
  }
};
export const checkAuthFlow = (dispatch, cookies, loopQualApp, addressInfo, addressQualification, moveDetails) => {
  try {
    dispatch(showLoader());
    return new Promise((resolve) => {
      fetchAuthDetails(cookies, loopQualApp, addressInfo)
        .then((response) => {
          dispatch(hideLoader());
          if (response) {
            logMetrics('checkAuthFlow', 'checkAuthFlowSuccess', 'Info');
            dispatch({ type: ActionTypes.QUALIFICATION_DETAILS, value: response });
            if (response.targetView) {
              dispatch(updateCurrentView(response.targetView));
              dispatchLearnCta(response, dispatch);
              postQualificationUpdate(response, loopQualApp, addressQualification, moveDetails, dispatch);
            }
            if (loopQualApp.isACPFlow && response.acpInfo) {
              dispatch({ type: ActionTypes.SC_ACP, value: response.acpInfo.isAcpExist });
              dispatch({ type: ActionTypes.SC_VFP, value: response.acpInfo.isVfExist });
              window.sessionStorage.setItem('isVfExist', response.acpInfo.isVfExist);
              window.sessionStorage.setItem('isAcpExist', response.acpInfo.isAcpExist);
              window.sessionStorage.setItem('isSwitcherPromoEligible', response.acpInfo.SwitcherPromoEligible);
              if (response.acpInfo.customerName) {
                const details = {
                  firstName: response.acpInfo.customerName.firstName,
                  lastName: response.acpInfo.customerName.lastName,
                };
                dispatch({ type: ActionTypes.SC_DETAILS, value: details });
              }
            }
            resolve(response);
          } else {
            dispatch({ type: ActionTypes.RESET_QUALIFICATION_DETAILS_LQRESULT });
          }
        })
        .catch((error) => {
          dispatch(hideLoader());
          logMetrics('checkAuthFlow', error.message, 'Error');
        });
    });
  } catch (error) {
    dispatch(hideLoader());
    logMetrics('checkAuthFlow', error.message, 'Error');
  }
};

export const setNetworkTypeForMoversFlow = (prev, curr) => {
  let networkBandwidthType = '';
  if (prev && curr) {
    const previousNetwork = prev?.toUpperCase();
    const currentNetwork = curr?.toUpperCase();
    if (previousNetwork === currentNetwork) {
      networkBandwidthType = currentNetwork;
    } else {
      networkBandwidthType = `${previousNetwork}_${currentNetwork}`;
    }
  }
  setToStorage('moversNetworkBandwidth', networkBandwidthType);
  const value = `moversNetworkBandwidth=${networkBandwidthType};path=/`;
  document.cookie = value;
};

export const updateEmailInput = (emailaddress) => ({ type: ActionTypes.SC_EMAIL, value: emailaddress });
export const updatePhoneInput = (phoneNo) => ({ type: ActionTypes.SC_MTN, value: phoneNo });
export const updateFormattedPhoneInput = (phoneNo) => ({ type: ActionTypes.FORMATTED_MTN, value: phoneNo });
export const updateHelpView = (dispatch, input) => {
  dispatch({ type: ActionTypes.HELP_VIEW, value: input });
};
export const resetQualificationDetials = (dispatch) => {
  dispatch({ type: ActionTypes.RESET_QUALIFICATION_DETAILS });
  dispatch({ type: ActionTypes.RESET_QUALIFICATION_DETAILS_LQRESULT });
};
export const lqsTagTriggered = (dispatch, input) => {
  dispatch({ type: ActionTypes.LQSTAG_TRIGGERED, value: input });
};
export const mapTagTriggered = (dispatch, input) => {
  dispatch({ type: ActionTypes.MAPTAG_TRIGGERED, value: input });
};
export const validateEmailRegex = (dispatch, input) => {
  dispatch({ type: ActionTypes.VALIDATE_EMAIL_REGEX, value: input });
};
export const validateEmailAddress = (dispatch, input) => {
  dispatch({ type: ActionTypes.VALIDATE_EMAIL_ADDRESS, value: input });
};
export const verifyEmail = (dispatch, input) => {
  dispatch({ type: ActionTypes.VERIFY_EMAIL, value: input });
};
export const manualValidationClick = (dispatch, input) => {
  dispatch({ type: ActionTypes.MANUAL_VALIDATION_CLICK, value: input });
};
export const error = (dispatch, input) => {
  dispatch({ type: ActionTypes.ERROR, value: input });
};
export const success = (dispatch, input) => {
  dispatch({ type: ActionTypes.SUCCESS, value: input });
};
export const errorTextForEmail = (dispatch, input) => {
  dispatch({ type: ActionTypes.ERROR_TEXT_FOR_EMAIL, value: input });
};
export const resetQualificationDetialsLqResult = (dispatch) => {
  dispatch({ type: ActionTypes.RESET_QUALIFICATION_DETAILS_LQRESULT });
};

export const updatePlanpageViaThrottle = async (response, dispatch, loopQualApp, isRetry = false) => {
  dispatch(showLoader());
  return new Promise((resolve, reject) => {
    invokeUpdateCartNoRedirect(response, loopQualApp, dispatch)
      .then((fccresponse) => {
        setToStorage('cartResponse', JSON.stringify(fccresponse));
        if (fccresponse?.data?.serviceBody?.serviceResponse) {
          if (fccresponse?.data?.serviceBody?.serviceResponse?.context) {
            setToStorage('caseid', fccresponse.data.serviceBody.serviceResponse.context.caseId);
            if (fccresponse.data.serviceBody.serviceResponse.context?.cartInfo) {
              setToStorage('cartid', fccresponse.data.serviceBody.serviceResponse.context.cartInfo.cartId);
            }
            if (fccresponse.data.serviceBody.serviceResponse.context?.cartInfo?.cartExist) {
              setToStorage('cartExist', fccresponse.data.serviceBody.serviceResponse.context.cartInfo.cartExist);
            }
          }
          lqsTagTriggered(dispatch, true);
          let existingCase = fccresponse?.data?.serviceBody?.serviceResponse?.context?.customerInfo?.existingCase;
          if (window.location.search && window.location.search.indexOf('testThrottle=y') > -1) {
            existingCase = 'false';
          }
          if (existingCase && existingCase === 'true') {
            setToStorage('ExistingCase', existingCase);
            loopQualVzTaglqs('lq result - ', 'success', response.targetView === LQ_VIEWS.FIVEG_HOME_QUALIFIED_VIEW ? '5g' : 'lte', null, true);
            window.sessionStorage.removeItem('fwa_lqaCalled');
            // dispatch(updateCurrentView(LQ_VIEWS.RESUME_SMART_CART_VIEW)); Added below
            dispatch(hideLoader());
            if (plansvisit) {
              // cancelling smartcart for plansvitid LQS scenario
              const redirectionPlansPage = Pages.fiveGNSAExpressCart;
              dispatch(updatePlansPage(redirectionPlansPage));
              redirectToPlansPage(redirectionPlansPage);
            } else {
              dispatch(updateCurrentView(LQ_VIEWS.RESUME_SMART_CART_VIEW));
            }
          } else {
            setToStorage('ONResponse', fccresponse);
            setToStorage('FWA_LQSPO', 'Y');
            const redirectionPage = loopQualApp?.midnight ? 'progressiveplan' : 'plansOverview';
            const redirectionPlansPage = `https://${window.location.host}/sales/home/${redirectionPage}.html`;
            if (loopQualApp.lq4) {
              closeModalWindow(dispatch);
            }
            dispatch(hideLoader());
            dispatch(updatePlansPage(redirectionPlansPage));
            redirectToPlansPage(redirectionPlansPage);
          }
          resolve(fccresponse);
        } else if (fccresponse?.data?.errors || fccresponse?.errors || fccresponse?.data?.error) {
          setToStorage('ONError', fccresponse);
          let errorMsg;
          if (fccresponse?.data?.errors[0] && fccresponse.data.errors[0].message) {
            errorMsg = fccresponse.data.errors[0].message;
          } else if (fccresponse?.errors[0] && fccresponse.data.errors[0].message) {
            errorMsg = fccresponse.errors[0].message;
          } else {
            errorMsg = fccresponse?.data?.error;
          }
          if (!isRetry) {
            logMetrics('invokeUpdateCartNoRedirect Retriggering again', errorMsg, 'Error');
            updatePlanpageViaThrottle(response, dispatch, loopQualApp, true);
          } else {
            dispatch(updateCurrentView(LQ_VIEWS.LBO_VIEW));
            logMetrics('invokeUpdateCartNoRedirect UC error', errorMsg, 'Error');
          }
        }
      })
      .catch((error_resp) => {
        dispatch(hideLoader());
        setToStorage('ONError', response);
        logMetrics('invokeUpdateCartNoRedirect error', error_resp.message, 'Error', error_resp);
        if (!isRetry) {
          logMetrics('invokeUpdateCartNoRedirect Retriggering again', error_resp.message, 'Error');
          updatePlanpageViaThrottle(response, dispatch, loopQualApp, true);
        } else {
          dispatch(updateCurrentView(LQ_VIEWS.LBO_VIEW));
          logMetrics('invokeUpdateCartNoRedirect UC error', error_resp.message, 'Error');
        }
        reject(error_resp);
      });
  });
};
