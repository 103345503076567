import { AddressLookUp as Action } from '../../constants/actionTypes';

const initialState = {
  customerStreetDetails: null,
  isReCaptcheValidated: true,
  streetInput: '',
  addressCollection: [],
  halfSuggestion: [],
  fullSuggestion: [],
  errorMessage: '',
  revisitAddress: '',
  showLqCta: false,
  parserAttempt: 0,
  parserAddressID: 0,
  parserShowAll: false,
  streetError: false,
  sameAddressError: false,
  invalidAddressError: false,
  streetEmptyError: false,
  isRevisit: false,
};

const addressLookupReducer = (state = initialState, action) => {
  let selstate;
  switch (action.type) {
    case Action.RESET_STREET_SELECTION:
      return {
        ...state,
        customerStreetDetails: null,
        customerUnitDetails: null,
        revisitAddress: '',
      };
    case Action.UPDATE_STREET_DETAILS:
      return { ...state, customerStreetDetails: action.value, revisitAddress: '' };
    case Action.UPDATE_STREET_INPUT:
      return { ...state, streetInput: action.value };
    case Action.STREET_EMPTY_ERROR:
      return { ...state, streetEmptyError: action.value };
    case Action.SET_STREET_ERROR:
      return { ...state, streetError: action.value };
    case Action.SET_STREET_GEO_ERROR:
      return { ...state, streetGeoError: action.value };
    case Action.SAME_ADDRESS_ERROR:
      return { ...state, sameAddressError: action.value };
    case Action.INVALID_ADDRESS_ERROR:
      return { ...state, invalidAddressError: action.value };
    case Action.UPDATE_ADDRESS_COLLECTION:
      return { ...state, addressCollection: action.value };
    case Action.FULL_SUGGESTION:
      return { ...state, fullSuggestion: action.value };
    case Action.HALF_SUGGESTION:
      return { ...state, halfSuggestion: action.value };
    case Action.UPDATE_ADDRESS_COLLECTION_RESET:
      const addressCollection = [];
      return { ...state, addressCollection };
    case Action.ERROR_MSG:
      return { ...state, errorMessage: action.value };
    case Action.REVISIT_ADDRESS:
      return { ...state, revisitAddress: action.value };
    case Action.REVISIT_SCENARIO:
      return { ...state, isRevisit: action.value };
    case Action.SHOW_LQCTA:
      return { ...state, showLqCta: action.value };
    case Action.PARSER_ATTEMPT:
      return { ...state, parserAttempt: action.value };
    case Action.PARSER_ADDRESS_ID:
      return { ...state, parserAddressID: action.value };
    case Action.PARSER_SHOW_ALL:
      return { ...state, parserShowAll: action.value };
    case Action.SET_PARSED_ADDRESS:
      selstate = {
        zip: action.value.zip,
        muni: action.value.city,
        dispstr: action.value.street,
        state: action.value.state,
        ntasAddrID: action.value.ntasAddrID,
        locusID: action.value.locusID,
        multipleAddress: action.value.multipleAddress,
        entireStreetAddress: action.value.entireStreetAddress,
        parserAddressLine2: action.value.parserAddressLine2,
      };
      return {
        ...state,
        customerStreetDetails: selstate,
      };
    case Action.RESTORE_DEFAULTS:
      return initialState;

    default:
      return state;
  }
};

export default addressLookupReducer;
